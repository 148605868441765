import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { subscribe, unsubscribe } from '@ltvco/refresh-lib';
import { styled, Box, Stack } from '@ltvco/refresh-lib/theme';
import type { HeaderItem } from '../types';
import { OpenCloseChevron } from './components';

interface ClassicHeaderItemProps {
  item: HeaderItem;
  onClickHandler?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const StyledStack = styled(Stack)(({ theme }) => ({
  height: '100%',
  justifyContent: 'center',
  padding: '0 .875rem',
  fontWeight: 700,
  fontSize: '1.125rem',
  color: theme.palette.secondary.contrastText,
  userSelect: 'none',

  '&:hover': {
    cursor: 'pointer',
    color: '#ff4722',

    '& > a': {
      color: '#ff4722',
    },

    svg: {
      color: '#ff4722',
    },
  },

  '& > a': {
    color: theme.palette.secondary.contrastText,
  },
}));

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const ClassicHeaderItem = ({
  item,
  onClickHandler,
}: ClassicHeaderItemProps) => {
  const { url, route, visibleText, icon, name, componentToDisplayWhenOpen } =
    item;

  const linkTo = url || route;

  useEffect(() => {
    if (item.eventName === 'openVehicleSearch')
      subscribe('openVehicleSearch', () => {
        if (onClickHandler) {
          onClickHandler({} as React.MouseEvent<HTMLDivElement>);
        }
      });

    return () => {
      unsubscribe('openVehicleSearch', () => {});
    };
  }, []);

  return (
    <StyledStack onClick={onClickHandler}>
      {linkTo ? (
        <Link to={linkTo} target={url && '_blank'}>
          {visibleText && visibleText}
          {icon && icon}
        </Link>
      ) : (
        <>
          <StyledBox>
            {visibleText && visibleText}
            {Boolean(componentToDisplayWhenOpen) && Boolean(visibleText) && (
              <OpenCloseChevron name={name} />
            )}
            {Boolean(icon) && icon}
          </StyledBox>
        </>
      )}
    </StyledStack>
  );
};
