import { Button, Stack, Text, styled } from '@ltvco/refresh-lib/theme';
import { ArrowBackIosNew } from '@mui/icons-material';
import { lemonSquadCard } from 'utils/constants/selfInspectionQuestionnaire';

interface LemonSquadProps {
  actionHandlers: {
    backToVehicleInspection: () => void;
    lemonSquadRedirect: () => void;
  };
}

const StyledContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
}));

export function LemonSquad({ actionHandlers }: LemonSquadProps) {
  const handlePrimaryAction = () => {
    actionHandlers[
      lemonSquadCard.primaryAction.action as keyof typeof actionHandlers
    ]();
  };

  const handleSecondaryAction = () => {
    actionHandlers[
      lemonSquadCard.secondaryAction.action as keyof typeof actionHandlers
    ]();
  };

  return (
    <StyledContainer>
      <Stack alignItems="center" direction="column" sx={{ flexGrow: 1 }}>
        <Button
          sx={{ alignSelf: 'flex-start', fontSize: '.875rem' }}
          onClick={actionHandlers.backToVehicleInspection}
        >
          <ArrowBackIosNew fontSize="inherit" /> Back
        </Button>
        <img
          src={lemonSquadCard.cardInfo.icon}
          alt="section-icon"
          width="135px"
          height="auto"
        />

        <Text variant="h3" textAlign="center" mb={1.25}>
          {lemonSquadCard.cardInfo.cardHeader}
        </Text>
        <Text variant="body1" textAlign="center" mb={2.25}>
          {lemonSquadCard.cardInfo.cardBody}
        </Text>
        <Stack width="100%" mt="auto" spacing={2.5}>
          <Button
            color="success"
            variant="contained"
            fullWidth
            onClick={handlePrimaryAction}
          >
            {lemonSquadCard.primaryAction.text}
          </Button>
          <Button variant="text" fullWidth onClick={handleSecondaryAction}>
            {lemonSquadCard.secondaryAction.text}
          </Button>
        </Stack>
      </Stack>
    </StyledContainer>
  );
}
