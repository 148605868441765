import { useScreenSize } from '@ltvco/refresh-lib/utils';
import {
  useSession,
  JoinButton,
  ReactivateHeader,
} from '@ltvco/refresh-lib/v1';
import { DesktopHeaderNav } from './DesktopHeaderNav/DesktopHeaderNav';
import { MobileHeaderNav } from 'components/MobileHeaderNav/MobileHeaderNav';

interface ClassicHeaderContents {
  handleLogout: () => void;
}

export const ClassicHeaderContents = ({
  handleLogout,
}: ClassicHeaderContents) => {
  const {
    session: { isAuthenticated },
  } = useSession();
  const { isMedium } = useScreenSize();

  const isInReactivationRoute =
    window.location.pathname.includes('/reactivation');

  if (!isAuthenticated) {
    return <JoinButton />;
  }
  if (isInReactivationRoute) {
    return <ReactivateHeader handleLogout={handleLogout} />;
  }

  if (isMedium) {
    return <MobileHeaderNav handleLogout={handleLogout} />;
  }

  return <DesktopHeaderNav />;
};
