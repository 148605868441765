import { Box, Text } from '@ltvco/refresh-lib/theme';
import { CheckCircle } from '@mui/icons-material';
import { MiscellaneousCard } from 'utils/constants/selfInspectionQuestionnaire';

interface LastScreenDataCardProps {
  cardData: MiscellaneousCard;
}

export const LastScreenDataCard = ({ cardData }: LastScreenDataCardProps) => {
  return (
    <>
      <Box fontSize="2.25rem">
        <CheckCircle fontSize="inherit" sx={{ color: '#212121' }} />
      </Box>
      <Text variant="h5" textAlign="center" color="primary.dark" mt={1.5}>
        {cardData.cardInfo.cardHeader}
      </Text>
      <Text
        variant="h2"
        textAlign="center"
        fontStyle="italic"
        color="primary.dark"
      >
        {cardData.cardInfo.cardBody}
      </Text>
      <Text variant="body1" textAlign="center">
        {cardData.cardInfo.cardAdditionalInfo}
      </Text>
    </>
  );
};
