import { Input, Text, Box, Stack } from '@ltvco/refresh-lib/theme';
import { StyledInput } from '../components';
import { ErrorMessageWithIcon } from 'components/shared/ErrorMessageWithIcon';

interface FormInputProps {
  title: string;
  placeholder: string;
  name: string;
  error?: string;
  register: any;
  extendHeight?: boolean;
  disabled?: boolean;
  watch?: any;
  charLimit?: number;
  handleChange?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const FormInput = ({
  title,
  placeholder,
  register,
  name,
  error,
  extendHeight,
  disabled,
  watch,
  charLimit,
  handleChange,
}: FormInputProps) => {
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Text variant="h5">{title}</Text>
        {watch && (
          <Text
            variant="body2"
            color={Boolean(error) ? 'error.main' : 'text.secondary'}
          >
            {watch(name)?.length || 0}/{charLimit}
          </Text>
        )}
      </Stack>
      {extendHeight ? (
        <StyledInput
          type="text"
          rows={3}
          multiline
          fullWidth
          placeholder={placeholder}
          disabled={disabled}
          {...register(name, {
            onChange: handleChange,
          })}
        />
      ) : (
        <Input
          type="text"
          fullWidth
          placeholder={placeholder}
          disabled={disabled}
          {...register(name, {
            onChange: handleChange,
          })}
        />
      )}

      {Boolean(error) && <ErrorMessageWithIcon errorMessage={error!} />}
    </Box>
  );
};
