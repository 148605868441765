import { Box, styled } from '@ltvco/refresh-lib/theme';
import { useLocation } from 'react-router-dom';

const AppWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingBottom: theme.spacing(0.25),
  [theme.breakpoints.down('md')]: {
    margin: '20px 16px',
  },
  [theme.breakpoints.up('md')]: {
    margin: 0,
  },
}));

export function AppContainer({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const location = useLocation();
  const isOnReportView = location.pathname.includes('/report/');

  return (
    <AppWrapper
      sx={{
        marginX: isOnReportView ? 0 : null,
        marginTop: isOnReportView ? '70px' : '80px',
      }}
    >
      <Box>{children}</Box>
    </AppWrapper>
  );
}
