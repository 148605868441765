import { useContext, useState } from 'react';
import { grey } from '@mui/material/colors';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Text,
  styled,
} from '@ltvco/refresh-lib/theme';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { OnSuccessCallback, SearchData } from '@ltvco/refresh-lib/v1';
import { Search, Menu } from '@mui/icons-material';
import { useHeaderContext } from 'context/HeaderContext';
import { useHandleSearch } from 'hooks/useHandleSearch';
import { HeaderSearchDropdown } from 'components/ClassicHeader/ClassicHeaderContents/DesktopHeaderNav/HeaderSearchDropdown/HeaderSearchDropdown';
import { MobileHeaderMenuDropdown } from './MobileHeaderMenuDropdown/MobileHeaderMenuDropdown';

const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const MenuButtonContainer = styled(Box)({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
});

const StyledIconButton = styled(IconButton)({
  width: '55px',
});

const StyledButton = styled(Button)({
  height: '100%',
  padding: '10px 8px',

  '&:hover': {
    background: 'inherit',
  },
});

const ButtonContents = styled(Box)({
  flexDirection: 'row',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '25px',
  background: grey[100],
  padding: '4px 16px',
});

interface MobileHeaderNavProps {
  handleLogout: () => void;
}

export const MobileHeaderNav = ({ handleLogout }: MobileHeaderNavProps) => {
  const { trackEventGA4 } = useContext(AppConfig);

  const { handleSearchSuccess } = useHandleSearch();
  const { currentDropdown, handleSetCurrentDropdown } = useHeaderContext();
  const [showDrawer, setShowDrawer] = useState(false);

  const onSearchSuccess: OnSuccessCallback = (searchPayload: SearchData) => {
    handleSearchSuccess && handleSearchSuccess(searchPayload);
    handleSetCurrentDropdown(null);
  };

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const handleClose = () => {
    handleSetCurrentDropdown(null);
  };

  const toggleSearch = () => {
    trackEventGA4({
      event_name: 'header_engagement',
      type: 'nav - search',
    });
    handleSetCurrentDropdown(currentDropdown === 'search' ? null : 'search');
  };

  const openSearch = () => {
    handleSetCurrentDropdown('search');
  };

  const openMenu = () => {
    trackEventGA4({
      event_name: 'header_engagement',
      type: 'nav - menu',
    });
    toggleDrawer();
  };

  return (
    <>
      <StyledBox>
        <StyledButton
          onClick={toggleSearch}
          id="mobile-header-search-button"
          disableRipple
        >
          <ButtonContents>
            <Search sx={{ paddingRight: 0.5 }} />
            <Text>Vehicle Search</Text>
          </ButtonContents>
        </StyledButton>
        <HeaderSearchDropdown
          name={'search'}
          onSearchSuccess={onSearchSuccess}
          id="search-dropdown"
          handleClose={handleClose}
          placement={'header'}
          openSearch={openSearch}
        />
      </StyledBox>
      <MenuButtonContainer>
        <StyledIconButton disableRipple onClick={openMenu}>
          <Menu />
        </StyledIconButton>
      </MenuButtonContainer>
      <Drawer anchor={'top'} open={showDrawer} onClose={toggleDrawer}>
        <MobileHeaderMenuDropdown
          closeMobileMenu={toggleDrawer}
          handleLogout={handleLogout}
        />
      </Drawer>
    </>
  );
};
