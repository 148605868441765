import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Stack,
  Text,
} from '@ltvco/refresh-lib/theme';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { Close } from '@mui/icons-material';
import { useAccount } from '@ltvco/refresh-lib/v1';
import CircleUserImg from 'images/img_circle_user.svg';
import { MenuItems } from 'utils/constants/mobileMenu';
import { MenuAccordion } from '../MenuAccordion/MenuAccordion';
import { MenuItem } from '../MenuItem/MenuItem';
import { MobileHeaderUserInformation } from './MobileHeaderUserInformation/MobileHeaderUserInformation';

interface MobileHeaderMenuDropdownProps {
  closeMobileMenu: () => void;
  handleLogout: () => void;
}

export type AccordionName = 'Tools' | 'Marketplace' | 'My Bumper';

const accordionsConfig = {
  Tools: { expanded: false },
  Marketplace: { expanded: false },
  'My Bumper': { expanded: false },
};

export const MobileHeaderMenuDropdown = ({
  closeMobileMenu,
  handleLogout,
}: MobileHeaderMenuDropdownProps) => {
  const navigate = useNavigate();
  const { trackEventGA4 } = useContext(AppConfig);
  const { data: account } = useAccount(true);
  const [accordions, setAccordionsState] = useState(accordionsConfig);

  const fullName = `${account?.account?.user_info?.first_name} ${account?.account?.user_info?.last_name}`;
  const email = `${account?.account?.user_info?.email}`;

  const handleActiveAccordion = (title: AccordionName) => {
    if (!Object.keys(accordionsConfig).includes(title)) return;

    trackEventGA4({
      event_name: 'header_engagement',
      type: `${title.toLowerCase()} ${
        accordions[title].expanded ? 'close' : 'open'
      }`,
    });

    setAccordionsState({
      ...accordions,
      [title]: {
        expanded: !accordions[title as keyof typeof accordions].expanded,
      },
    });
  };

  const handleMyAccountRedirect = () => {
    trackEventGA4({
      event_name: 'header_engagement',
      type: 'menu - my account',
    });
    closeMobileMenu();
    navigate('/dashboard/billing');
  };

  const handleRedirect = ({
    route,
    internal,
    logout,
  }: {
    route: string;
    internal: boolean;
    logout?: boolean;
  }) => {
    closeMobileMenu();

    if (logout) {
      handleLogout();
      return;
    }

    if (internal) {
      navigate(route);
    } else {
      window.open(route, '_blank', 'noopener,noreferrer');
    }
  };

  const handleTopClose = () => {
    trackEventGA4({
      event_name: 'header_engagement',
      type: 'menu - close x',
    });
    closeMobileMenu();
  };

  const handleBottomClose = () => {
    trackEventGA4({
      event_name: 'header_engagement',
      type: 'menu - close bottom',
    });
    closeMobileMenu();
  };

  return (
    <Stack height="100vh" width={'100%'}>
      <Stack px={2.5}>
        <Stack
          mb={5.5}
          pt={2}
          spacing={1.5}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            disableRipple
            onClick={handleTopClose}
            sx={{ position: 'absolute', top: 0, right: 0, padding: 2 }}
          >
            <Close />
          </IconButton>
          <Avatar src={CircleUserImg} alt="User" />
          <Text variant="h4">{fullName}</Text>
          <MobileHeaderUserInformation />
          <Button
            variant="contained"
            color="success"
            onClick={handleMyAccountRedirect}
          >
            My Account
          </Button>
        </Stack>
        <Stack
          direction="column"
          divider={<Divider orientation="horizontal" flexItem />}
        >
          {MenuItems.map((item, index) =>
            item.hasSubMenu ? (
              <MenuAccordion
                item={item}
                expanded={
                  accordions[item.title as keyof typeof accordions].expanded
                }
                handleActiveAccordion={handleActiveAccordion}
                handleRedirect={handleRedirect}
                key={index}
              />
            ) : (
              <MenuItem
                item={item}
                handleRedirect={handleRedirect}
                key={index}
              />
            )
          )}
        </Stack>
      </Stack>
      <Stack mt="auto" pt={1.5} pb={5} borderTop="1px solid rgba(0, 0, 0, 0.1)">
        <Button
          disableRipple
          onClick={handleBottomClose}
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          Close
        </Button>
      </Stack>
    </Stack>
  );
};
