import { ReactNode } from 'react';
import { styled, Menu } from '@ltvco/refresh-lib/theme';

interface ClassicHeaderDropdownMenuProps {
  /** The element to anchor the menu to. */
  anchorEl: HTMLElement | null;
  /** If true, the menu is visible. */
  open: boolean;
  /** Callback fired when the component requests to be closed. */
  handleClose?: () => void;
  /** An array of ReactNodes to be rendered within the ClassicHeaderDropdownMenu */
  children: ReactNode | ReactNode[];
  /** A string to be used as the HTML id for the top-most Menu element */
  id: string;
}

const StyledMenu = styled(Menu)({
  width: '0',
  height: '0',

  '>.MuiPaper-root': {
    width: 'auto',
    maxWidth: 'inherit',
    maxHeight: 'calc(100vh - 236px)',
  },
});

export const ClassicHeaderDropdownMenu = ({
  open,
  anchorEl,
  handleClose,
  children,
  id,
}: ClassicHeaderDropdownMenuProps) => {
  return (
    <StyledMenu
      id={id}
      anchorEl={anchorEl}
      onClose={handleClose}
      open={open}
      data-cy="header-menu"
      hideBackdrop
      disableAutoFocusItem
    >
      {children}
    </StyledMenu>
  );
};
