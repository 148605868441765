export const ApiDocumentation = {
  json: [
    {
      method: 'GET',
      endpoint: '/v1/vehicle/full',
      description: 'Get a full JSON report for a specified vehicle',
    },
    {
      method: 'GET',
      endpoint: '/v1/vehicle/custom',
      description: 'Get a custom JSON report for a specified vehicle',
    },
    {
      method: 'GET',
      endpoint: '/v1/vehicle/plate_to_vin',
      description: 'Get a VIN from a license plate and state',
    },
  ],
  pdf: [
    {
      method: 'POST',
      endpoint: '/v1/pdf',
      description: 'Generate a vehicle report and receive a permalink to a PDF',
    },
    {
      method: 'GET',
      endpoint: '/v1/pdf',
      description: 'Get a generated PDF report using a permalink',
    },
  ],
};
