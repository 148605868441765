import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@ltvco/refresh-lib/theme';
import { ChangeEvent } from 'react';

interface Props {
  choices: string[];
  updateAnswer: (newValue: string) => void;
  currentAnswer: string | null;
}

export function SelectQuestion({
  choices,
  updateAnswer,
  currentAnswer,
}: Props) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateAnswer((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl>
      <RadioGroup value={currentAnswer} onChange={handleChange}>
        {choices.map((choice) => (
          <FormControlLabel
            key={choice}
            value={choice}
            label={choice}
            control={<Radio size="small" />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
