import { styled, Card, Button } from '@ltvco/refresh-lib/theme';

export const StyledApiPlanCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`;

export const StyledPlanButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginTop: 'auto',
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(2),
  },
}));
