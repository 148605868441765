import { useEffect, useState } from 'react';
import { Accordion, Text } from '@ltvco/refresh-lib/theme';
import { formatDateFromReport } from '@ltvco/refresh-lib/utils';
import { Questionnaire } from 'utils/constants/selfInspectionQuestionnaire';
import { InspectionHeader } from './InspectionHeader/InspectionHeader';
import { InspectionDetail } from './InspectionDetail/InspectionDetail';
import { Answer, UpdatedQuestionnaire } from './interface';
import { mergeAnswersWithQuestionnaire } from '../utils';

interface SelfInspectionResultProps {
  answerSet: {
    answers: Answer[];
    createdDate: string;
  };
  expanded: boolean;
  handleOpenAccordion: (index: number) => void;
  index: number;
  vehicleType?: {
    vehicleType: string | null;
    vehicleSize: string | null;
    vehicleDoors: number | null | undefined;
  };
}

export function SelfInspectionResult({
  answerSet,
  expanded,
  handleOpenAccordion,
  index,
  vehicleType,
}: SelfInspectionResultProps) {
  const sections = [
    'Exterior inspection',
    'Interior inspection',
    'Engine and drivetrain',
    'Test drive',
  ];
  const [openAccordionId, setOpenAccordionId] = useState<number | null>(null);
  const [questionnaire, setQuestionnaire] = useState<UpdatedQuestionnaire[][]>(
    []
  );
  const [headerAnswers, setHeaderAnswers] = useState<UpdatedQuestionnaire[]>(
    []
  );

  useEffect(() => {
    const updatedQuestionnaire = mergeAnswersWithQuestionnaire(
      Questionnaire,
      answerSet.answers,
      vehicleType
    );

    setQuestionnaire(updatedQuestionnaire.slice(1));
    setHeaderAnswers(updatedQuestionnaire[0]);
  }, [answerSet.answers, vehicleType]);

  const handleActiveAccordion = () => {
    handleOpenAccordion(index);
  };

  const handleOpenSectionAccordion = (index: number) => {
    if (openAccordionId === index) {
      setOpenAccordionId(null);
      return;
    }

    setOpenAccordionId(index);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleActiveAccordion}
      display={
        <Text variant="h3" m={0}>
          <span style={{ color: 'text.secondary' }}>
            Inspection Completed on{' '}
          </span>
          <span>{formatDateFromReport(answerSet.createdDate)}</span>
        </Text>
      }
    >
      <InspectionHeader headerAnswers={headerAnswers} />
      {sections?.map((section, index) => (
        <InspectionDetail
          expanded={openAccordionId === index}
          handleOpenAccordion={handleOpenSectionAccordion}
          index={index}
          key={section}
          section={section}
          questionnaire={questionnaire[index]}
        />
      ))}
    </Accordion>
  );
}
