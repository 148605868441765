import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Text } from '@ltvco/refresh-lib/theme';
import { constants } from '../../../appConstants';
import { StyledAccordion } from './components';

/**
 * React component for displaying the API frequently asked questions (FAQs).
 *
 * This component renders a list of common questions and answers related to the
 * API services, such as the difference between PDF and Raw Data APIs, handling
 * credits or reports, vehicle coverage, response times, and contact information
 * for further inquiries. It uses StyledAccordion components to present each
 * question and answer in an expandable format.
 *
 * @returns {React.ReactElement} A React element representing the API FAQs section.
 */
export default function ApiFaqs() {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  });

  const handleChange = (panel: string) => {
    const newExpanded: { [key: string]: boolean } = { ...expanded };
    Object.keys(newExpanded).forEach((key) => {
      if (key === panel) {
        newExpanded[key] = !newExpanded[key];
      } else {
        newExpanded[key] = false;
      }
    });

    setExpanded(newExpanded);
  };

  return (
    <Box>
      <Text variant="h3">Frequently Asked Questions</Text>
      <StyledAccordion
        expanded={expanded['0']}
        onChange={() => handleChange('0')}
        display={
          <Text variant="h5" marginBottom={0}>
            What is the difference between the PDF API and Raw Data API?
          </Text>
        }
      >
        <Text>
          The PDF API provides a Bumper and NMVTIS branded report that you can
          download and display to consumers on your site.
        </Text>
        <Text>
          A Raw Data API provides the report in a JSON raw data response.
          Specific data in the Raw Data API has limitations on how it can be
          used. Salvage, Title, Title Brands and all NMVTIS data can be used for
          internal purposes only. Any data supplied by the Raw Data API cannot
          be used to match it to the vehicle owner.
        </Text>
      </StyledAccordion>
      <StyledAccordion
        expanded={expanded['1']}
        onChange={() => handleChange('1')}
        display={
          <Text variant="h5" marginBottom={0}>
            What happens when I run out of credits or reports?
          </Text>
        }
      >
        <Text>
          You can log in to Bumper.com and{' '}
          <Link to="/upgrade/plan" target="_blank" rel="noreferrer noopener">
            upgrade your plan
          </Link>{' '}
          at any anytime. If you reach the maximum reports or credits offered by
          our plans, you can contact us at{' '}
          <a
            href={`mailto:${constants.data.brandSupportEmail}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {constants.data.brandSupportEmail}
          </a>{' '}
          for a custom plan that fits your needs.
        </Text>
      </StyledAccordion>
      <StyledAccordion
        expanded={expanded['2']}
        onChange={() => handleChange('2')}
        display={
          <Text variant="h5" marginBottom={0}>
            What types of vehicles are covered by the API?
          </Text>
        }
      >
        <Text>
          Automobiles, motorcycles, trucks, buses, motor homes and truck
          tractors may have vehicle history records. Unfortunately, we don't
          have history reports for tractors and trailers.
        </Text>
      </StyledAccordion>
      <StyledAccordion
        expanded={expanded['3']}
        onChange={() => handleChange('3')}
        display={
          <Text variant="h5" marginBottom={0}>
            How long does it take to receive a response?
          </Text>
        }
      >
        <Text>
          Depending on the size of the report, typical response times for our
          API are no more than a few seconds.
        </Text>
      </StyledAccordion>
      <StyledAccordion
        expanded={expanded['4']}
        onChange={() => handleChange('4')}
        display={
          <Text variant="h5" marginBottom={0}>
            I have other questions, who do I reach out to?
          </Text>
        }
      >
        <Text>
          For any API related questions please contact us at{' '}
          <a
            href={`mailto:${constants.data.brandSupportEmail}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {constants.data.brandSupportEmail}
          </a>
          .
        </Text>
      </StyledAccordion>
    </Box>
  );
}
