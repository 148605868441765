import { Button, LinearProgress, Stack, Text } from '@ltvco/refresh-lib/theme';
import { ArrowBackIosNew, Info } from '@mui/icons-material';
import { QuestionCard as QuestionCardI } from 'utils/constants/selfInspectionQuestionnaire';
import { SelectQuestion } from './SelectQuestion/SelectQuestion';
import { InputQuestion } from './InputQuestion/InputQuestion';

interface QuestionProps {
  cardData: QuestionCardI;
  actionHandlers: {
    next: () => void;
    back: () => void;
    exit: () => void;
    complete: () => void;
    update: () => void;
  };
  sectionProgress: (item: QuestionCardI) => number;
  updateAnswer: (screenId: string, newValue: string) => void;
  currentAnswer: string | null;
}

export function Question({
  cardData,
  actionHandlers,
  sectionProgress,
  updateAnswer,
  currentAnswer,
}: QuestionProps) {
  const progressValue = sectionProgress(cardData);
  const isSelectQuestion = cardData.questionType === 'select';
  const isInputQuestion = cardData.questionType === 'input';

  const handleAnswerChangeWithId = (newValue: string) => {
    updateAnswer(cardData.screenId, newValue);
  };

  const disableNextButton =
    cardData?.cardInfo.hasOwnProperty('unskippable') &&
    Boolean(currentAnswer) === false;

  const handlePrimaryAction = () => {
    if (disableNextButton) return;

    actionHandlers[
      cardData.primaryAction.action as keyof typeof actionHandlers
    ]();
  };

  const handleSecondaryAction = () => {
    actionHandlers[
      cardData.secondaryAction.action as keyof typeof actionHandlers
    ]();
  };

  return (
    <Stack height="100%">
      <LinearProgress
        variant="determinate"
        value={progressValue}
        sx={{ height: '12px' }}
      />
      <Stack direction="column" sx={{ flexGrow: 1, py: 2.5, px: 2 }}>
        {cardData.hasBack && (
          <Button
            sx={{
              justifyContent: 'flex-start',
              alignSelf: 'flex-start',
              fontSize: '.875rem',
              mb: 2.5,
              pl: 0,
            }}
            onClick={actionHandlers.back}
          >
            <ArrowBackIosNew fontSize="inherit" /> Back
          </Button>
        )}
        <Text variant="h3" mb={1.25}>
          {cardData.cardInfo.question}
        </Text>
        {cardData.cardInfo?.description && (
          <Text variant="body2" mb={1.25}>
            <Info fontSize="inherit" /> {cardData.cardInfo.description}
          </Text>
        )}
        {isSelectQuestion && (
          <SelectQuestion
            choices={cardData.cardInfo.choices}
            currentAnswer={currentAnswer}
            updateAnswer={handleAnswerChangeWithId}
          />
        )}
        {isInputQuestion && (
          <InputQuestion
            placeholder={cardData.cardInfo.placeholder}
            currentAnswer={currentAnswer}
            updateAnswer={handleAnswerChangeWithId}
            isAlphaNumeric={cardData.cardInfo.isAlphaNumeric}
          />
        )}
        <Stack width="100%" mt="auto" spacing={2.5}>
          <Button
            color="success"
            variant="contained"
            fullWidth
            disabled={disableNextButton}
            onClick={handlePrimaryAction}
          >
            {cardData.primaryAction.text}
          </Button>
          <Button variant="text" fullWidth onClick={handleSecondaryAction}>
            {cardData.secondaryAction.text}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
