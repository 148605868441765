import { Stack, Text } from '@ltvco/refresh-lib/theme';
import { pluralizeString } from '@ltvco/refresh-lib/utils';
import { ReviewCard } from './ReviewCard/ReviewCard';
import { AggregatedReview } from './AggregatedReview/AggregatedReview';
import {
  AggregatedReviewScoreConverted,
  VehicleReviewConverted,
} from 'api/requests/aurelia/interfaces';

interface ReviewsContainerProps {
  reviews: VehicleReviewConverted[];
  aggregatedData: AggregatedReviewScoreConverted;
  ymmText: string;
  redirectToVehicleReview: () => void;
}
export const ReviewsContainer = ({
  reviews,
  aggregatedData,
  ymmText,
  redirectToVehicleReview,
}: ReviewsContainerProps) => {
  const sectionTitle = `${aggregatedData.totalReviews} ${pluralizeString(
    'Overall Review',
    reviews.length
  )}`;

  return (
    <Stack>
      <Text variant="h2" color="primary.main">
        {sectionTitle}
      </Text>
      <AggregatedReview
        ymmText={ymmText}
        aggregatedData={aggregatedData}
        redirectToVehicleReview={redirectToVehicleReview}
      />
      <Text variant="h3" color="primary.main">
        Latest Owner Reviews
      </Text>
      <Stack spacing={2.5}>
        {reviews.map((review: any, index: number) => (
          <ReviewCard key={index} review={review} />
        ))}
      </Stack>
    </Stack>
  );
};
