import { Button, styled } from '@ltvco/refresh-lib/theme';

export const BannerContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.info.dark,
}));

export const BannerContent = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  position: 'relative',
  maxWidth: '1600px',
}));

export const SvgContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  maxWidth: '300px',
}));

export const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: `0 0 0 1px ${theme.palette.grey[200]}`,
  maxWidth: '260px',
  maxHeight: '300px',
  margin: 'auto',
}));

export const CardIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

export const CardButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(3.5),
}));

export const CardText = styled('div')(({ theme }) => ({
  padding: `0 ${theme.spacing(1)}`,
  fontSize: theme.spacing(2),
  alignSelf: 'center',
  marginBottom: theme.spacing(2.5),
  textAlign: 'center',
}));

export const BannerText = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(2.5),
  alignSelf: 'center',
  color: theme.palette.common.black,
}));

export const PrimaryText = styled('p')(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'center',
  margin: 0,
}));

export const SecondaryText = styled('span')(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const StyledCloseButton = styled(Button)(({ theme }) => ({
  zIndex: 2,
  minWidth: 'unset',
  color: theme.palette.text.secondary,

  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    right: theme.spacing(2),
  },

  '&:hover': {
    backgroundColor: 'transparent',
    outline: 'none',
  },

  '&:focus': {
    outline: 'none',
  },
}));

export const GoodhoodCtaButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  lineHeight: 1.5,
  margin: '8px',
  padding: '4px 8px',
  minWidth: '100px',
  maxHeight: '48px',

  '&:hover': {
    backgroundColor: theme.palette.common.white,

    span: {
      color: theme.palette.common.black,
    },
  },
}));
