import { useContext } from 'react';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useQuery } from '@ltvco/refresh-lib/vendors';
import { locationRequest } from 'api/requests/location/location';

export const useZipConverter = (zip: string) => {
  const { logError } = useContext(AppConfig);
  const result = useQuery({
    queryKey: [zip],
    queryFn: () => {
      return locationRequest.zipToLocation(zip);
    },
    enabled: Boolean(zip) && zip.length === 5,
    onError: (error: Error) => {
      logError(
        'Error fetching city and state from zip converter',
        error as Error
      );
    },
  });

  return { ...result };
};
