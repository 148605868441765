import { useEffect } from 'react';
import { Stack } from '@ltvco/refresh-lib/theme';
import { VehicleReview } from 'components/VehicleReview/VehicleReview';

export function VehicleReviewPage() {
  useEffect(() => {
    document.title = 'Vehicle Review - Bumper';
  }, []);

  return (
    <Stack pt={1.5}>
      <VehicleReview />
    </Stack>
  );
}
