import { Box, Stack, Text } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { ApiSolutionCard } from './ApiSolutionCard/ApiSolutionCard';
import { apiSolutionsText } from 'utils/constants/apiSolutionsText';

/**
 * Component for displaying the API solutions section.
 *
 * This component renders a section with a title and a series of API solution cards.
 * The layout of the cards changes based on the screen size, using a row layout
 * for larger screens and a column layout for tablets. Each card represents a different
 * solution and is rendered using the ApiSolutionCard component.
 *
 * @returns {React.ReactElement} A React element representing the API solutions section.
 */
export default function ApiSolutions() {
  const { isTablet } = useScreenSize();

  return (
    <Box>
      <Text variant="h2" textAlign="center" mt={4}>
        Solutions for any automotive business
      </Text>
      <Stack
        direction={isTablet ? 'column' : 'row'}
        flexWrap="wrap"
        justifyContent="space-around"
        useFlexGap
      >
        {apiSolutionsText.map((solution) => (
          <ApiSolutionCard key={solution.title} solution={solution} />
        ))}
      </Stack>
    </Box>
  );
}
