import {
  Box,
  Button,
  Grid,
  Stack,
  styled,
  Text,
} from '@ltvco/refresh-lib/theme';
import { Check } from '@mui/icons-material';
import bumperConnect from 'images/bumper_connect_cta_image.svg';
import { useSmartcar } from './useSmartcar';

const StyledTagText = styled(Text)(({ theme }) => ({
  marginTop: '8px',
  marginBottom: '8px',
  padding: '2px 5px',
  borderRadius: '9.5px',
  backgroundImage: 'linear-gradient(to left, #8736c1, #ff4722)',
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#ffffff',
}));

const StyledPillText = styled(Box)(({ theme }) => ({
  marginBottom: '12px',
  padding: '2px 5px',
  borderRadius: '4px',
  backgroundImage: 'linear-gradient(to left, #8736c1, #ff4722)',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#ffffff',
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
}));

const StyledImage = styled('img')`
  max-width: 60px;
  max-height: 100px;
  object-fit: contain;
`;

export const VehicleComptabileCta: React.FC = () => {
  const { openDialog } = useSmartcar();
  const benefits = [
    'Odometer readings',
    'Lock & unlock doors',
    'EV battery level',
    'EV charging',
    'Fuel tank level',
    'Tire pressure',
    'Engine oil life',
    'And much more',
  ];

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{ mt: 2.5, mb: 2.5, borderTop: '1px solid #eeeeee' }}
    >
      <StyledTagText>NEW</StyledTagText>
      <StyledPillText>
        <Check fontSize="small" sx={{ mb: -0.75, color: '#fff' }} />
        Your car is compatible!
      </StyledPillText>
      <ImageContainer sx={{ mb: 2 }}>
        <StyledImage src={bumperConnect} alt={'Connect logo'} />
      </ImageContainer>
      <Text variant="h3" textAlign="center" color="primary.dark">
        Monitor real-time data from your car
      </Text>
      <Text
        variant="h4"
        textAlign="center"
        sx={{ fontWeight: 300, fontSize: '16px' }}
      >
        Connect your car to stay up-to-date on your vehicle's most important
        metrics on the go
      </Text>
      <Grid container sx={{ mb: 1.5 }}>
        {benefits.map((benefit) => (
          <Grid item key={benefit} xs={6} sx={{ fontSize: '14px' }}>
            <Check
              sx={{ m: 0.75, mb: -0.75, color: 'primary.dark' }}
              fontSize="small"
            />
            {benefit}
          </Grid>
        ))}
      </Grid>
      <Button onClick={() => openDialog(false)} variant="contained">
        Connect this car
      </Button>
    </Stack>
  );
};
