import { useLocation } from 'react-router-dom';
import { ResetPasswordForm } from '@ltvco/refresh-lib/v1';

export const ResetPassword = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const token = searchParams.get('t');

  if (!token) {
    return <h1>Invalid token</h1>;
  }

  return <ResetPasswordForm token={token} />;
};
