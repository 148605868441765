import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ReportLoading,
  ReportNullState,
  ReportRating,
  SmartInsights,
  UpgradePremiumDataModal,
  UserReportPreferencesModal,
  VehicleAuctions,
  VehicleDamageHistory,
  VehicleDetailedHistory,
  VehicleExports,
  VehicleImpounds,
  VehicleInspections,
  VehicleLiens,
  VehicleMaintenanceSchedule,
  VehicleOwnershipCost,
  VehicleOwnershipHistory,
  VehicleOverview,
  VehicleRecalls,
  VehicleReport,
  VehicleReportDisclaimer,
  VehicleSafetyComplaints,
  VehicleSafetyRatings,
  VehicleSales,
  VehicleServiceRepairs,
  VehicleSpecs,
  VehicleThefts,
  VehicleTitleBrands,
  VehicleTitleRecords,
  VehicleTrimSelect,
  VehicleValuation,
  VehicleWarranty,
  AdditionalRecordsModal,
  EtagCTA,
  GetMostOut,
  HideableSection,
  NotesSection,
  PremiumSectionVariation,
  generateVehicleSpecs,
  getPremiumRecordsFound,
  useAccount,
  useFreeUpgradeMutation,
  ReportChangesOverview,
} from '@ltvco/refresh-lib/v1';
import { FeaturesReady, UseQueryResult } from '@ltvco/refresh-lib/vendors';
import {
  AppConstants,
  HiddenSectionsProvider,
  useReportContext,
} from '@ltvco/refresh-lib/ctx';
import {
  isZeroed,
  useScrollToSectionOnNavigate,
  DateUtil,
} from '@ltvco/refresh-lib/utils';
import { Grid, Box } from '@ltvco/refresh-lib/theme';
import {
  getVehicleNavLinkData,
  getVehicleNavLinkDataV2,
} from 'navLinkData/vehicleNavLinkData';
import { ReportSectionSelfInspectionCTA } from 'components/SelfInspection/CTAs/ReportSectionSelfInspectionCTA/ReportSectionSelfInspectionCTA';
import { ReportNavigationSelfInspectionCTA } from 'components/SelfInspection/CTAs/ReportNavigationSelfInspectionCTA/ReportNavigationSelfInspectionCTA';
import { SelfInspectionResults } from 'components/SelfInspection/SelfInspectionResults/SelfInspectionResults';
import { useBowtie } from 'hooks/useBowtie';
import { useStatesContact } from 'hooks/useStatesContact';
import { useVehicleComplaints } from 'hooks/useVehicleComplaints';
import { getDefaultUserReportPreferences } from './Preferences/defaultUserReportPreferences';
import { VehicleReviews } from './VehicleReviews/VehicleReviews';
import type { ReportRouteProps } from './types';
import {
  ReportNavigationMenu,
  ReportNavigationMobileMenu,
  ReportActionsWithDateUpdated,
  ReportNavigationMenuProps,
} from '@ltvco/refresh-lib/v2';

interface VehicleReportProps extends ReportRouteProps {}

export function VehicleReportPage({
  permalink,
  isMonitored = false,
}: VehicleReportProps) {
  const reportQuery = useReportContext()?.reportQuery;
  const { data, isInitialLoading, isError } = reportQuery ?? {};

  // Hooks
  const {
    images: { imgReportChecked },
  } = useContext(AppConstants);
  const { data: accountData } = useAccount(true);
  const [preferencesModalVisible, setPreferencesModalVisible] = useState(false);
  const hiddenSections =
    accountData?.account?.user_report_preferences.hidden_sections
      .vehicle_report || [];
  const newReport = data?.meta && new VehicleReport(data);
  const reportData = newReport?.data;
  const year = reportData?.automobiles?.[0]?.year?.toString() || '';
  const make = reportData?.automobiles?.[0]?.make || '';
  const model = reportData?.automobiles?.[0]?.model || '';
  const reportTitle =
    reportData?.automobiles?.[0]?.vehicleYMM?.toUpperCase() ||
    reportData?.meta?.metaName ||
    '';
  const defaultUserReportPreferences = getDefaultUserReportPreferences();
  const [sectionsToHide, setSectionsToHide] = useState(
    defaultUserReportPreferences
  );
  const vehicleComplaints = useVehicleComplaints(year, make, model);
  const upgradeReport = useFreeUpgradeMutation(permalink);
  const statesContact = useStatesContact();

  const [showAdditionalRecordsModal, setShowAdditionalRecordsModal] =
    useState(false);
  const [premiumRecordsFound, setPremiumRecordsFound] = useState<string[]>([]);
  const [upgradeReportCalled, setUpgradeReportCalled] = useState(false);
  const bowtie = useBowtie(data?.meta?.search_data?.vin);

  // AB Tests
  const { hash } = useLocation();
  useScrollToSectionOnNavigate(hash, reportQuery as UseQueryResult<any, Error>);

  useEffect(() => {
    document.title = `${reportTitle || 'Search'} - Bumper`;
  }, [data]);

  useEffect(() => {
    if (hiddenSections) {
      setSectionsToHide((prevSections) =>
        prevSections.map((navSection) => ({
          ...navSection,
          sections: navSection.sections.map((section) => ({
            ...section,
            checked: !hiddenSections.includes(section.nameToRecord),
          })),
        }))
      );
    }
  }, [accountData]);

  const vehicleNavLinkDataV2 = getVehicleNavLinkDataV2(
    reportData || null,
    vehicleComplaints?.data?.complaints?.length || 0,
    bowtie?.data?.answer_sets.length || 0
  );
  const vehicleNavLinkData = getVehicleNavLinkData(
    reportData || null,
    vehicleComplaints?.data?.complaints?.length || 0,
    bowtie?.data?.answer_sets.length || 0
  );

  if (isInitialLoading || isError) {
    return <ReportLoading menuItems={10} />;
  }

  const zeroed = isZeroed(data);
  if (zeroed) {
    return <ReportNullState />;
  }

  if (!reportData && (upgradeReport.isSuccess || upgradeReport.isLoading)) {
    return <UpgradePremiumDataModal />;
  }

  if (!reportData || !reportData.automobiles)
    return <ReportLoading menuItems={10} />;

  if (reportData?.automobiles?.length === 0) return <ReportNullState />;

  const vehicleSpecs = generateVehicleSpecs(reportData);

  const isYMMSearch = reportData.meta?.search_by === 'ymm';
  const ymmPresent = [year, make, model].filter(Boolean);
  const hasAllYMMPresent = ymmPresent.length === 3;
  const ymm = ymmPresent.join(' ');
  const reportSubtitle = !isYMMSearch ? reportData.meta?.metaName : '';

  const handleFreeUpgrade = () => {
    upgradeReport.mutate({ permalink });
    setUpgradeReportCalled(true);
  };

  const handleOnClickPremiumData = () => {
    setShowAdditionalRecordsModal(!showAdditionalRecordsModal);
    setUpgradeReportCalled(!upgradeReportCalled);
  };

  const handlePreferencesModalVisibility = () => {
    setPreferencesModalVisible(!preferencesModalVisible);
  };

  if (
    reportData &&
    upgradeReportCalled &&
    upgradeReport.isSuccess &&
    !showAdditionalRecordsModal
  ) {
    setPremiumRecordsFound(getPremiumRecordsFound(reportData));
    setShowAdditionalRecordsModal(true);
  }

  const date = new DateUtil();
  const reportUpdateDate = reportData.meta?.updated_at
    ? date.parseDateFromString(
        reportData.meta?.updated_at,
        'yyyy-MM-dd',
        'yyyy-MM-dd HH:mm:ss ZZZ'
      )
    : '';

  const isUpgradedAndSuccess =
    reportData.report_upgraded && reportData?.automobiles?.[0]?.nmvtis_success;

  return (
    <HiddenSectionsProvider reportType="vehicle_report">
      <>
        {upgradeReport.isLoading && <UpgradePremiumDataModal />}
        <AdditionalRecordsModal
          recordsFound={premiumRecordsFound}
          showModal={showAdditionalRecordsModal}
          handleOnClose={handleOnClickPremiumData}
        />
        <Grid container direction={'row'} columns={12} spacing={7}>
          <Grid
            item
            sm={12}
            md={4}
            lg={4}
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' },
            }}
          >
            <ReportNavigationMenu
              reportType="Vehicle Report"
              headerTitle={reportTitle || reportSubtitle || ''}
              subheaderTitle={reportSubtitle || ''}
              showReportType={false}
              menuItems={
                vehicleNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
              }
              customTitleStyle={{
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                color: '#0A0B0D',
              }}
              customCTA={
                <ReportNavigationSelfInspectionCTA
                  vin={reportData?.meta?.search_data?.vin}
                  permalink={permalink}
                />
              }
            />
          </Grid>
          <Grid
            item
            sm={12}
            md={8}
            lg={8}
            marginTop={{
              xs: 0,
              md: 0.5,
            }}
            sx={{
              '&.MuiGrid-item': {
                paddingTop: { xs: 3.75, md: 7 },
              },
            }}
          >
            {isMonitored && (
              <ReportChangesOverview
                permalink={permalink}
                navLinkData={vehicleNavLinkData}
              />
            )}

            <>
              <Box
                sx={{
                  height: 30,
                  backgroundColor: '#f8f8f8',
                  position: 'sticky',
                  marginBottom: '-10px',
                  marginX: -1,
                  top: 52,
                  zIndex: 10,
                  display: {
                    xs: 'none',
                    sm: 'block',
                    md: 'block',
                    lg: 'block',
                  },
                }}
              />
              <ReportActionsWithDateUpdated
                reportTitle="Vehicle"
                reportType="vehicle"
                dateUpdated={reportUpdateDate}
                reportUpgraded={false}
                sx={{
                  top: '50px',
                  position: 'sticky',
                }}
              />
            </>

            {isYMMSearch && (
              <VehicleTrimSelect year={year} make={make} model={model} />
            )}
            <VehicleOverview
              reportType="vehicle-report"
              vehicleReportData={reportData}
              permalink={permalink}
              nmvtisSuccess={reportData?.automobiles?.[0]?.nmvtis_success}
              reportUpgraded={reportData.report_upgraded}
              handleFreeUpgrade={handleFreeUpgrade}
              newPremiumRecordsFound={premiumRecordsFound}
              badge={
                <Box sx={{ position: 'absolute', top: '-45px' }}>
                  <img
                    height="90px"
                    width="90px"
                    src={imgReportChecked}
                    alt="Report Checked"
                  />
                </Box>
              }
              containerStyling={{
                marginTop: 7,
                paddingTop: 6,
                position: 'relative',
              }}
            />
            {!isYMMSearch && hasAllYMMPresent && (
              <HideableSection
                nameToDisplay="Smart Insights"
                nameToRecord="smart_insights"
                id="smart-insights"
              >
                <Box mb={2.5} id="smart-insights">
                  <SmartInsights reportData={reportData} />
                </Box>
              </HideableSection>
            )}
            <GetMostOut
              sectionId="get-most-out"
              claimed={reportData.meta?.tag === 'self' ? true : false}
              meta={reportData.meta}
            />
            <VehicleReviews
              ymmText={ymm}
              ymm={{ year, make, model }}
              permalink={permalink}
            />
            <>
              {!isYMMSearch && (
                <>
                  <HideableSection
                    nameToDisplay="Ownership History"
                    nameToRecord="ownership_history"
                    id="vehicle-ownership-history"
                  >
                    <VehicleOwnershipHistory
                      vehicleOwnershipHistories={
                        reportData?.automobiles?.[0]?.ownership_history
                      }
                    />
                  </HideableSection>
                  <HideableSection
                    nameToDisplay="Title Records"
                    nameToRecord="title_records"
                    id="vehicle-title-records"
                  >
                    {isUpgradedAndSuccess ? (
                      <VehicleTitleRecords
                        vehicleTitleRegistrationsList={
                          reportData?.automobiles?.[0]?.title_registrations
                        }
                        statesContact={
                          statesContact?.data?.states_contact || []
                        }
                      />
                    ) : (
                      <PremiumSectionVariation
                        showTopBar
                        sectionId="vehicle-title-records"
                        sectionName="Title Records"
                        nmvtisSuccess={
                          reportData?.automobiles?.[0]?.nmvtis_success
                        }
                        reportUpgraded={reportData.report_upgraded}
                        handleFreeUpgrade={handleFreeUpgrade}
                      />
                    )}
                  </HideableSection>
                  <EtagCTA />
                  <HideableSection
                    nameToDisplay="Title Brands"
                    nameToRecord="title_brands"
                    id="vehicle-title-brands"
                  >
                    {isUpgradedAndSuccess ? (
                      <VehicleTitleBrands
                        vehicleTitleBrandsList={
                          reportData?.automobiles?.[0]?.title_checks
                        }
                        statesContact={
                          statesContact?.data?.states_contact || []
                        }
                      />
                    ) : (
                      <PremiumSectionVariation
                        showTopBar
                        sectionId="vehicle-title-brands"
                        sectionName="Title Brands"
                        nmvtisSuccess={
                          reportData?.automobiles?.[0]?.nmvtis_success
                        }
                        reportUpgraded={reportData.report_upgraded}
                        handleFreeUpgrade={handleFreeUpgrade}
                      />
                    )}
                  </HideableSection>
                  <HideableSection
                    nameToDisplay="Lien Records"
                    nameToRecord="lien_records"
                    id="vehicle-liens-report-section"
                  >
                    <VehicleLiens
                      vehicleLiensList={reportData?.automobiles?.[0]?.liens}
                    />
                  </HideableSection>
                  <HideableSection
                    nameToDisplay="Impound Records"
                    nameToRecord="impound_records"
                    id="vehicle-impound"
                  >
                    <VehicleImpounds
                      vehicleImpoundList={
                        reportData?.automobiles?.[0]?.impounds
                      }
                    />
                  </HideableSection>
                  <HideableSection
                    nameToDisplay="Export Records"
                    nameToRecord="export_records"
                    id="vehicle-exports"
                  >
                    <VehicleExports
                      vehicleExportList={reportData?.automobiles?.[0]?.exports}
                    />
                  </HideableSection>
                </>
              )}
              <HideableSection
                nameToDisplay="Specs"
                nameToRecord="specifications"
                id="vehicle-specs"
              >
                <VehicleSpecs
                  vehicleSpecs={vehicleSpecs}
                  vehicleDesign={reportData?.automobiles?.[0]?.design}
                />
              </HideableSection>
            </>
            {isYMMSearch && (
              <ReportSectionSelfInspectionCTA
                vin={reportData?.meta?.search_data?.vin}
                permalink={permalink}
              />
            )}
            {!isYMMSearch && (
              <>
                <FeaturesReady>
                  {reportData?.automobiles?.[0]?.detailedHistory && (
                    <HideableSection
                      nameToDisplay="Detailed History"
                      nameToRecord="detailed_history"
                      id="detailed-history"
                    >
                      <VehicleDetailedHistory
                        detailedHistory={
                          reportData?.automobiles?.[0]?.detailedHistory
                        }
                        ymm={ymm}
                      />
                    </HideableSection>
                  )}
                </FeaturesReady>
                <HideableSection
                  nameToDisplay="Self Inspection"
                  nameToRecord="self_inspection"
                  id="self-inspection"
                >
                  <SelfInspectionResults
                    vin={reportData?.meta?.search_data?.vin}
                    vehicleType={{
                      vehicleType: reportData?.automobiles?.[0]?.type,
                      vehicleSize: reportData?.automobiles?.[0]?.size,
                      vehicleDoors: reportData?.automobiles?.[0]?.design?.doors,
                    }}
                  />
                  <ReportSectionSelfInspectionCTA
                    vin={reportData?.meta?.search_data?.vin}
                    permalink={permalink}
                  />
                </HideableSection>
                <HideableSection
                  nameToDisplay="Sale Listings"
                  nameToRecord="sales"
                  id="vehicle-sales"
                >
                  <VehicleSales
                    vehicleSalesList={reportData?.automobiles?.[0]?.sales}
                  />
                </HideableSection>
                <HideableSection
                  nameToDisplay="Auctions"
                  nameToRecord="auctions"
                  id="vehicle-auctions"
                >
                  <VehicleAuctions
                    vehicleAuctionsList={reportData?.automobiles?.[0]?.auctions}
                    vehicleYMM={reportData?.automobiles?.[0]?.vehicleYMM || ''}
                  />
                </HideableSection>
                <HideableSection
                  nameToDisplay="Damage History"
                  nameToRecord="damage_history"
                  id="vehicle-damage-history"
                >
                  <VehicleDamageHistory
                    nmvtisSuccess={reportData?.automobiles?.[0]?.nmvtis_success}
                    reportUpgraded={reportData.report_upgraded}
                    handleFreeUpgrade={handleFreeUpgrade}
                    vehicleAccidentsList={
                      reportData?.automobiles?.[0]?.damage_history?.accidents ||
                      reportData?.automobiles?.[0]?.accidents
                    }
                    vehicleAuctionsDamageList={
                      reportData?.automobiles?.[0]?.damage_history
                        ?.reported_damage ||
                      reportData?.automobiles?.[0]?.auctionsDamage
                    }
                    vehicleSalvagesList={
                      reportData?.automobiles?.[0]?.damage_history?.salvage ||
                      reportData?.automobiles?.[0]?.salvage
                    }
                    vehicleRepairQuotesList={
                      reportData?.automobiles?.[0]?.damage_history
                        ?.repair_quotes || []
                    }
                  />
                </HideableSection>
                <HideableSection
                  nameToDisplay="Theft Records"
                  nameToRecord="theft_records"
                  id="vehicle-thefts"
                >
                  <VehicleThefts
                    vehicleTheftsList={reportData?.automobiles?.[0]?.thefts}
                  />
                </HideableSection>
                <HideableSection
                  nameToDisplay="Service and Repairs"
                  nameToRecord="service_and_repairs"
                  id="vehicle-service-and-repairs"
                >
                  <VehicleServiceRepairs
                    repairs={reportData?.automobiles?.[0]?.repairs || []}
                  />
                </HideableSection>
                <HideableSection
                  nameToDisplay="Inspections"
                  nameToRecord="inspections"
                  id="vehicle-inspections"
                >
                  <VehicleInspections
                    inspectionEvents={
                      reportData?.automobiles?.[0]?.inspection_events
                    }
                  />
                </HideableSection>
                <HideableSection
                  nameToDisplay="Maintenance Schedule"
                  nameToRecord="maintenance_schedule"
                  id="vehicle-maintenance"
                >
                  <VehicleMaintenanceSchedule
                    vehicleMaintenanceSchedules={
                      reportData?.automobiles?.[0]?.maintenances || []
                    }
                  />
                </HideableSection>
              </>
            )}
            <HideableSection
              nameToDisplay="Recalls"
              nameToRecord="recalls"
              id="vehicle-recalls"
            >
              <VehicleRecalls
                vehicleRecallList={[
                  ...(reportData?.automobiles?.[0]?.openRecalls || []),
                  ...(isYMMSearch
                    ? reportData?.automobiles?.[0]?.otherRecalls || []
                    : reportData?.automobiles?.[0]?.closedRecalls || []),
                ]}
              />
            </HideableSection>

            <HideableSection
              nameToDisplay="Market Value"
              nameToRecord="market_value"
              id="vehicle-market"
            >
              <VehicleValuation vehicleReport={reportData} />
            </HideableSection>

            <HideableSection
              nameToDisplay="Ownership Costs"
              nameToRecord="ownership_costs"
              id="vehicle-ownership-cost"
            >
              <VehicleOwnershipCost
                vehicleOwnershipCosts={
                  reportData?.automobiles?.[0]?.ownership_costs
                }
                vehicleYMM={reportData?.automobiles?.[0]?.vehicleYMM || ''}
              />
            </HideableSection>
            <HideableSection
              nameToDisplay="Warranties"
              nameToRecord="warranties"
              id="vehicle-warranty"
            >
              <VehicleWarranty
                vehicleWarrantyList={reportData?.automobiles?.[0]?.warranties}
              />
            </HideableSection>
            <HideableSection
              nameToDisplay="Vehicle Safety"
              nameToRecord="vehicle_safety"
              id="vehicle-warranty"
            >
              <VehicleSafetyRatings
                vehicleSafetyRatingsList={
                  reportData?.automobiles?.[0]?.safety_ratings || []
                }
                vehicleYMM={reportData?.automobiles?.[0]?.vehicleYMM || ''}
                complaintsCount={
                  vehicleComplaints?.data?.complaints?.length || 0
                }
              />
              <VehicleSafetyComplaints
                ymm={reportData?.automobiles?.[0]?.vehicleYMM || ''}
                complaints={vehicleComplaints?.data?.complaints}
              />
            </HideableSection>
            <NotesSection permalink={permalink} />
            <ReportRating
              permalink={permalink}
              rating={reportData.meta?.rating || null}
            />
            <VehicleReportDisclaimer />
          </Grid>
        </Grid>
        <ReportNavigationMobileMenu
          reportType="Vehicle Report"
          headerTitle={reportTitle || reportSubtitle || ''}
          subheaderTitle={reportSubtitle || ''}
          showReportType={false}
          menuItems={
            vehicleNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
          }
          customTitleStyle={{
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            color: '#0A0B0D',
          }}
        />
        {preferencesModalVisible && (
          <UserReportPreferencesModal
            sectionsToHide={sectionsToHide}
            handleOnClose={handlePreferencesModalVisibility}
          />
        )}
      </>
    </HiddenSectionsProvider>
  );
}
