import { request } from '@ltvco/refresh-lib/utils';
import {
  VehicleReviewParams,
  VehicleReviewResponse,
  CreateVehicleReviewParams,
  CreateVehicleReviewResponse,
} from './interfaces';

const aureliaUrl = '/aurelia';

interface VehicleReviewsInterface {
  getVehicleReviews: (
    token: string,
    params: VehicleReviewParams
  ) => Promise<VehicleReviewResponse>;
  createVehicleReview: (
    token: string,
    params: CreateVehicleReviewParams
  ) => Promise<CreateVehicleReviewResponse>;
}

const getVehicleReviews = async (
  token: string,
  params: VehicleReviewParams
) => {
  const searchParams = new URLSearchParams();
  searchParams.append('year', params.year);
  searchParams.append('make', params.make);
  searchParams.append('model', params.model);
  searchParams.append('trim', params.trim ?? '');

  const init = {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  };

  return await request(`${aureliaUrl}/vehicle_reviews?${searchParams}`, init);
};

const createVehicleReview = async (
  token: string,
  params: CreateVehicleReviewParams
) => {
  const init = {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify(params),
  };

  return await request(`${aureliaUrl}/vehicle_reviews`, init);
};

export const aurelia: VehicleReviewsInterface = {
  getVehicleReviews,
  createVehicleReview,
};
