import { Text, Link } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { StyledApiPlanCard, StyledPlanButton } from '../components';

/**
 * React component for displaying the Enterprise plan card.
 *
 * This component renders a card specifically for the Enterprise plan. It includes
 * text indicating that the plan is suitable for over 1,000 reports and provides
 * a contact email and a link to a contact form. The layout adjusts based on
 * screen size.
 *
 * @returns {React.ReactElement} A React element representing the Enterprise plan card.
 */
export function EnterprisePlanCard() {
  const { isTablet } = useScreenSize();

  return (
    <StyledApiPlanCard sx={{ justifyContent: 'space-between' }}>
      <Text variant="h5" mb={isTablet ? 4 : 2}>
        Enterprise
      </Text>
      <Text>For plans over 1,000 reports contact us:</Text>
      <Text variant="h5">business@bumper.com</Text>
      <Link
        href="https://forms.gle/MsshKYQfoMsdmP459"
        target="_blank"
        rel="noopener noreferrer"
        width="100%"
      >
        <StyledPlanButton variant="contained" color="success">
          Contact us
        </StyledPlanButton>
      </Link>
    </StyledApiPlanCard>
  );
}
