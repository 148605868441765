import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Button,
  CircularProgress,
  Divider,
  Link,
  Stack,
  Text,
} from '@ltvco/refresh-lib/theme';
import { convertKeysToSnakeCase } from '@ltvco/refresh-lib/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAccount, useReportRedirect } from '@ltvco/refresh-lib/v1';
import { ChevronLeft } from '@mui/icons-material';
import { CreateVehicleReviewParams } from 'api/requests/aurelia/interfaces';
import { constants } from 'appConstants';
import { useAurelia } from 'hooks/useAurelia';
import { useZipConverter } from 'hooks/useZipConverter';
import { vehicleReviewSchema } from 'utils/formSchemas/formSchemas';
import { defaultValues } from './constants';
import { StyledButton } from './components';
import { FormValues } from './interfaces';
import { FormYMM } from './FormYMM/FormYMM';
import { RadioQuestions } from './RadioQuestions/RadioQuestions';
import { RatingQuestions } from './RatingQuestions/RatingQuestions';
import { ReviewInputs } from './ReviewInputs/ReviewInputs';
import { SubmissionFeedbackPanel } from './SubmissionFeedbackPanel/SubmissionFeedbackPanel';
import { SubmittingAsForm } from './SubmittingAsForm/SubmittingAsForm';

export function VehicleReview() {
  const tosLink = constants.links.tosLink;
  const [showForm, setShowForm] = useState(true);
  const [isValidSubmission, setIsValidSubmission] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { redirectToReportUrl } = useReportRedirect();
  const accountData = useAccount(true);
  const firstName = `${accountData?.data?.account?.user_info?.first_name}`;
  const [zipCode, setZipCode] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    control,
    formState,
    watch,
    reset,
  } = useForm<FormValues>({
    defaultValues: defaultValues,
    resolver: yupResolver(vehicleReviewSchema),
  });
  const {
    data: cityStateLocationData,
    isSuccess: isCityStateLocationDataSuccess,
  } = useZipConverter(zipCode);

  useEffect(() => {
    if (location.state?.ymm) {
      setValue('year', location.state?.ymm.year, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue('make', location.state?.ymm.make, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue('model', location.state?.ymm.model, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }

    if (firstName) {
      setValue('name', firstName, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [location.state?.ymm, firstName]);

  useEffect(() => {
    clearErrors('zip');
    const hasLocationData = Boolean(
      cityStateLocationData?.entities?.locations[0]
    );
    if (
      zipCode.length === 5 &&
      !hasLocationData &&
      isCityStateLocationDataSuccess
    ) {
      setError('zip', {
        type: 'manual',
        message: 'Invalid zip code',
      });
      return;
    }

    if (hasLocationData && isCityStateLocationDataSuccess) {
      setValue('city', cityStateLocationData?.entities?.locations[0]?.city, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue('state', cityStateLocationData?.entities?.locations[0]?.state, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [zipCode, cityStateLocationData, isCityStateLocationDataSuccess]);

  const { createVehicleReviewMutation } = useAurelia({
    year: '',
    make: '',
    model: '',
  });

  const { errors, isDirty, isValid } = formState;

  const onSubmit = async (data: FormValues) => {
    setIsSubmitting(true);
    if (!isDirty || !isValid) return;
    const formattedData = convertKeysToSnakeCase(data);

    createVehicleReviewMutation.mutate(
      {
        ...formattedData,
        user_code: accountData?.data?.account?.user_info?.user_code!,
      } as CreateVehicleReviewParams,
      {
        onSuccess: () => {
          setIsValidSubmission(true);
          reset();
        },
        onError: () => {
          setIsValidSubmission(false);
        },
        onSettled: () => {
          setShowForm(false);
          setIsSubmitting(false);
        },
      }
    );
  };

  const handleSubmitAnonymous = (event: React.SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).checked
      ? 'Anonymous'
      : firstName;
    setValue('name', value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleRedirect = () => {
    if (!isValidSubmission) {
      setShowForm(true);
      return;
    }

    redirectLocation();
  };

  const redirectLocation = () => {
    if (location.state?.permalink) {
      redirectToReportUrl(
        'vehicle',
        `${location.state?.permalink}#vehicle-review`
      );
    } else {
      navigate('/dashboard/home');
    }
  };

  const ymmText = location.state?.ymmText;

  return (
    <Stack alignItems="center" justifyContent="center">
      {showForm ? (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack
            py={{ xs: 4, md: 6 }}
            px={{ xs: 2, md: 0 }}
            width={{ xs: '100%', md: '607px' }}
          >
            <StyledButton
              startIcon={<ChevronLeft />}
              variant="text"
              onClick={redirectLocation}
            >
              Back to Report
            </StyledButton>
            <FormYMM setValue={setValue} errors={errors} ymmText={ymmText} />
            <RatingQuestions control={control} />
            <Divider variant="fullWidth" flexItem sx={{ my: 2.5 }} />

            <ReviewInputs errors={errors} register={register} watch={watch} />
            <Divider variant="fullWidth" flexItem sx={{ my: 2.5 }} />

            <RadioQuestions control={control} />
            <Divider variant="fullWidth" flexItem sx={{ my: 2.5 }} />

            <SubmittingAsForm
              firstName={firstName}
              register={register}
              handleSubmitAnonymous={handleSubmitAnonymous}
              setZipCode={setZipCode}
              errors={errors}
            />
            <Stack alignItems={{ xs: 'normal', md: 'center' }} spacing={3}>
              <Button
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="success"
                size="large"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : null
                }
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
              <Text variant="body2" textAlign="center">
                By posting this review, you agree to strictly abide by our{' '}
                <Link
                  href={tosLink}
                  sx={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noopener"
                  color="#8736C1"
                >
                  Terms
                </Link>
                , including posting no objectionable language. You agree and
                certify that all the submitted answers to questions are true and
                correct to the best of your knowledge and belief.
              </Text>
            </Stack>
          </Stack>
        </form>
      ) : (
        <SubmissionFeedbackPanel
          handleRedirect={handleRedirect}
          isValidSubmission={isValidSubmission}
        />
      )}
    </Stack>
  );
}
