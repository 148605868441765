import { useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, Box, BrandLogo, Button } from '@ltvco/refresh-lib/theme';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { SsoPromoBanner } from '@ltvco/refresh-lib/v1';
import {
  HeaderContainer,
  StyledContainer,
  StyledHeader,
  StyledHeaderNav,
  StyledBannerContainer,
} from './components';
import { ClassicHeaderContents } from './ClassicHeaderContents/ClassicHeaderContents';
import { BannerMigrationCta } from '@ltvco/refresh-lib/v2';
import { GoodhoodPromoBanner } from 'components/GoodhoodPromo';

const ButtonContainer = styled(Box)(({ theme }) => ({
  width: '55px',
  display: 'flex',
  flex: 1,

  button: {
    minWidth: 'inherit',
    padding: 0,
    height: '100%',
    width: '55px',

    '&:hover': {
      background: 'inherit',
    },
  },

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    paddingLeft: '1rem',

    button: {
      width: 'inherit',
    },
  },
}));

const StyledButton = styled(Button)({
  height: 'fit-content',
  py: 1.875,
  backgroundColor: 'transparent',

  '&.MuiButtonBase-root:hover': {
    bgcolor: 'transparent',
  },
});

interface ClassicHeaderProps {
  /** Callback to handle logout */
  handleLogout: () => void;
}

export const ClassicHeader = ({ handleLogout }: ClassicHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const headerRef = useRef(null);
  const { trackEventGA4 } = useContext(AppConfig);

  const isInSelfInspectionRoute = location?.pathname.includes(
    '/vehicle-self-inspection'
  );

  const handleBrandLogoClick = () => {
    trackEventGA4({
      event_name: 'header_engagement',
      type: 'nav - brand logo',
    });
    if (isInSelfInspectionRoute) return null;

    navigate('/dashboard');
  };

  return (
    <HeaderContainer ref={headerRef} id="outer-header-container">
      <StyledContainer id="styled-header">
        <StyledBannerContainer>
          <SsoPromoBanner hasCustomStyle={true} />
          <BannerMigrationCta hasCustomStyle />
          <GoodhoodPromoBanner />
        </StyledBannerContainer>
        <StyledHeader
          direction={'row'}
          justifyContent={'space-between'}
          id="main-header"
        >
          <StyledHeaderNav>
            <ButtonContainer>
              <StyledButton
                disabled={isInSelfInspectionRoute}
                className="brand-logo-btn"
                disableRipple
                onClick={handleBrandLogoClick}
                data-testid="brand-logo-btn"
              >
                <BrandLogo />
              </StyledButton>
            </ButtonContainer>
            {!isInSelfInspectionRoute && (
              <ClassicHeaderContents handleLogout={handleLogout} />
            )}
          </StyledHeaderNav>
        </StyledHeader>
      </StyledContainer>
    </HeaderContainer>
  );
};
