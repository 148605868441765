import { useState } from 'react';
import { Text, Stack } from '@ltvco/refresh-lib/theme';
import { BasicApiPlanSelector } from 'components/BasicApiPlanSelector/BasicApiPlanSelector';
import ApiSolutions from 'components/ApiSolutions/ApiSolutions';
import ApiFeaturesCard from 'components/ApiFeaturesCard/ApiFeaturesCard';
import ApiInfo from 'components/ApiInfo/ApiInfo';
import { CreditCalculator } from 'components/CreditCalculator/CreditCalculator';
import ApiFaqs from 'components/FAQ/ApiFaqs/ApiFaqs';

/**
 * React component for the API gated page.
 *
 * This component is the main container for the API gated page, including various
 * sub-components like BasicApiPlanSelector, ApiSolutions, ApiFeaturesCard,
 * ApiInfo, CreditCalculator, and ApiFaqs. It manages the state for the currently
 * selected plan type and passes it down to relevant child components.
 *
 * @returns {React.ReactElement} A React element representing the API gated page.
 */
export function ApiGatedPage() {
  const [currentlySelectedPlanType, setCurrentlySelectedPlanType] = useState<
    'pdf' | 'json'
  >('json');

  const handlePlanTypeChange = (planType: 'pdf' | 'json') => {
    setCurrentlySelectedPlanType(planType);
  };

  return (
    <Stack spacing={4}>
      <Stack spacing={2} alignItems="center" textAlign="center">
        <Text variant="h2">Maximize sales with a Bumper Report</Text>
        <Text>
          Increase confidence by providing your buyers and team with Bumper
          Vehicle data
        </Text>
        <Text>
          With our API integration, you can easily integrate data or reports on
          your site to provide quick and easy access
        </Text>
      </Stack>
      <BasicApiPlanSelector
        handlePlanTypeChange={handlePlanTypeChange}
        currentlySelectedPlanType={currentlySelectedPlanType}
      />
      <ApiSolutions />
      <ApiFeaturesCard
        currentlySelectedPlanType={currentlySelectedPlanType}
        handlePlanTypeChange={handlePlanTypeChange}
      />
      <ApiInfo currentlySelectedPlanType={currentlySelectedPlanType} />
      <CreditCalculator />
      <ApiFaqs />
    </Stack>
  );
}
