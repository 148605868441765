import {
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Text,
  styled,
} from '@ltvco/refresh-lib/theme';
import { ArrowBackIosNew, Warning } from '@mui/icons-material';
import { SectionCard as SectionCardI } from 'utils/constants/selfInspectionQuestionnaire';

interface SectionProps {
  sections: SectionCardI[];
  cardData: SectionCardI;
  actionHandlers: {
    next: () => void;
    back: () => void;
    exit: () => void;
    complete: () => void;
    update: () => void;
  };
}

const StyledSectionContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
}));

export function Section({ sections, cardData, actionHandlers }: SectionProps) {
  const handlePrimaryAction = () => {
    actionHandlers[
      cardData.primaryAction.action as keyof typeof actionHandlers
    ]();
  };

  const handleSecondaryAction = () => {
    actionHandlers[
      cardData.secondaryAction.action as keyof typeof actionHandlers
    ]();
  };

  return (
    <StyledSectionContainer>
      <Stepper activeStep={cardData.sectionId - 1} sx={{ mb: 2.5 }}>
        {sections.map((section) => (
          <Step key={section.sectionId}>
            <StepLabel />
          </Step>
        ))}
      </Stepper>
      <Stack alignItems="center" direction="column" sx={{ flexGrow: 1 }}>
        {cardData.hasBack && (
          <Button
            sx={{ alignSelf: 'flex-start', fontSize: '.875rem' }}
            onClick={actionHandlers.back}
          >
            <ArrowBackIosNew fontSize="inherit" /> Back
          </Button>
        )}
        {cardData.cardInfo.icon && (
          <img
            src={cardData.cardInfo.icon}
            alt="section-icon"
            width="288px"
            height="auto"
          />
        )}
        <Text variant="h3" textAlign="center" mb={1.25}>
          {cardData.cardInfo.sectionMainHeader}
        </Text>
        {Boolean(cardData.cardInfo.sectionWarningText) && (
          <Stack direction="row" alignItems="center" mb={0.5}>
            <Warning fontSize="inherit" color={'error'} />
            <Text
              variant="body2"
              textAlign="center"
              sx={{ fontWeight: 'bold', color: 'error.main', ml: 0.5 }}
            >
              {cardData.cardInfo.sectionWarningText}
            </Text>
          </Stack>
        )}
        <Text variant="body1" textAlign="center" mb={2.25}>
          {cardData.cardInfo.sectionBody}
        </Text>
        <Stack width="100%" mt="auto" spacing={2.5}>
          <Button
            color="success"
            variant="contained"
            fullWidth
            onClick={handlePrimaryAction}
          >
            {cardData.primaryAction.text}
          </Button>
          <Button variant="text" fullWidth onClick={handleSecondaryAction}>
            {cardData.secondaryAction.text}
          </Button>
        </Stack>
      </Stack>
    </StyledSectionContainer>
  );
}
