import { Input } from '@ltvco/refresh-lib/theme';
import { ChangeEvent } from 'react';

interface InputQuestionProps {
  placeholder: string;
  updateAnswer: (newValue: string) => void;
  currentAnswer: string | null;
  isAlphaNumeric?: boolean;
}

export function InputQuestion({
  placeholder,
  updateAnswer,
  currentAnswer,
  isAlphaNumeric,
}: InputQuestionProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = isAlphaNumeric
      ? event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
      : event.target.value.replace(/^0+|[^0-9]/g, '');
    updateAnswer(value);
  };

  return (
    <Input
      variant="outlined"
      fullWidth
      label={placeholder}
      value={currentAnswer}
      onChange={handleChange}
    />
  );
}
