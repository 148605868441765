import { MenuItem, styled } from '@ltvco/refresh-lib/theme';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { HeaderSimpleMenuListItem } from '../types';
import { Link } from 'react-router-dom';
import { useContext } from 'react';

const StyledHeaderDropdownMenuItem = styled(MenuItem)({
  padding: 0,

  '& > a': {
    textDecoration: 'none',
    padding: '1rem',
    fontWeight: 700,
    color: 'black',
  },
});

interface HeaderSimpleMenuItemProps {
  listItem: HeaderSimpleMenuListItem;
  handleClose: () => void;
}

export const HeaderSimpleMenuItem = ({
  listItem,
  handleClose,
}: HeaderSimpleMenuItemProps) => {
  const { trackEventGA4 } = useContext(AppConfig);

  const handleItemClick = () => {
    trackEventGA4({
      event_name: 'header_engagement',
      type: `${listItem.eventLabel} - ${listItem.visibleText}`.toLowerCase(),
    });
    handleClose();
  };

  return (
    <StyledHeaderDropdownMenuItem
      disableGutters
      disableRipple
      onClick={handleItemClick}
    >
      <Link
        to={listItem.url}
        rel={listItem.openInNewTab ? 'noopener noreferrer' : ''}
        target={listItem.openInNewTab ? '_blank' : ''}
        onClick={handleClose}
      >
        {listItem.visibleText}
      </Link>
    </StyledHeaderDropdownMenuItem>
  );
};
