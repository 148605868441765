import {
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  styled,
  Text,
} from '@ltvco/refresh-lib/theme';
import { Check } from '@mui/icons-material';
import bumperConnect from '../../../images/img_bumper_connect.svg';
import { useSmartcar } from '../useSmartcar';

interface ConnectCTAModalProps {
  /** If true, the modal is open. */
  open: boolean;
  /** Callback fired when the user tries to close the modal by either clicking on the backdrop or pressing the escape key */
  handleCloseModal: () => void;
  /** Require Bumper connect */
}

const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
}));

const StyledImage = styled('img')`
  max-width: 60px;
  max-height: 100px;
  object-fit: contain;
`;

const StyledModal = styled(Modal)(({ theme }) => ({
  '.MuiDialog-paper': {
    width: '460px',
    borderRadius: '12px',
    positive: 'relative',

    '.MuiDialogContent-root': {
      padding: theme.spacing(2.5),
    },
  },
}));

const StyledText = styled(Text)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

/**
 * This component is a generic modal used to display the claim vehicle form
 */
export const ConnectCTAModal: React.FC<ConnectCTAModalProps> = ({
  open,
  handleCloseModal,
}) => {
  const benefits = [
    'Odometer readings',
    'Lock & unlock doors',
    'EV battery level',
    'EV charging',
    'Fuel tank level',
    'Tire pressure',
    'Engine oil life',
    'And much more',
  ];
  const { openDialog } = useSmartcar();

  const handleOpenDiaglog = () => {
    openDialog();

    setTimeout(handleCloseModal, 3000);
  };

  return (
    <StyledModal
      open={open}
      onClose={handleCloseModal}
      data-testid="connect-cta-modal"
    >
      <Stack direction="column" alignItems="center">
        <ImageContainer sx={{ mb: 2 }}>
          <StyledImage src={bumperConnect} alt={'Connect logo'} />
        </ImageContainer>

        <Text variant="h3">Monitor real-time data from your car</Text>
        <Text variant="h5" textAlign="center">
          Connect your car to stay up-to-date on your vehicle's most important
          metrics on the go
        </Text>

        <Grid container sx={{ mb: 1.5 }}>
          {benefits.map((benefit) => (
            <Grid item key={benefit} xs={6}>
              <Check sx={{ m: 0.75, mb: -0.75, color: 'primary.dark' }} />
              {benefit}
            </Grid>
          ))}
        </Grid>
        <Button
          variant="contained"
          color="success"
          sx={{ mb: 3 }}
          onClick={handleOpenDiaglog}
        >
          Connect your car
        </Button>
        <StyledText variant="body2" onClick={handleCloseModal}>
          No thanks
        </StyledText>
      </Stack>
    </StyledModal>
  );
};
