import {
  createTheme,
  LinearProgressProps,
  Theme,
} from '@ltvco/refresh-lib/theme';

export const theme = createTheme({
  typography: {
    button: { textTransform: 'none', fontSize: '1rem', fontWeight: 'bold' },
    fontSize: 16,
    htmlFontSize: 16,
    fontFamily: "'Nunito Sans', sans-serif",
    h1: {
      fontSize: '1.875rem',
      fontWeight: '700',
      margin: '0 0 12px 0',
      letterSpacing: '1.5px',
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: '700',
      margin: '0 0 12px 0',
      letterSpacing: '-0.5px',
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: '700',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: '700',
      margin: '0 0 12px 0',
      letterSpacing: '0.25px',
      lineHeight: 1.2,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: '700',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: '700',
      margin: '0 0 12px 0',
      letterSpacing: '0.15px',
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: '400',
      letterSpacing: '0.15px',
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: '500',
      letterSpacing: '0.1px',
      lineHeight: 1.2,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: '400',
      letterSpacing: '0.15px',
      lineHeight: 1.2,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: '400',
      letterSpacing: '0.17px',
      lineHeight: 1.2,
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: '400',
      letterSpacing: '0.4px',
      lineHeight: 1.2,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: '400',
      letterSpacing: '1px',
      lineHeight: 1.2,
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          backgroundColor: '#fff',
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: '#fff',
          },
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: '#F8F8F8',
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.checked && {
            '& .MuiSwitch-thumb': {
              backgroundColor: '#8736C1',
            },
            '&.report-preferences-switch': {
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#8736C1',
              },
            },
          }),
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: 32,
          boxShadow: 'none',
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'info' && {
              '&:hover': {
                color: '#ffffff',
              },
            }),
          // Using this for the button in the credit calculator
          // <Button variant="contained" color="error">
          // will allow us to have a purple button with white text
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'error' && {
              backgroundColor: '#8736C1',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#8736C1',
              },
            }),

          '&.overview-monitor-vehicle': {
            backgroundColor: '#8736C1',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#8736C1',
            },

            '&.monitored': {
              backgroundColor: 'white',
              color: '#8736C1',
              border: '1px solid #8736C1',
            },
          },
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: () => ({
          '&.hidden-section-icon': {
            color: '#FF4722',
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#E2E2E2',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            padding: 12,
          },
          div: {
            height: 40,
            backgroundColor: '#fff',
          },
          '& .MuiFormLabel-root': {
            top: -8,
          },
          '& .Mui-focused': {
            top: 0,
          },
          '& .MuiFormLabel-filled': {
            top: 0,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === 'success' && {
            '&.Mui-checked': {
              color: '#f1401d',
            },
          }),
        }),
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: (props: { ownerState: LinearProgressProps; theme: Theme }) => ({
          backgroundColor: (() => {
            switch (props.ownerState.color) {
              case 'primary':
                return '#8736C180';
              case 'success':
                return '';
              case 'error':
                return '#D32F2F80';
              default:
                return props?.theme?.palette?.progress?.main;
            }
          })(),
        }),
        bar: (props: { ownerState: LinearProgressProps; theme: Theme }) => ({
          backgroundColor: (() => {
            switch (props.ownerState.color) {
              case 'primary':
                return '#8736C1';
              case 'success':
                return '#388E3C';
              case 'error':
                return '#D32F2F';
              default:
                return props?.theme?.palette?.progress?.main;
            }
          })(),
        }),
      },
    },
    MuiStack: {
      styleOverrides: {
        root: () => ({
          '&.smart-insights-badge': {
            backgroundImage: `linear-gradient(to right, rgba(135,54,193,1) 0%, rgba(255, 71, 34, 1))`,
          },
          '&.market-analysis-container': {
            backgroundColor: '#FF47220A',
          },
        }),
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: () => ({
          backgroundColor: '#E6E7E9 !important',
          '&.dark-mode': {
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
          },
        }),
      },
    },
  },
  palette: {
    primary: {
      main: '#212121',
      dark: '#f1401d',
      light: '#ff8a65',
      contrastText: '#fff',
    },
    secondary: {
      main: '#21212114',
      dark: '#FF472280',
      light: '#FF47221F',
      contrastText: '#000000',
    },
    info: {
      main: '#FBE9E7',
      dark: '#f1401d',
      light: '#03A9F4',
      contrastText: '#000',
    },
    success: {
      main: '#212121',
      dark: '#212121',
      light: '#4CAF50',
      contrastText: '#ffffff',
    },
    error: {
      main: '#D32F2F',
      dark: '#C62828',
      light: '#D32F2F1F',
      contrastText: '#000',
    },
    warning: {
      main: '#ED6C02',
      dark: '#E65100',
      light: '#FF9800',
      contrastText: '#000',
    },
    background: { paper: '#fff', default: '#f8f8f8' },
    barChart: {
      main: '#4A3B8F',
      light: '#8736C14D',
      dark: '#D3D3D7',
      contrastText: '#000',
    },
    lineChart: {
      main: '#4A3B8F',
      light: '#757575',
      dark: '#DC0015',
      contrastText: '#000',
    },
    progress: {
      main: '#4A3B8F',
      light: '#757575',
      dark: '#DC0015',
      contrastText: '#E2E2E2',
    },
    premium: { main: '#FCC41C', contrastText: '#FFF' },
    listingPins: {
      main: '#EC413D',
      light: '#4AA351',
      dark: '#4A3B8F',
      contrastText: '#81b44c',
    },
    listingPinsVisited: {
      main: '#f8a09e',
      light: '#9FCBA2',
      dark: '#9F97C1',
    },
    reportSectionSupertitleBackgroundColor: {
      main: 'rgba(95, 45, 176, 1)',
    },
    text: {
      primary: '#0A0B0D',
      secondary: '#5B616E',
      disabled: '#929AAB',
      contrastText: '#fff',
    },
  },
});
