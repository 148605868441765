import { useEffect, useState } from 'react';
import { Box, Grid, Skeleton } from '@ltvco/refresh-lib/theme';
import { useSmartcar } from './useSmartcar';
import { useSmartcarConnectedVehicles } from './useSmartcarConnectedVehicles';
import { VehicleComptabileCta } from './VehicleCompatibleCta';
import { SmartcarDetail } from './SmartcarDetail';
import { useSmartcarCompatibility } from './useSmartcarCompatibility';

interface SmartcarContainerProps {
  vin: string;
}

export const SmartcarContainer: React.FC<SmartcarContainerProps> = ({
  vin,
}) => {
  const [connectedVehicle, setConnectedVehicle] = useState<string>('');
  const { data: smartcarCompatibility } = useSmartcarCompatibility(vin);
  const { data: smartcarConnectedVehicles } = useSmartcarConnectedVehicles();
  const { data: smartcarDetail, isLoading: loadingSmartcarDetail } =
    useSmartcar(connectedVehicle);

  useEffect(() => {
    const connectedVehicle = smartcarConnectedVehicles?.vehicles?.find(
      (vehicle: {
        year: number;
        make: string;
        model: string;
        vin: string;
        smartcar_id: string;
      }) => vehicle.vin === vin
    );
    setConnectedVehicle(connectedVehicle?.smartcar_id);
  }, [vin, smartcarConnectedVehicles]);

  if (Boolean(connectedVehicle) && loadingSmartcarDetail)
    return <LoadingSmartcarDetail />;
  if (smartcarDetail) return <SmartcarDetail smartcarDetail={smartcarDetail} />;
  if (smartcarCompatibility?.compatible) return <VehicleComptabileCta />;

  return <Box />;
};

function LoadingSmartcarDetail() {
  return (
    <Grid
      container
      spacing={2}
      sx={{ my: 1 }}
      data-testid="smartcar-loading-panel"
    >
      <Grid item xs={12}>
        <Skeleton variant="rounded" height={60} animation="pulse" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" height={80} animation="pulse" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" height={30} animation="pulse" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" height={30} animation="pulse" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" height={126} animation="pulse" />
      </Grid>
    </Grid>
  );
}
