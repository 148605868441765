import { useContext } from 'react';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useQuery } from '@ltvco/refresh-lib/vendors';
import { locationRequest } from 'api/requests/location/location';

export const useIpToZip = (ip: string) => {
  const { logError } = useContext(AppConfig);
  const result = useQuery({
    queryKey: [ip],
    queryFn: () => {
      return locationRequest.ipToZip(ip);
    },
    enabled: Boolean(ip) && ip.split('.').length === 4,
    onError: (error: Error) => {
      logError(
        'Error fetching city and state from zip converter',
        error as Error
      );
    },
  });

  return { ...result };
};
