import { request } from '@ltvco/refresh-lib/utils';

export const fetchSavedVehicles = async (
  userCode: string,
  token: string,
  url: string
) => {
  const data: { user_id: string; page: number; per_page: number } = {
    user_id: userCode,
    page: 1,
    per_page: 0,
  };

  const queryParams = Object.keys(data)
    .map((key) => `${key}=${data[key as keyof typeof data]}`)
    .join('&');
  return await request(`${url}/?${queryParams}`, {
    method: 'GET',
    headers: new Headers({
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
};
