import {
  Button,
  Box,
  Text,
  Stack,
  styled,
  Grid,
} from '@ltvco/refresh-lib/theme';
import { useNavigate } from 'react-router-dom';
import rewardCard from 'images/rewards-card.png';
import rewardBenefits from 'images/rewards-benefits.png';
import rewardBlur from 'images/reward_blur.png';
import rewardGraphic from 'images/rewards-graphic.png';

interface FreeUserRewardContainerProps {
  displayCTABanner?: boolean;
  displayBenefits?: boolean;
}

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  backgroundImage:
    'linear-gradient(to bottom, #ffffff 70%, rgba(148, 40, 200, 0.07))',
}));

const StyledOverlayContainer = styled(Box)(({ theme }) => ({
  zIndex: 1,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: theme.spacing(1.25),
  boxShadow: '0 2px 10px 1px rgba(117,117,117,0.5)',
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.background.paper,
  textAlign: 'center',
  minWidth: '288px',
}));

const rewardBenefitList = [
  {
    title: 'Radio Streaming',
    description:
      'Get 4 months of free streaming to make your rides more entertaining',
  },
  {
    title: 'Car Wash',
    description:
      'Get 30 days of unlimited free car washes to keep your car squeaky clean',
  },
  {
    title: 'Parts & Accessories',
    description:
      'Grab parts and accessories at a discounted rate with your Bumper membership',
  },
  {
    title: 'Local auto repair services',
    description:
      'Discounts for Bumper customers for their auto repair or maintenance services',
  },
];

export const FreeUserRewardContainer = ({
  displayCTABanner,
  displayBenefits,
}: FreeUserRewardContainerProps) => {
  const navigate = useNavigate();
  const handleUpgradeNav = () => {
    navigate('/upgrade/plan');
  };

  return (
    <Box>
      {displayCTABanner && (
        <StyledGridContainer
          container
          justifyContent="center"
          sx={{ paddingBottom: 1.25 }}
        >
          <Grid item xs={12} sm={6}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1.25}
              sx={{ height: '100%' }}
            >
              <Text variant="h2" sx={{ fontWeight: 400, textAlign: 'center' }}>
                Enjoy exclusive savings with <strong>Bumper</strong>{' '}
                <Text
                  variant="h2"
                  component="span"
                  sx={{ color: 'primary.dark' }}
                >
                  Rewards
                </Text>
              </Text>
              <Text variant="body1">We got your Bumper</Text>
              <Button
                variant="contained"
                sx={{ mt: '20px !important' }}
                onClick={handleUpgradeNav}
              >
                Upgrade to unlock rewards
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1.25}
              sx={{ height: '100%' }}
            >
              <img
                src={rewardCard}
                alt="reward-card"
                width="100%"
                height="auto"
              />
              <Text variant="body2" textAlign="center">
                Disclaimer: This virtual membership card for Rewards is for
                illustrative purposes only
              </Text>
            </Stack>
          </Grid>
        </StyledGridContainer>
      )}
      {displayBenefits && (
        <>
          <Grid container sx={{ pt: 2.5 }}>
            <Text variant="h2" sx={{ textAlign: 'center', width: '100%' }}>
              Savings to honk about
            </Text>
            <Text
              variant="h3"
              sx={{ textAlign: 'center', width: '100%', fontWeight: 400 }}
            >
              Bumper has partnered with leading car industry brands to unlock
              potential savings for you
            </Text>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1.25}>
                {rewardBenefitList.map((benefit) => (
                  <Stack>
                    <Text variant="h5">{benefit.title}</Text>
                    <Text variant="subtitle1">{benefit.description}</Text>
                  </Stack>
                ))}
                <Text variant="subtitle1">And much more</Text>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                src={rewardBenefits}
                alt="reward-benefit"
                width="100%"
                height="auto"
                style={{ maxWidth: '400px', maxHeight: '400px' }}
              />
            </Grid>
          </Grid>
          <Box sx={{ position: 'relative' }}>
            <img
              src={rewardBlur}
              alt="reward-benefit"
              width="100%"
              height="auto"
              style={{ minHeight: '450px' }}
            />
            <StyledOverlayContainer>
              <Text variant="h2" sx={{ fontWeight: 400 }}>
                Save{' '}
                <Text
                  variant="h2"
                  component="span"
                  sx={{ color: 'primary.dark' }}
                >
                  time and money
                </Text>{' '}
                with Bumper
              </Text>
              <Text variant="body1">Access thousands in rewards</Text>
              <Button
                variant="contained"
                sx={{ mt: '20px !important', mb: 3.25 }}
                onClick={handleUpgradeNav}
              >
                Upgrade to unlock rewards
              </Button>
              <img
                src={rewardGraphic}
                alt="reward-graphic"
                width="100%"
                height="auto"
              />
            </StyledOverlayContainer>
          </Box>
          <Text variant="body2" sx={{ my: 2.5 }}>
            <strong>Advertising disclaimer:</strong> Certain products and
            services, including vehicle purchase/sale opportunities, provided
            above include offers from third parties and/or external partners
            from whom we may receive limited compensation. Please be advised
            that these offers are provided as a convenience and are a sample,
            but do not necessarily include ALL offers, products or services
            available in the marketplace.
          </Text>
        </>
      )}
    </Box>
  );
};
