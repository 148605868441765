import { ClickAwayListener } from '@ltvco/refresh-lib/theme';
import { ForwardRefDiv } from '@ltvco/refresh-lib/v1';
import { useHeaderContext } from 'context/HeaderContext';
import { ClassicHeaderDropdownMenu } from '../../ClassicHeaderDropdownMenu/ClassicHeaderDropdownMenu';
import { HeaderItemName } from '../../types';
import { HeaderSimpleMenuItem } from './HeaderSimpleMenuItem/HeaderSimpleMenuItem';
import { HeaderSimpleMenuListItem } from './types';

interface HeaderSimpleMenuProps {
  name: HeaderItemName;
  anchorEl: HTMLElement | null;
  id: string;
  handleClose: () => void;
  listItems: HeaderSimpleMenuListItem[];
}

export const HeaderSimpleMenu = ({
  name,
  id,
  anchorEl,
  handleClose,
  listItems,
}: HeaderSimpleMenuProps) => {
  const { currentDropdown } = useHeaderContext();

  const handleClickAway = (event: Event) => {
    const allowedElements = [`#${id}`];
    const clickedOnAllowedElement = allowedElements.some((selector) =>
      (event && (event.target as Element))?.closest(selector)
    );

    if (
      !clickedOnAllowedElement ||
      (currentDropdown !== null && currentDropdown !== name)
    ) {
      handleClose();
    }
  };

  if (currentDropdown !== name) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ForwardRefDiv>
        <ClassicHeaderDropdownMenu
          anchorEl={anchorEl}
          open={currentDropdown === name}
          id={id}
        >
          {listItems.map((listItem, index) => {
            return (
              <HeaderSimpleMenuItem
                key={index}
                handleClose={handleClose}
                listItem={listItem}
              />
            );
          })}
        </ClassicHeaderDropdownMenu>
      </ForwardRefDiv>
    </ClickAwayListener>
  );
};
