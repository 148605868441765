import { Stack, Text } from '@ltvco/refresh-lib/theme';
import { VehicleDisclaimer } from '@ltvco/refresh-lib/v2';
export const CustomDisclaimer = () => {
  return (
    <Stack data-cy="footer_disclaimer" spacing={3} maxWidth={1290}>
      <Text variant="body2" color="text.secondary">
        The information contained in any report and on our website is not
        necessarily 100% accurate, complete or up to date, nor a substitute for
        your own due diligence, especially concerning such sensitive items as
        criminal history, relatives, mortgages and liens. Our data comes from a
        wide variety of sources, but some municipalities and jurisdictions are
        slow to report and digitize their data, so we cannot guarantee or
        warrant full accuracy of ALL search results.
      </Text>
      <VehicleDisclaimer fontSize="14px" />
    </Stack>
  );
};
