import { useContext } from 'react';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useQuery } from '@ltvco/refresh-lib/vendors';
import { statesContact } from '@ltvco/refresh-lib/v1';

export const useStatesContact = () => {
  const { logError } = useContext(AppConfig);
  const result = useQuery<any>({
    queryKey: ['statesContact'],
    queryFn: () => {
      return statesContact();
    },

    onError: (error) => {
      logError('Error fetching vehicle complaints', error as Error);
    },
  });

  return { ...result };
};
