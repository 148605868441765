import { Answer } from 'api/requests/bowtie/interfaces';
import {
  Card,
  QuestionCard,
} from 'utils/constants/selfInspectionQuestionnaire';
import { UpdatedQuestionnaire } from './SelfInspectionResult/interface';

export function mergeAnswersWithQuestionnaire(
  questionnaire: Card[],
  answers: Answer[],
  vehicleType?: {
    vehicleType: string | null;
    vehicleSize: string | null;
    vehicleDoors: number | null | undefined;
  }
): UpdatedQuestionnaire[][] {
  const answersMap = new Map<string, string>();

  answers.forEach((answer) => {
    answersMap.set(answer.screen_id, answer.response[0]);
  });

  const groupedQuestions: {
    [sectionId: number]: UpdatedQuestionnaire[];
  } = {};
  const questionCards = questionnaire.filter(
    (card): card is QuestionCard => card.cardType === 'question'
  );

  questionCards.forEach((card) => {
    const answer = answersMap.get(card.screenId);
    const updatedCard = {
      question: card.cardInfo.question,
      description: card.cardInfo.description,
      sectionId: card.sectionId,
      answer: Boolean(answer) ? answer : 'No response',
    };

    if (!groupedQuestions[updatedCard.sectionId]) {
      groupedQuestions[updatedCard.sectionId] = [];
    }

    groupedQuestions[updatedCard.sectionId].push(updatedCard);
  });

  // Delete the vin question
  groupedQuestions[1].splice(2, 1);

  // Add vehicle type to the first group
  groupedQuestions[1].push({
    question: 'Vehicle type',
    description: undefined,
    sectionId: 1,
    answer: formatVehicleDescription(vehicleType),
  });

  return Object.values(groupedQuestions);
}

function formatVehicleDescription(
  vehicleDetails:
    | {
        vehicleType: string | null;
        vehicleSize: string | null;
        vehicleDoors: number | null | undefined;
      }
    | undefined
) {
  if (!vehicleDetails) return '';
  const { vehicleType, vehicleSize, vehicleDoors } = vehicleDetails;
  const doorString = vehicleDoors ? `${vehicleDoors} door` : '';

  return [vehicleSize, vehicleType, doorString].filter(Boolean).join(' ');
}
