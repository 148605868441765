import { Avatar, Button, Card, Stack, Text } from '@ltvco/refresh-lib/theme';
import { useNavigate } from 'react-router-dom';
import SelfInspectionChecklist from 'images/inspection-checklist-icon.svg';

interface ReportNavigationSelfInspectionCTAProps {
  vin?: string;
  permalink?: string;
}

export const ReportNavigationSelfInspectionCTA = ({
  vin,
  permalink,
}: ReportNavigationSelfInspectionCTAProps) => {
  const navigate = useNavigate();

  const redirectToVehicleInspectionSurvey = () => {
    navigate('/vehicle-self-inspection', { state: { vin, permalink } });
  };

  return (
    <Card variant="outlined" sx={{ boxShadow: 1, mb: 0, mt: 1 }}>
      <Stack spacing={1} direction="row" alignItems="center">
        <Avatar
          src={SelfInspectionChecklist}
          sx={{ width: 19, height: 19 }}
          variant="square"
        />
        <Text>Vehicle Self-Inspection</Text>
      </Stack>
      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 1 }}
        onClick={redirectToVehicleInspectionSurvey}
      >
        Start inspection
      </Button>
    </Card>
  );
};
