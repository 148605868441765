import { smartcarConstants } from '../../utils/constants/smartcarConstants';
import { useQuery } from '@ltvco/refresh-lib/vendors';
import { request } from '@ltvco/refresh-lib/utils';

const baseQueryKey = 'smartcar-compatibility';

export const useSmartcarCompatibility = (vin: string) => {
  const result = useQuery({
    queryKey: [`${baseQueryKey}${vin}compatible`],
    queryFn: () => checkCompatibility(vin),
  });

  return { ...result };
};

const checkCompatibility = async (vin: string) => {
  return await request(
    `${smartcarConstants.connectedVehicleUrl}/check_compatibility?vin=${vin}`,
    { method: 'GET' }
  );
};
