import { useNavigate } from 'react-router-dom';
import { SearchData } from '@ltvco/refresh-lib/v1';

export const useHandleSearch = () => {
  const navigate = useNavigate();

  const handleSearchSuccess = ({
    reportType,
    searchParams,
    permalink,
  }: SearchData) => {
    const url = `/report/${reportType}?${searchParams}&permalink=${permalink}`;
    navigate(url);
  };

  return {
    handleSearchSuccess,
  };
};
