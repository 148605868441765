import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@ltvco/refresh-lib/theme';
import { ErrorMessageWithIcon } from 'components/shared/ErrorMessageWithIcon';

interface FormRadioProps {
  name: string;
  options: { label: string; value: string }[];
  control: any;
}

export function FormRadio({ name, options, control }: FormRadioProps) {
  return (
    <FormControl>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <>
            <RadioGroup value={value} onChange={onChange}>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
            {Boolean(errors[name]?.message) && (
              <ErrorMessageWithIcon
                errorMessage={errors[name]?.message?.toString() ?? ''}
              />
            )}
          </>
        )}
      />
    </FormControl>
  );
}
