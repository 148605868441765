import { request } from '@ltvco/refresh-lib/utils';
import { LocationData } from './interface';

interface locationInterface {
  zipToLocation: (zip: string) => Promise<LocationData>;
  ipToZip: (zip: string) => Promise<LocationData>;
}

const locationURL = '/hk/dd/v2/location';

async function zipToLocation(zip: string) {
  const searchParams = new URLSearchParams();
  searchParams.append('zip5', zip);
  searchParams.append('dd_plan', 'riverside');

  const init = {
    headers: {
      'content-type': 'application/json',
    },
    method: 'GET',
  };

  return await request(`${locationURL}?${searchParams}`, init);
}

async function ipToZip(ip: string) {
  const searchParams = new URLSearchParams();
  searchParams.append('ip_address', ip);
  searchParams.append('dd_plan', 'augusta');

  const init = {
    headers: {
      'content-type': 'application/json',
    },
    method: 'GET',
  };

  return await request(`${locationURL}?${searchParams}`, init);
}

export const locationRequest: locationInterface = {
  zipToLocation,
  ipToZip,
};
