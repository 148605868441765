import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { Box, Stack, Text } from '@ltvco/refresh-lib/theme';
import { YMMSearchForm } from '@ltvco/refresh-lib/v1';
import { ErrorMessageWithIcon } from 'components/shared/ErrorMessageWithIcon';
import { generateYMMErrorMessage } from 'utils/generateYMMErrorMessage';
import { FormValues } from '../interfaces';

type YMM = {
  year: string;
  make: string;
  model: string;
  trim?: string;
};

interface FormYMMProps {
  setValue: UseFormSetValue<FormValues>;
  errors: FieldErrors<FormValues>;
  ymmText: string;
}

export const FormYMM = ({ setValue, errors, ymmText }: FormYMMProps) => {
  const ymmError = generateYMMErrorMessage({
    year: Boolean(errors.year),
    make: Boolean(errors.make),
    model: Boolean(errors.model),
  });

  const handleUpdateYMMForm = ({ year, make, model, trim }: Partial<YMM>) => {
    if (year !== undefined) {
      setValue('year', year, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }

    if (make !== undefined) {
      setValue('make', make, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }

    if (model !== undefined) {
      setValue('model', model, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }

    if (trim !== undefined) {
      setValue('trim', trim, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  return (
    <Stack>
      <Text variant="h2">
        {Boolean(ymmText)
          ? `Write a vehicle review of ${ymmText}`
          : 'Write a vehicle review'}
      </Text>
      <Text variant="body1" mb={3}>
        All fields are required
      </Text>
      {!Boolean(ymmText) && (
        <>
          <Box mb={4}>
            <YMMSearchForm
              showSubmitButton={false}
              handleUpdateYMMForm={handleUpdateYMMForm}
              optionalProps={{
                yearWidth: 3,
                makeWidth: 3,
                modelWidth: 3,
                trimWidth: 3,
                selectHeight: '70px',
              }}
              isForm={false}
            />
          </Box>
          {ymmError?.hasError && (
            <ErrorMessageWithIcon errorMessage={ymmError.errorMessage} />
          )}
        </>
      )}
    </Stack>
  );
};
