import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { FormValues } from '../interfaces';
import { Stack, Text } from '@ltvco/refresh-lib/theme';
import { FormInput } from '../FormInput/FormInput';

interface ReviewInputsProps {
  errors: FieldErrors<FormValues>;
  register: UseFormRegister<FormValues>;
  watch: any;
}

export const ReviewInputs = ({
  errors,
  register,
  watch,
}: ReviewInputsProps) => {
  return (
    <Stack spacing={2.5}>
      <Text variant="h4">Your Review </Text>
      <FormInput
        title="Title"
        name={'title'}
        placeholder="Give your review a title"
        error={errors.title?.message}
        register={register}
        watch={watch}
        charLimit={60}
      />
      <FormInput
        title="Your Review"
        name={'review'}
        placeholder="Let others know what you like or dislike. What's import to know?"
        error={errors.review?.message}
        register={register}
        extendHeight={true}
        watch={watch}
        charLimit={500}
      />
    </Stack>
  );
};
