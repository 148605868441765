import { styled, Box, Card, Stack } from '@ltvco/refresh-lib/theme';

export const StyledCreditCalculatorCard = styled(Card)(({ theme }) => ({
  marginBottom: 0,
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    textAlign: 'left',
  },
}));

export const StyledRowHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
  padding: theme.spacing(1),
  background: theme.palette.background.default,
  [theme.breakpoints.down('md')]: { display: 'none' },
}));

export const StyledSuggestedPlanCard = styled(Card)(({ theme }) => ({
  flex: 1,
  height: '100%',
  marginBottom: 0,
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
}));

export const StyledSuggestedPlanCardStack = styled(Stack)(({ theme }) => ({
  flex: 1,
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(2),
  },
}));

export const StyledCreditRowBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid black',
  '&:last-child': { borderBottom: 'none' },
}));

export const StyledCreditRowStack = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-end',
  [theme.breakpoints.up('md')]: {
    width: '60%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
