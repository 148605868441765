import { Card, Text, Box, Stack } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { HardcodedPlan } from '@ltvco/refresh-lib/v1';
import { ApiPlanCard } from 'components/apiPlanCards/ApiPlanCard/ApiPlanCard';
import { EnterprisePlanCard } from 'components/apiPlanCards/EnterprisePlanCard/EnterprisePlanCard';
import { RadioButtonCard } from 'components/RadioButtonCard/RadioButtonCard';
import {
  BUMPER_HARDCODED_JSON_API_PLANS,
  BUMPER_HARDCODED_PDF_API_PLANS,
} from 'utils/constants/hardcodedPlans';

/**
 * Props for the BasicApiPlanSelector component.
 *
 * @interface
 * @property {(planType: 'pdf' | 'json') => void} handlePlanTypeChange - Callback function to handle plan type change.
 * @property {('pdf'|'json')} currentlySelectedPlanType - The currently selected plan type.
 */
interface BasicApiPlanSelectorProps {
  handlePlanTypeChange: (planType: 'pdf' | 'json') => void;
  currentlySelectedPlanType: 'pdf' | 'json';
}

/**
 * Array of API plan types with their corresponding titles and descriptions.
 *
 * Each element in the array represents a plan type (either JSON or PDF) with
 * its title and description for use in the BasicApiPlanSelector component.
 *
 * @constant {Array}
 */
const ApiPlanTypes = [
  {
    type: 'json',
    title: 'Raw Data API Plans',
    description: 'Get raw data API response for applicable data points.',
  },
  {
    type: 'pdf',
    title: 'PDF API Plans',
    description: 'Get a Bumper branded PDF report over API.',
  },
];

/**
 * React component for selecting a basic API plan type.
 *
 * This component renders a card that allows users to select between different
 * types of API plans (JSON or PDF). Based on the selection, it displays
 * relevant plans. It uses RadioButtonCard and ApiPlanCard components to
 * present the options.
 *
 * @param {BasicApiPlanSelectorProps} props - The props for the component.
 * @returns {React.ReactElement} A React element representing the basic API plan selector.
 */
export function BasicApiPlanSelector({
  handlePlanTypeChange,
  currentlySelectedPlanType,
}: BasicApiPlanSelectorProps) {
  const { isTablet } = useScreenSize();
  const selectedPlanType =
    ApiPlanTypes.find((plan) => plan.type === currentlySelectedPlanType) ||
    ApiPlanTypes[0];

  const currentlySelectedPlans =
    selectedPlanType.type === 'json'
      ? BUMPER_HARDCODED_JSON_API_PLANS
      : BUMPER_HARDCODED_PDF_API_PLANS;

  const handleClick = (planType: string) => {
    const foundPlanType =
      ApiPlanTypes.find((plan) => plan.title === planType) || ApiPlanTypes[0];

    handlePlanTypeChange(foundPlanType.type as 'pdf' | 'json');
  };

  return (
    <Card
      variant="outlined"
      sx={{ marginTop: 2, backgroundColor: 'background.default' }}
    >
      <Text variant="h3">Select your preferred plan type:</Text>
      <Stack direction={isTablet ? 'column' : 'row'} spacing={isTablet ? 0 : 2}>
        {ApiPlanTypes.map((planType) => (
          <Box width={isTablet ? '100%' : '33%'} key={planType.type}>
            <RadioButtonCard
              titleText={planType.title}
              bodyText={planType.description}
              currentlySelected={selectedPlanType.title}
              handleClick={handleClick}
            />
          </Box>
        ))}
      </Stack>
      <Text variant="h3" mt={2}>
        Select Plan:
      </Text>
      <Stack direction={isTablet ? 'column' : 'row'} spacing={2}>
        {currentlySelectedPlans.map((plan: HardcodedPlan) => (
          <Box width={isTablet ? '100%' : '25%'} key={plan.unique_key}>
            <ApiPlanCard plan={plan} />
          </Box>
        ))}
        <Box width={isTablet ? '100%' : '25%'}>
          <EnterprisePlanCard />
        </Box>
      </Stack>
    </Card>
  );
}
