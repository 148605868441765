import { useNavigate } from 'react-router-dom';
import { Text, Box } from '@ltvco/refresh-lib/theme';
import { commaFormat } from '@ltvco/refresh-lib/utils';
import { HardcodedPlan } from '@ltvco/refresh-lib/v1';
import { StyledApiPlanCard, StyledPlanButton } from '../components';

/**
 * Props for the ApiPlanCard component.
 *
 * @interface
 * @property {HardcodedPlan} plan - The plan data to display in the card.
 */
interface ApiPlanCardProps {
  plan: HardcodedPlan;
}

/**
 * Parses the non-discounted cost of a plan.
 *
 * Calculates the original cost of a plan based on its discounted cost and the discount percentage.
 *
 * @param {number} cost - The discounted cost of the plan.
 * @param {number} discount - The discount percentage.
 * @returns {number} The original cost of the plan before the discount.
 */
const parseNonDiscountCost = (cost: number, discount: number) => {
  if (isNaN(cost) || isNaN(discount)) return cost;
  const amount = Math.trunc(Math.floor(cost) / 100);
  return discount ? Math.trunc(amount / (1 - discount / 100)) : amount;
};

/**
 * Parses a discount string and calculates the non-discounted amount.
 *
 * @param {string} discount - The discount string, e.g., "50% off".
 * @param {number} amount - The discounted amount.
 * @returns {number} The calculated non-discounted amount.
 */
const parseDiscount = (discount: string, amount: number) => {
  const discountNum = parseInt(discount.split(' ')[1]) || 0;

  return parseNonDiscountCost(amount, discountNum);
};

/**
 * Component for displaying an individual API plan card.
 *
 * This component renders a card for an API plan, including title, cost,
 * discount information (if applicable), and monthly allowance (in credits or reports).
 * It uses StyledApiPlanCard and StyledPlanButton for styling.
 *
 * @param {ApiPlanCardProps} props - The props for the component.
 * @returns {React.ReactElement} A React element representing the API plan card.
 */
export function ApiPlanCard({ plan }: ApiPlanCardProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/upgrade/plan', {
      state: { plan_type: plan.plan_type, unique_key: plan.unique_key },
    });
  };

  const parseDescription = () => {
    if (plan.plan_type === 'json') {
      return <Text variant="body2">${plan.costPerCredit}/ per credit</Text>;
    }

    if (plan.discount) {
      return (
        <Box display="flex">
          <Text variant="body2" sx={{ textDecoration: 'line-through' }}>
            ${parseDiscount(plan.discount, plan.amount)}
          </Text>
          <Text variant="body2" fontWeight={700} ml={1}>
            {plan.discount}
          </Text>
        </Box>
      );
    }
    return <Text variant="body2">Most Popular</Text>;
  };

  const parseMonthlyAllowance = () => {
    return plan.credits
      ? `${commaFormat(plan.credits)} credits`
      : `${commaFormat(plan.monthly_report_limit)} reports`;
  };

  return (
    <StyledApiPlanCard>
      <Text variant="h5">{plan.title}</Text>
      <Box display="flex">
        <Text variant="h3">${plan.amount / 100}</Text>
        <Text variant="h3" fontWeight={500}>
          / mo
        </Text>
      </Box>
      <>{parseDescription()}</>
      <Text variant="body1" fontWeight={700} mt={2}>
        {parseMonthlyAllowance()}
      </Text>
      <Text variant="body1">per month</Text>
      <StyledPlanButton
        onClick={handleClick}
        variant="contained"
        color="success"
      >
        Select Plan
      </StyledPlanButton>
    </StyledApiPlanCard>
  );
}
