import { Control } from 'react-hook-form';
import { Box, Stack, Text } from '@ltvco/refresh-lib/theme';
import { FormValues, RatingQuestion } from '../interfaces';
import { ratingList } from '../constants';
import { FormRating } from './FormRating/FormRating';
import { theme } from 'theme';

interface RatingQuestionsProps {
  control: Control<FormValues, any>;
}

export const RatingQuestions = ({ control }: RatingQuestionsProps) => {
  const ratingQuestions: RatingQuestion[] = ratingList;

  return (
    <Stack spacing={2.5}>
      <Box sx={{ mb: `${theme.spacing(2.5)} !important` }}>
        <Text variant="h4">How would you rate the overall experience?</Text>
        <FormRating name="overallRating" control={control} />
      </Box>
      <Text variant="h4">Additional Ratings</Text>
      {ratingQuestions.map((rating) => (
        <Box key={rating.name}>
          <Text variant="h5">{rating.label}</Text>
          <FormRating name={rating.name} control={control} />
        </Box>
      ))}
    </Stack>
  );
};
