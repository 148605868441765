import Analytics from 'analytics';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { useEffect, useRef, useState } from 'react';
import googleTagManager, {
  GoogleTagManagerConfig,
} from '@analytics/google-tag-manager';
import { initialize } from '@ltv-apm-modules/react';
import { version } from '@ltvco/refresh-lib';
import { type ResponseError } from '@ltvco/refresh-lib/utils';
import { ErrorBoundary } from '@ltvco/refresh-lib/theme';
import {
  CancelFinalStepModal,
  closeSnackbar,
  ComplianceFormPage,
  CompliancePermittedPage,
  ComplianceProhibitedPage,
  ComplianceRecomplyPage,
  ContactOptionsModal,
  ConfirmCancelModal,
  ForgotLoginModal,
  invalidateSession,
  isUserLoggedIn,
  MagicLinkModal,
  NonCompliancePage,
  ResetPasswordRequestForm,
  SessionProvider,
  useSession,
} from '@ltvco/refresh-lib/v1';
import { styled } from '@ltvco/refresh-lib/theme';
import { SearchFormProvider, Footer } from '@ltvco/refresh-lib/v2';
import 'App.css';
import { ClassicHeader } from 'components/ClassicHeader/ClassicHeader';
import { Growthbook } from 'components/Growthbook';
import { EmailVerification } from 'components/EmailVerification';
import { LibContextWrapper } from 'components/LibContextWrapper';
import QueryWrapper from 'components/queryWrapper/QueryWrapper';
import { Recycling } from 'components/recycling/Recycling';
import { DashboardParent, Login, Report } from 'routes';
import {
  ApiPage,
  AuthenticatedRoute,
  Billing,
  ErrorPage,
  Lists,
  Monitoring,
  MyGarage,
  NotFound,
  OnboardingPage,
  PublicRoute,
  Reactivation,
  Referrals,
  ReportVelocityCaptcha,
  ResetPassword,
  RewardsPage,
  UpgradePlanPage,
  RecentReports,
} from 'routes';
import { SelfInspectionPage } from 'routes/SelfInspectionPage';
import { VehicleReviewPage } from 'routes/VehicleReviewPage';
import { HeaderProvider } from './context/HeaderContext';
import { useHandleSearch } from 'hooks/useHandleSearch';
import { goToLogin } from 'utils/goToLogin';
import { AppContainer } from 'components/AppContainer';
import { generateFooterLinks } from 'utils/footerLinks/footerLinks';
import packageInfo from '../package.json';
import { CustomDisclaimer } from 'utils/footerLinks';

let otel: any = null;

if (
  import.meta.env.VITE_TARGET_ENV === 'production' ||
  import.meta.env.VITE_TARGET_ENV === 'staging'
) {
  otel = initialize({
    name: 'refresh-bumper',
    url: import.meta.env.VITE_APM_URL,
    apikey: import.meta.env.VITE_APM_KEY,
  });
}

declare global {
  interface Window {
    env: Record<string, string>;
  }
}

const LibVersion = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(2)} 0 0 0`,
  fontSize: theme.typography.fontSize * 0.75,
}));

function App() {
  const analytics = Analytics({
    app: 'refresh-bumper',
    debug: true,
    plugins: [
      googleTagManager({
        containerId: 'GTM-KBK69NF',
        dataLayer: (window as any)?.dataLayer ?? [],
      } as GoogleTagManagerConfig),
    ],
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { session } = useSession();
  const [isCoreLoginRedirect] = useQueryParam(
    'is_core_login_redirect',
    StringParam
  );
  const [loginType] = useQueryParam('login_type', StringParam);

  const [isAuthenticated, setIsAuthenticated] = useState(isUserLoggedIn());
  const [isContactOptionsModalOpen, setIsContactOptionsModalOpen] =
    useState(false);
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);
  const [isCancelFinalStepModalOpen, setIsCancelFinalStepModalOpen] =
    useState(false);
  const loginPageContainerRef = useRef(null);
  const { handleSearchSuccess } = useHandleSearch();
  const showFooter =
    !location?.pathname.includes('/vehicle-self-inspection') &&
    !location?.pathname.includes('/vehicle-review');

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    if (
      isCoreLoginRedirect === 'true' &&
      !sessionStorage.getItem('has_tracked_core_login_redirect')
    ) {
      analytics.track('site_event', {
        event_name: 'login_attempt',
        type: loginType || 'web',
        success: true,
      });

      sessionStorage.setItem(
        'has_tracked_core_login_redirect',
        isCoreLoginRedirect
      );
    }
  }, [isCoreLoginRedirect]);

  async function handleLogoutClick(ga4Ttracker?: () => void) {
    await invalidateSession();
    setIsAuthenticated(isUserLoggedIn());
    closeSnackbar();
    goToLogin('https://www.bumper.com');
    if (ga4Ttracker) {
      ga4Ttracker();
    }
  }

  function isResponseError(error: any): error is ResponseError {
    return error.response !== undefined;
  }

  function logError(
    context: string,
    error: Error | ResponseError,
    url: string | undefined
  ) {
    const isProdEnv = import.meta.env.VITE_TARGET_ENV === 'production';
    if (!isProdEnv || !otel) return;

    const { account } = session;
    const { api } = otel;
    if (api.isOTELInitialized()) {
      if (account) {
        api.setUser({
          id: account?.account.user_info.user_code,
          email: account?.account.user_info.email,
        });
      }
      let errorMsg = `${context}: ${error.message}, Cause: ${error.cause}`;
      if (isResponseError(error)) {
        errorMsg = `${errorMsg}, Status: ${
          error.response.status
        }, Data: ${JSON.stringify(error.response)}`;
      }
      if (url) {
        errorMsg += `, URL: ${url}`;
      }
      api.pushError(new Error(errorMsg));
    }
  }

  function trackEventGA4(eventToTrack: object, eventName?: string) {
    const targetEnv = import.meta.env.VITE_TARGET_ENV;
    if (targetEnv === 'dev') return;
    analytics?.track(eventName || 'site_event', eventToTrack);
  }

  function trackEvent(category: string, action: string, label?: string) {
    analytics.track('ua_event', {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }

  const onLoginSuccess = () => {
    let next = '/';
    if (location.state && location.state.next) {
      next = location.state.next;
    }
    navigate(next === '/' ? '/dashboard/home' : next);
    setIsAuthenticated(isUserLoggedIn());
  };

  const contactUsHandler = () => {
    setIsContactOptionsModalOpen(true);
  };

  const footerLinks = generateFooterLinks({ contactUsHandler });

  const handleConfirmCancelModalState = () => {
    setIsConfirmCancelModalOpen(!isConfirmCancelModalOpen);
  };

  const handleCancelFinalStepModalState = () => {
    setIsCancelFinalStepModalOpen(!isCancelFinalStepModalOpen);
  };

  const handleConfirmCancelModalOnCancel = () => {
    handleConfirmCancelModalState();
    handleCancelFinalStepModalState();
  };

  return (
    <ErrorBoundary
      redirectFunction={() => {
        navigate('/error');
      }}
      location={location}
      logFunction={logError as any}
      trackEventFunction={trackEventGA4}
    >
      <QueryWrapper
        handleLogout={handleLogoutClick}
        trackEventGA4={trackEventGA4}
      >
        <SessionProvider isAuthenticated={isAuthenticated}>
          <Growthbook>
            <LibContextWrapper
              trackEventGA4={trackEventGA4}
              logError={logError}
              trackEvent={trackEvent}
              handleLogoutClick={handleLogoutClick}
            >
              <HeaderProvider>
                <SearchFormProvider>
                  <EmailVerification>
                    <div className="app">
                      <ClassicHeader handleLogout={handleLogoutClick} />
                      <AppContainer>
                        <Routes>
                          <Route element={<NotFound />} path={'*'} />
                          <Route element={<ErrorPage />} path={'/error'} />
                          <Route
                            index
                            element={<Navigate to={`/dashboard`} />}
                          />
                          <Route
                            path={`/login`}
                            element={
                              <PublicRoute>
                                <Login
                                  onLoginSuccess={onLoginSuccess}
                                  ref={loginPageContainerRef}
                                />
                              </PublicRoute>
                            }
                          >
                            <Route
                              path="reset-password-request"
                              element={
                                <ResetPasswordRequestForm
                                  containerRef={loginPageContainerRef}
                                  open={false}
                                />
                              }
                            />
                            <Route
                              path="magic-link"
                              element={
                                <MagicLinkModal
                                  containerRef={loginPageContainerRef}
                                  open={false}
                                />
                              }
                            />
                            <Route
                              path="forgot-email"
                              element={
                                <ForgotLoginModal
                                  containerRef={loginPageContainerRef}
                                  open={false}
                                />
                              }
                            />
                          </Route>
                          <Route
                            path={`/reset-password`}
                            element={
                              <PublicRoute>
                                <ResetPassword />
                              </PublicRoute>
                            }
                          />
                          <Route
                            path={`dashboard`}
                            element={
                              <AuthenticatedRoute>
                                <DashboardParent
                                  contactUsHandler={contactUsHandler}
                                  handleLogout={handleLogoutClick}
                                />
                              </AuthenticatedRoute>
                            }
                          >
                            <Route
                              index
                              element={<Navigate to={`/dashboard/home`} />}
                            />
                            <Route
                              path={`/dashboard/home`}
                              element={
                                <AuthenticatedRoute>
                                  <MyGarage />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={`/dashboard/reports`}
                              element={
                                <AuthenticatedRoute>
                                  <RecentReports />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={`/dashboard/rewards`}
                              element={
                                <AuthenticatedRoute>
                                  <RewardsPage />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={`/dashboard/lists`}
                              element={
                                <AuthenticatedRoute>
                                  <Lists />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={`/dashboard/monitoring`}
                              element={
                                <AuthenticatedRoute>
                                  <Monitoring />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={'/dashboard/my-garage'}
                              element={<Navigate to={`/dashboard/home`} />}
                            />
                            <Route
                              path={'/dashboard/api'}
                              element={
                                <AuthenticatedRoute>
                                  <ApiPage />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={`billing`}
                              element={
                                <AuthenticatedRoute>
                                  <Billing />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={`referrals`}
                              element={
                                <AuthenticatedRoute>
                                  <Referrals />
                                </AuthenticatedRoute>
                              }
                            />
                          </Route>
                          <Route
                            path={`/report/:reportType`}
                            element={
                              <AuthenticatedRoute>
                                <Report onSearchSuccess={handleSearchSuccess} />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={'/vehicle-self-inspection'}
                            element={
                              <AuthenticatedRoute>
                                <SelfInspectionPage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={'/vehicle-review'}
                            element={
                              <AuthenticatedRoute>
                                <VehicleReviewPage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/noncompliance`}
                            element={
                              <AuthenticatedRoute>
                                <NonCompliancePage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/compliance-form`}
                            element={
                              <AuthenticatedRoute>
                                <ComplianceFormPage link="/noncompliance" />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/compliance-permitted`}
                            element={
                              <AuthenticatedRoute>
                                <CompliancePermittedPage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/compliance-prohibited`}
                            element={
                              <AuthenticatedRoute>
                                <ComplianceProhibitedPage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/compliance-recomply`}
                            element={
                              <AuthenticatedRoute>
                                <ComplianceRecomplyPage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={'/reactivation'}
                            element={
                              <AuthenticatedRoute>
                                <Reactivation />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            index={true}
                            path={'/upgrade/plan'}
                            element={
                              <AuthenticatedRoute>
                                <UpgradePlanPage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={'/captcha'}
                            element={
                              <AuthenticatedRoute>
                                <ReportVelocityCaptcha />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/onboarding`}
                            element={
                              <AuthenticatedRoute>
                                <OnboardingPage />
                              </AuthenticatedRoute>
                            }
                          />
                        </Routes>
                        <Recycling isAuthenticated={isAuthenticated} />
                      </AppContainer>
                      <ContactOptionsModal
                        isOpen={isContactOptionsModalOpen}
                        setIsOpen={setIsContactOptionsModalOpen}
                        openConfirmCancelModal={handleConfirmCancelModalState}
                      />
                      <ConfirmCancelModal
                        isOpen={isConfirmCancelModalOpen}
                        onClose={handleConfirmCancelModalState}
                        onCancel={handleConfirmCancelModalOnCancel}
                      />
                      <CancelFinalStepModal
                        isOpen={isCancelFinalStepModalOpen}
                        onClose={handleCancelFinalStepModalState}
                      />
                      {showFooter && (
                        <Footer
                          footerLinks={footerLinks}
                          customDisclaimer={<CustomDisclaimer />}
                          maxWidth={1290}
                          libVersion={
                            <LibVersion>
                              {import.meta.env.VITE_TARGET_ENV ===
                              'production' ? (
                                <code>{version}</code>
                              ) : (
                                <code>
                                  {
                                    packageInfo.dependencies[
                                      '@ltvco/refresh-lib'
                                    ]
                                  }
                                </code>
                              )}
                            </LibVersion>
                          }
                          hasMobileApps
                        />
                      )}
                    </div>
                  </EmailVerification>
                </SearchFormProvider>
              </HeaderProvider>
            </LibContextWrapper>
          </Growthbook>
        </SessionProvider>
      </QueryWrapper>
    </ErrorBoundary>
  );
}

export default App;
