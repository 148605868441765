import {
  Button,
  Stack,
  Text,
  LinearProgress,
  styled,
} from '@ltvco/refresh-lib/theme';
import { Cancel } from '@mui/icons-material';
import { convertToPercentage } from '@ltvco/refresh-lib/utils';
import imgConnectPSIInfograph from 'images/connect-psi-infograph.svg';
import imgConnectTirePressure from 'images/connect-tire-pressure.svg';
import imgConnectOil from 'images/connect-oil.svg';
import imgConnectOilLow from 'images/connect-oil-low.svg';
import imgConnectGas from 'images/connect-gas.svg';
import imgConnectGasLow from 'images/connect-gas-low.svg';
import imgConnectNotPluggedIn from 'images/connect-not-plugged-in.svg';
import imgConnectPluggedIn from 'images/connect-plugged-in.svg';
import imgConnectBatteryEmpty from 'images/connect-battery-empty.svg';
import imgConnectBatteryFull from 'images/connect-battery-full.svg';
import imgConnectBattery from 'images/connect-battery.svg';
import imgConnectLock from 'images/connect-lock.svg';
import { SmartCarDetail } from './SmartcarInterfaces';
import { useSmartcar } from './useSmartcar';

interface SmartcarDetailProps {
  smartcarDetail: SmartCarDetail;
}

const StyledText = styled(Text)(({ theme }) => ({
  cursor: 'pointer',
  color: 'primary.main',

  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const SmartcarDetail: React.FC<SmartcarDetailProps> = ({
  smartcarDetail,
}) => {
  const vehicle = smartcarDetail.vehicle;

  const hasLockAndUnlockAccess = vehicle?.permissions['control_security?'];
  const hasChargeAccess = vehicle?.permissions['control_charge?'];

  const hasCharge = vehicle?.charge;
  const isPluggedIn = hasCharge?.is_plugged_in;
  const pluggedInText = isPluggedIn ? 'Plugged in' : 'Not plugged in';
  const isNotCharging = hasCharge?.state === 'NOT_CHARGING';
  const isCharging = hasCharge?.state === 'CHARGING';
  const isChargingOrFullyCharged =
    hasCharge?.state === 'CHARGING' || hasCharge?.state === 'FULLY_CHARGED';

  const battery: {
    isLowBattery: boolean | null;
    isFullBattery: boolean | null;
    percentageRemaining: number;
  } = {
    isLowBattery: null,
    isFullBattery: null,
    percentageRemaining: 0,
  };
  const hasBattery = vehicle?.battery;

  if (hasBattery) {
    battery.isLowBattery =
      hasBattery?.percent_remaining <= 0.2 && isNotCharging;
    battery.isFullBattery = hasBattery?.percent_remaining >= 1;
    battery.percentageRemaining = hasBattery?.percent_remaining || 0;
  }

  const isFullyCharge =
    hasCharge?.state === 'FULLY_CHARGED' || battery.isFullBattery;
  const batteryIcon = isChargingOrFullyCharged
    ? imgConnectBatteryFull
    : battery.isLowBattery
    ? imgConnectBatteryEmpty
    : imgConnectBattery;
  const batteryTextHeader = battery.isLowBattery ? 'Low battery' : 'Battery';
  const batteryTextDetail = isFullyCharge
    ? 'Fully Charged'
    : isCharging
    ? `Charging - ${convertToPercentage(battery.percentageRemaining, 0)}`
    : convertToPercentage(battery.percentageRemaining, 0);

  const hasGas = vehicle?.fuel;
  const isLowGas = hasGas?.percent_remaining
    ? hasGas.percent_remaining * 100 <= 10
    : false;
  const gasTextHeader = isLowGas ? 'Low gas' : 'Gas';
  const gasTextDetail = hasGas
    ? `${Math.round(hasGas.amount_remaining)} gal (${convertToPercentage(
        hasGas.percent_remaining,
        0
      )})`
    : 'No gas information available';

  const hasOil = vehicle?.engine_oil;
  const isLowOil = hasOil?.life_remaining
    ? hasOil.life_remaining * 100 <= 10
    : false;
  const oilTextHeader = isLowOil ? 'Low oil' : 'Oil';
  const oilTextDetail = hasOil
    ? `${convertToPercentage(hasOil.life_remaining, 0)} remaining`
    : 'No oil information available';

  const hasTirePressure = vehicle.tire_pressure;

  const {
    lockVehicleMutation,
    unlockVehicleMutation,
    startChargeMutation,
    stopChargeMutation,
    disconnectVehicleMutation,
  } = useSmartcar(vehicle.smartcar_id);

  return (
    <Stack sx={{ py: 1.25, mt: 2.5 }}>
      {hasLockAndUnlockAccess && (
        <Stack
          direction="column"
          spacing={1}
          sx={{
            pt: 1.5,
            pb: 1.75,
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <img
              src={imgConnectLock}
              alt="lock-icon"
              width="16px"
              height="16px"
            />
            <Text variant="h6">Lock / unlock doors</Text>
          </Stack>
          <Stack direction="row" spacing={2.5} alignItems="center">
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={() => lockVehicleMutation.mutate()}
              disabled={lockVehicleMutation.isLoading}
            >
              {lockVehicleMutation.isLoading ? 'Loading...' : 'Lock'}
            </Button>
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={() => unlockVehicleMutation.mutate()}
              disabled={lockVehicleMutation.isLoading}
            >
              {unlockVehicleMutation.isLoading ? 'Loading...' : 'Unlock'}
            </Button>
          </Stack>
        </Stack>
      )}
      {(hasBattery || hasCharge) && (
        <Stack
          direction="column"
          spacing={0.5}
          sx={{ pt: 1.5, pb: 1.75, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          {hasBattery && hasCharge && (
            <>
              <Stack direction="row" spacing={1} alignItems="center" sx={{}}>
                <img
                  src={batteryIcon}
                  alt="battery-icon"
                  width="16px"
                  height="16px"
                />
                <Text
                  variant="h6"
                  color={battery.isLowBattery ? 'error' : 'primary'}
                >
                  {batteryTextHeader}
                </Text>
                <Text variant="body2">{batteryTextDetail}</Text>
              </Stack>
              <Stack direction="row" alignItems="center">
                <LinearProgress
                  variant="determinate"
                  value={hasBattery.percent_remaining * 100}
                  sx={{ width: '100%' }}
                  color={
                    isFullyCharge
                      ? 'success'
                      : battery.isLowBattery
                      ? 'error'
                      : 'primary'
                  }
                />
              </Stack>
            </>
          )}
          {hasBattery && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Text variant="body2" color="rgba(0, 0, 0, 0.60)">
                Range
              </Text>
              <Text variant="body2" color="rgba(0, 0, 0, 0.87)">
                {Math.round(hasBattery.range)} miles
              </Text>
            </Stack>
          )}
          {hasCharge && (
            <Stack direction="row" spacing={1} alignItems="center">
              <img
                src={isPluggedIn ? imgConnectPluggedIn : imgConnectNotPluggedIn}
                alt="charger-connector-icon"
                width="16px"
                height="16px"
              />
              <Text variant="h6">{pluggedInText}</Text>
            </Stack>
          )}
          {hasChargeAccess && isPluggedIn && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Button
                variant="contained"
                fullWidth
                onClick={() =>
                  battery.isFullBattery
                    ? stopChargeMutation.mutate()
                    : startChargeMutation.mutate()
                }
                sx={{
                  backgroundColor: `${isFullyCharge ? '#388E3C' : 'success'}`,
                  '&:hover': {
                    backgroundColor: `${isFullyCharge ? '#1B5E20' : 'inherit'}`,
                  },
                }}
              >
                {battery.isFullBattery ? 'Stop charging' : 'Start charging'}
              </Button>
            </Stack>
          )}
        </Stack>
      )}
      {hasGas?.success && (
        <Stack
          sx={{ pt: 1.5, pb: 1.75, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          <Stack direction="row" spacing={1} alignItems="center" mb={1}>
            <img
              src={isLowGas ? imgConnectGasLow : imgConnectGas}
              alt="gas-icon"
              width="16px"
              height="16px"
            />
            <Text variant="h6">{gasTextHeader}</Text>
            <Text variant="body2">{gasTextDetail}</Text>
          </Stack>
          <Stack direction="row" alignItems="center">
            <LinearProgress
              variant="determinate"
              value={hasGas.percent_remaining * 100}
              sx={{ width: '100%' }}
              color={isLowGas ? 'error' : 'primary'}
            />
          </Stack>
        </Stack>
      )}
      {hasOil && (
        <Stack
          sx={{ pt: 1.5, pb: 1.75, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          <Stack direction="row" spacing={1} alignItems="center" mb={1}>
            <img
              src={isLowOil ? imgConnectOilLow : imgConnectOil}
              alt="oil-icon"
              width="16px"
              height="16px"
            />
            <Text variant="h6">{oilTextHeader}</Text>
            <Text variant="body2">{oilTextDetail}</Text>
          </Stack>
          <Stack direction="row" alignItems="center">
            <LinearProgress
              variant="determinate"
              value={hasOil.life_remaining * 100}
              sx={{ width: '100%' }}
              color={isLowOil ? 'error' : 'primary'}
            />
          </Stack>
        </Stack>
      )}
      {hasTirePressure && (
        <Stack
          sx={{ pt: 1.5, pb: 1.75, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          <Stack direction="row" spacing={1} alignItems="center" mb={1}>
            <img
              src={imgConnectTirePressure}
              alt="tire-pressure-icon"
              width="16px"
              height="16px"
            />
            <Text variant="h6">Tire Pressure</Text>
          </Stack>
          <Stack direction="row">
            <Stack direction="column" justifyContent="space-between" mr={1}>
              <Text variant="body2">
                {Math.round(hasTirePressure.front_left)} PSI
              </Text>
              <Text variant="body2">
                {Math.round(hasTirePressure.back_left)} PSI
              </Text>
            </Stack>
            <img
              src={imgConnectPSIInfograph}
              alt="psi-infograph-icon"
              width="76px"
              height="100px"
            />
            <Stack direction="column" justifyContent="space-between" ml={1}>
              <Text variant="body2">
                {Math.round(hasTirePressure.front_right)} PSI
              </Text>
              <Text variant="body2">
                {Math.round(hasTirePressure.back_right)} PSI
              </Text>
            </Stack>
          </Stack>
        </Stack>
      )}
      <Stack direction="row" spacing={1} alignItems="center">
        <Cancel sx={{ width: '12px', height: '12px' }} />
        <StyledText
          variant="body2"
          onClick={() => disconnectVehicleMutation.mutate()}
        >
          Disconnect this vehicle
        </StyledText>
      </Stack>
    </Stack>
  );
};
