import { styled, Box } from '@ltvco/refresh-lib/theme';
import { useSession, TooltipMenuItem } from '@ltvco/refresh-lib/v1';

const StyledBox = styled(Box)({
  fontSize: '0.75rem !important',

  p: {
    fontSize: '0.75rem !important',
    lineHeight: 1.4,
  },
});

export function MobileHeaderUserInformation() {
  const {
    session: { account },
  } = useSession();

  const user = account?.account.user_info;

  if (!user) return null;

  return (
    <StyledBox>
      <Box sx={{ display: 'flex', alignItems: 'center' }} data-cy="user_code">
        <strong>Member ID:</strong>
        <TooltipMenuItem text={user.user_code} />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }} data-cy="user_email">
        <strong>Email:</strong>
        <TooltipMenuItem text={user.email} />
      </Box>
    </StyledBox>
  );
}
