import { useEffect, useState } from 'react';
import { Grid, Box, useTheme } from '@ltvco/refresh-lib/theme';
import {
  AccountInformation,
  GenericCtaCard,
  LimitedPlanProgress,
  // MyAccountHeader,
} from '@ltvco/refresh-lib/v1';
import {
  PaymentsSelectionModal,
  PayPalRedirectionModal,
  RadioPaymentsModal,
  ViewOrderHistory,
} from '@ltvco/refresh-lib/payments';
import {
  AccountInformationOptions,
  GenericCtaCardProps,
  editOptionClicked,
} from './BillingConstants';
import { AccountEditModals } from 'components/myAccount/AccountEditModals';
import { useNavigate } from 'react-router-dom';
import { useLimitedPlanInfo } from 'utils/useLimitedPlanInfo';
import { BPMobileAppsCtaCard } from 'components/cta/BPMobileAppsCta/BPMobileAppsCta';

export function Billing() {
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    document.title = 'My Account - Bumper';
  }, []);

  const {
    isLimitedUser,
    isUnlimitedUser,
    reportLimit,
    reportsRun,
    reportsRunPercentage,
    isLoading,
  } = useLimitedPlanInfo();

  const [openPaymentsSelectionModal, setOpenPaymentsSelectionModal] =
    useState<boolean>(false);

  const [openPaymentsUpdateModal, setOpenPaymentsUpdateModal] =
    useState<boolean>(false);

  //Paypal Redirection modal
  const [openPaypalRedirection, setOpenPaypalRedirection] =
    useState<boolean>(false);
  const [paypalState, setPaypalState] = useState<'success' | 'canceled'>(
    'success'
  );
  const togglePaypalRedirectionModal = () =>
    setOpenPaypalRedirection(!openPaypalRedirection);

  const [editing, setEditing] = useState<string | null>('');

  const { ctaCardProps, length } = GenericCtaCardProps(navigate);

  const togglePaymentsSelectionModal = () =>
    setOpenPaymentsSelectionModal(!openPaymentsSelectionModal);

  const togglePaymentsUpdateModal = () => {
    setOpenPaymentsUpdateModal(!openPaymentsUpdateModal);
    if (!openPaymentsSelectionModal) {
      togglePaymentsSelectionModal();
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('bvppcanc')) {
      setPaypalState('canceled');
      setOpenPaypalRedirection(true);
      return;
    }
    if (urlParams.get('bvpp') && urlParams.get('token')) {
      setPaypalState('success');
      setOpenPaypalRedirection(true);
    }
  }, []);

  const finishEditing = () => {
    setEditing('');
  };

  return (
    <>
      {/* <MyAccountHeader
        isUnlimited={isUnlimitedUser}
        customTextStyling={{
          color: theme.palette.primary.main,
          fontSize: theme.typography.h2.fontSize,
        }}
      /> */}
      {isLimitedUser && !isLoading && (
        <LimitedPlanProgress
          progressValue={reportsRunPercentage}
          reportsLimit={reportLimit}
          reportsRun={reportsRun}
          link="/upgrade/plan"
        />
      )}
      <Grid container spacing={2} justifyContent="center">
        {[...Array(length)].map((_x, index) => (
          <Grid item xs={12} md={6} key={index}>
            <GenericCtaCard {...ctaCardProps[index]}></GenericCtaCard>
          </Grid>
        ))}
      </Grid>

      <Box mt={2.5}>
        <BPMobileAppsCtaCard />
      </Box>

      <AccountEditModals editing={editing} finishEditing={finishEditing} />
      <Box
        mt={2.5}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        <AccountInformation
          editOptions={AccountInformationOptions}
          editOptionClick={(value: string | null) => {
            editOptionClicked(value, setOpenPaymentsSelectionModal, setEditing);
          }}
        />
      </Box>
      <ViewOrderHistory />
      <PaymentsSelectionModal
        open={openPaymentsSelectionModal}
        onClose={togglePaymentsSelectionModal}
        onAddPaymentMethod={() => {
          setOpenPaymentsSelectionModal(false);
          setOpenPaymentsUpdateModal(true);
        }}
      />
      <RadioPaymentsModal
        isOpen={openPaymentsUpdateModal}
        onCloseHandle={togglePaymentsUpdateModal}
      />
      <PayPalRedirectionModal
        paypalState={paypalState}
        isOpen={openPaypalRedirection}
        onCloseHandle={togglePaypalRedirectionModal}
      />
    </>
  );
}
