import { useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Stack,
  Text,
} from '@ltvco/refresh-lib/theme';
import { Close, Info, Warning } from '@mui/icons-material';
import { UpdatedQuestionnaire } from '../../interface';

interface QuestionProps {
  question: UpdatedQuestionnaire;
}

export function Question({ question }: QuestionProps) {
  const nonAlertResponses = [
    'No issues found',
    'Smells like new',
    'No response',
  ].includes(`${question.answer}`);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid item xs={12} md={5} sx={{ borderTop: '1px solid #000', pt: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={handleOpen}
        >
          <Info fontSize="inherit" sx={{ mr: 1 }} />
          <Text
            variant="body2"
            sx={{
              textDecoration: 'underline',
              fontWeight: 'bold',
              color: 'text.secondary',
            }}
          >
            {question.question}
          </Text>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{ borderTop: { md: '1px solid #000' }, py: 1 }}
      >
        <Stack direction="row" alignItems="center" ml={{ md: 1.5 }}>
          {!nonAlertResponses && (
            <Warning fontSize="inherit" color={'error'} sx={{ mr: 1.5 }} />
          )}
          <Text
            variant="body1"
            sx={{
              fontWeight: nonAlertResponses ? 'normal' : 'bold',
              color: nonAlertResponses ? 'primary.main' : 'error.main',
            }}
          >
            {question.answer}
          </Text>
        </Stack>
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ width: '480px' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text variant="h2">{question.question}</Text>
            <IconButton disableRipple onClick={handleClose} sx={{ mb: 1.5 }}>
              <Close fontSize="small" sx={{ color: '#212121' }} />
            </IconButton>
          </Stack>
          <Text>{question.description}</Text>
        </Box>
      </Modal>
    </>
  );
}
