import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import { CookiesStorage } from '@ltvco/refresh-lib';
import { Grid, Text } from '@ltvco/refresh-lib/theme';
import {
  FirstRecordSearch,
  getFirstRecordSearch,
  DateUtil,
  useScreenSize,
} from '@ltvco/refresh-lib/utils';
import {
  closeSnackbar,
  EmailSettingsCard,
  EmailVerificationErrorModal,
  EmailVerificationSuccessModal,
  MonitoredReportsModal,
  NeedAssistanceSidebar,
  useAccount,
  useArticles,
  useSnackbar,
  useVideoArticles,
} from '@ltvco/refresh-lib/v1';
import { styled } from '@ltvco/refresh-lib/theme';
import { constants } from 'appConstants';
import { ArticlesContainer } from '../components/Articles/ArticlesContainer';
import { BumperShowroomAdd } from '../components/Adds/BumperShowroomAdd';
import { RelatedArticles } from 'components/Articles/RelatedArticles';
import { RelatedVideoArticles } from 'components/Articles/RelatedVideoArticles';
import { SidenavContainer } from '../components/sidenav/SidenavContainer';
import { SideBarMigrationCta } from '@ltvco/refresh-lib/v2';

const StyledContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  '&&': {
    '& > *': {
      width: '100%',
      maxWidth: '100%',
      border: `1px solid ${theme.palette.secondary.light}`,
      boxShadow: 'none',
      '& > button': {
        width: '95%',
      },
      '& > div button': {
        paddingRight: 0,
      },
    },
  },
}));

export function DashboardParent({
  contactUsHandler,
  handleLogout,
}: {
  contactUsHandler: () => void;
  handleLogout: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const cookieStorage = new CookiesStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useScreenSize();

  const dateUtil = new DateUtil();

  // State
  const [monitoredReportsOpen, setMonitoredReportsOpen] = useState(false);
  const [showEmailExpiredModal, setShowEmailExpiredModal] = useState(false);
  const [showEmailSuccessModal, setShowEmailSuccessModal] = useState(false);

  // Users were seeing notifications every time the account info was updated
  // this is a fix that will only show the notification once
  const [seenSiteNotifications, setSeenSiteNotifications] = useState<string[]>(
    []
  );

  const { data: account } = useAccount(true);
  const [statusParam, setStatusParam] = useQueryParam('status', StringParam);
  const { data: videoArticles } = useVideoArticles();
  const { data: articles } = useArticles();

  function closeMonitoredReportsModal() {
    setMonitoredReportsOpen(false);
  }

  useEffect(() => {
    if (!account) return;
    const firstRecordSearchData = getFirstRecordSearch();
    cookieStorage.removeValue('first_record_search');

    let navigateTo;

    const reportLimit =
      account?.account?.subscription_info?.monthly_report_limit;
    const oneReportLimit = reportLimit && reportLimit === 1;

    if (firstRecordSearchData && !oneReportLimit) {
      const typesForReportRoute = ['vehicle'];

      if (typesForReportRoute.includes(firstRecordSearchData.searchType)) {
        navigateTo = handleRedirectToReportRoute(firstRecordSearchData);
      }

      if (navigateTo) {
        navigate(navigateTo);

        // Setting this cookie for redirecting properly on the Onboarding Questions
        // cookieStorage.setValue('first_report_navigate', navigateTo);
      }
    }
  }, [account]);

  useEffect(() => {
    if (localStorage.getItem('pausedAccountSnackbarId')) {
      try {
        closeSnackbar(
          JSON.parse(localStorage.getItem('pausedAccountSnackbarId') || '')
        );
      } catch (e) {
        console.log('error', e);
      }
    }
    handleSiteNotifications();
    handleReactivationForPausedAccountsBanner();
  }, [account]);

  useEffect(() => {
    if (!account) return;

    const isEmailVerified = account?.account.user_info?.verified_email;
    const hasShownSuccessModal =
      account?.account.user_settings?.hide_email_verification_success;

    if (
      !isEmailVerified &&
      statusParam === 'success' &&
      !hasShownSuccessModal
    ) {
      setStatusParam(undefined, 'replaceIn');
      setShowEmailSuccessModal(true);
    } else if (!isEmailVerified && statusParam === 'expired') {
      setStatusParam(undefined, 'replaceIn');
      setShowEmailExpiredModal(true);
    } else if (!isEmailVerified) {
      //setShowEmailPromptModal(true);
    }
  }, [account, statusParam, setStatusParam]);

  function handleReactivationForPausedAccountsBanner() {
    if (!account) return;
    const isInPausedStatus = account?.meta?.subscription_state === 'paused';
    if (!isInPausedStatus) return;
    const pausedAccountSnackbarId = enqueueSnackbar(
      <Text>
        <b>
          Your account with member ID {account?.account.user_info.user_code} has
          been cancelled.
        </b>{' '}
        <br /> You have until{' '}
        {dateUtil.parseDateFromString(
          account?.account.subscription_info?.normalize_date,
          'M/d'
        )}{' '}
        to continue running reports. Please reactivate now to maintain access.
        Visit{' '}
        <Link target="_blank" to={constants.links.reactivateLink}>
          here
        </Link>{' '}
        to reactivate.
      </Text>,
      {
        variant: 'info',
        persist: true,
        action: <></>,
      }
    );
    localStorage.setItem(
      'pausedAccountSnackbarId',
      JSON.stringify(pausedAccountSnackbarId)
    );
  }

  function handleRedirectToReportRoute(
    firstRecordSearchData: FirstRecordSearch
  ) {
    let queryParams = '';

    if (firstRecordSearchData.searchType === 'vehicle') {
      if (stringExistsAndItsValueIsNotUndefined(firstRecordSearchData.vin)) {
        queryParams = `vin=${firstRecordSearchData.vin}`;
      } else if (
        stringExistsAndItsValueIsNotUndefined(
          firstRecordSearchData.license_plate
        ) &&
        stringExistsAndItsValueIsNotUndefined(firstRecordSearchData.state)
      ) {
        queryParams = `license_plate=${firstRecordSearchData.license_plate}&state=${firstRecordSearchData.state}`;
      } else if (
        stringExistsAndItsValueIsNotUndefined(firstRecordSearchData.make) &&
        stringExistsAndItsValueIsNotUndefined(firstRecordSearchData.model) &&
        stringExistsAndItsValueIsNotUndefined(firstRecordSearchData.year)
      ) {
        queryParams = `make=${firstRecordSearchData.make}&model=${firstRecordSearchData.model}&year=${firstRecordSearchData.year}`;
      }
    }

    return `/report/${firstRecordSearchData.searchType}?${queryParams}`;
  }

  function stringExistsAndItsValueIsNotUndefined(input: string) {
    return input && input !== 'undefined';
  }

  function handleSiteNotifications() {
    if (!account) return;

    const siteNotification = account?.meta?.notification;

    if (
      !siteNotification ||
      seenSiteNotifications.includes(siteNotification.title)
    ) {
      return;
    }

    enqueueSnackbar(
      <Text>
        <b>{siteNotification.title}</b> <br /> {siteNotification.message}
      </Text>,
      {
        variant: 'info',
        persist: true,
      }
    );

    setSeenSiteNotifications((prevState) => [
      ...prevState,
      siteNotification.title,
    ]);
  }

  const isDashboardHome = location.pathname === '/dashboard/home';
  const isRewards = location.pathname.includes('/dashboard/rewards');
  const isApiPage = location.pathname.includes('/dashboard/api');
  const isRecentReports = location.pathname.includes('/dashboard/reports');

  const showRightRail = !isDashboardHome && !isRewards && !isApiPage;

  const RightRailComponent = (
    <Grid
      item
      container
      xs={12}
      md={3}
      lg={3}
      spacing={1}
      direction="column"
      marginTop={2}
      maxWidth={{ xs: '100%', md: '299px' }}
    >
      {isMobile && isRecentReports && (
        <Grid item width={'100%'} order={1} key="mobile-migration-card">
          <StyledContainer>
            <SideBarMigrationCta />
          </StyledContainer>
        </Grid>
      )}
      {location.pathname.includes('/dashboard/billing') && (
        <Grid
          item
          width={'100%'}
          order={{ xs: 6, md: 1 }}
          className="needs-assistance"
        >
          <NeedAssistanceSidebar
            logout={handleLogout}
            supportImgUrl={constants.images.img24hr}
            contactUsHandler={contactUsHandler}
          />
        </Grid>
      )}
      {location.pathname.includes('/dashboard/monitoring') && (
        <Grid item width={'100%'} order={2}>
          <EmailSettingsCard
            currentInstantAlertsSetting={
              account?.account?.alert_me.settings.instant_enabled || false
            }
            currentDigestAlertsSetting={
              account?.account?.alert_me.settings.digest_enabled || false
            }
            handleMonitoredReports={() => setMonitoredReportsOpen(true)}
          />
          <MonitoredReportsModal
            isOpen={monitoredReportsOpen}
            onClose={() => closeMonitoredReportsModal()}
          />
        </Grid>
      )}
      <Grid item width={'100%'} order={3}>
        <BumperShowroomAdd />
      </Grid>
      {videoArticles?.articles && (
        <Grid item width={'100%'} order={4}>
          <ArticlesContainer>
            <RelatedVideoArticles videoArticles={videoArticles.articles} />
          </ArticlesContainer>
        </Grid>
      )}
      {articles && (
        <Grid item width={'100%'} order={5}>
          <ArticlesContainer>
            <RelatedArticles articles={articles} />
          </ArticlesContainer>
        </Grid>
      )}
    </Grid>
  );

  const RightRail = showRightRail ? RightRailComponent : <></>;

  return (
    <SidenavContainer>
      <EmailVerificationErrorModal
        open={showEmailExpiredModal}
        onClose={() => setShowEmailExpiredModal(false)}
      />
      <EmailVerificationSuccessModal
        open={showEmailSuccessModal}
        onClose={() => setShowEmailSuccessModal(false)}
      />
      <Grid container justifyContent={'space-around'}>
        <Grid
          item
          xs={12}
          md={showRightRail ? 8 : 12}
          lg={showRightRail ? 8 : 12}
          maxWidth={{
            xs: '100%',
            md: showRightRail ? '911px !important' : '100%',
          }}
        >
          <Outlet />
        </Grid>
        {RightRail}
      </Grid>
    </SidenavContainer>
  );
}
