import { styled, Box } from '@ltvco/refresh-lib/theme';

export const StyledApiSolutionsBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),

  [theme.breakpoints.up('lg')]: {
    width: '33%',
  },
}));
