import { ClickAwayListener } from '@ltvco/refresh-lib/theme';
import { ForwardRefDiv } from '@ltvco/refresh-lib/v1';
import { HeaderItemName } from '../../types';
import { useHeaderContext } from 'context/HeaderContext';

interface HeaderMenuDropdownProps {
  name: HeaderItemName;
  id: string;
  handleClose: () => void;
  children: JSX.Element | JSX.Element[];
}

export const HeaderMenuDropdown = ({
  name,
  id,
  handleClose,
  children,
}: HeaderMenuDropdownProps) => {
  const { currentDropdown } = useHeaderContext();

  const handleClickAway = (event: Event) => {
    const allowedElements = [`#${id}`];
    const clickedOnAllowedElement = allowedElements.some((selector) =>
      (event && (event.target as Element))?.closest(selector)
    );

    if (
      !clickedOnAllowedElement ||
      (currentDropdown !== null && currentDropdown !== name)
    ) {
      handleClose();
    }
  };

  if (currentDropdown !== name) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ForwardRefDiv>{children}</ForwardRefDiv>
    </ClickAwayListener>
  );
};
