import { constants } from 'appConstants';
import { Text } from '@ltvco/refresh-lib/theme';
import { StyledApiSolutionsBox } from '../components';

/**
 * Props for the ApiSolutionCard component.
 *
 * @interface
 * @property {Object} solution - The solution object to be displayed.
 * @property {string} solution.title - The title of the solution.
 * @property {string} solution.body - The body text describing the solution.
 * @property {string} solution.icon - The icon name for the solution.
 */
interface ApiSolutionCardProps {
  solution: {
    title: string;
    body: string;
    icon: string;
  };
}

/**
 * Component for displaying an individual API solution card.
 *
 * This component renders a card with an icon, a title, and a body text
 * based on the provided solution object. The icon is retrieved from the
 * constants based on the icon name.
 *
 * @param {ApiSolutionCardProps} props - The props for the component.
 * @returns {React.ReactElement} A React element representing the API solution card.
 */
export function ApiSolutionCard({ solution }: ApiSolutionCardProps) {
  const { imgApiSolutions } = constants.images;

  return (
    <StyledApiSolutionsBox>
      <img
        src={imgApiSolutions[solution.icon as keyof typeof imgApiSolutions]}
        alt={solution.title}
      />
      <Text variant="h5" mt={2}>
        {solution.title}
      </Text>
      <Text variant="body2">{solution.body}</Text>
    </StyledApiSolutionsBox>
  );
}
