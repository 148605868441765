export const apiSolutionsText = [
  {
    icon: 'imgOnlineMarketplaceIcon',
    title: 'Online car marketplaces',
    body: 'Integrate vehicle history reports right in your marketplace or classified website to increase customer’s trust in your listings.',
  },
  {
    icon: 'imgDealersIcon',
    title: 'Dealers',
    body: 'Increase sales by providing buyers confidence with a Bumper Vehicle History Report. Give your sales team the tools they need to close the deal.',
  },
  {
    icon: 'imgServiceCenterIcon',
    title: 'Service centers',
    body: 'Knowing the history of a vehicle you are servicing can help increase sales. From recalls to accidents, salvage and junk records, we got the data to maximize your business.',
  },
  {
    icon: 'imgFleetOwnersIcon',
    title: 'Fleet owners',
    body: 'Stay on top of recalls and warranties for your vehicles to know when it might be time for service or to update your fleet.',
  },
  {
    icon: 'imgAuctioneersIcon',
    title: 'Auctioneers',
    body: "Make the most informed bids on cars by knowing their history. With records that are updated daily, you'll steer clear of any junk, salvaged or stolen vehicles.",
  },
];
