import { Accordion, Box, Button, Stack, Text } from '@ltvco/refresh-lib/theme';
import {
  SectionCard as SectionCardI,
  QuestionCard as QuestionCardI,
} from 'utils/constants/selfInspectionQuestionnaire';

interface EditMenuProps {
  sections: SectionCardI[];
  fetchSectionQuestions: (sectionId: number) => QuestionCardI[];
  handleEditing: (isEditing: boolean) => void;
  updateCurrentScreenData: (questionScreenData: QuestionCardI) => void;
  actionHandlers: {
    complete: () => void;
  };
}

export function EditMenu({
  sections,
  fetchSectionQuestions,
  handleEditing,
  updateCurrentScreenData,
  actionHandlers,
}: EditMenuProps) {
  const openEdit = (question: QuestionCardI) => {
    updateCurrentScreenData(question);
    handleEditing(true);
  };

  return (
    <Stack height="100%" direction="column">
      <Text variant="h5">Edit and review</Text>
      <Box sx={{ flexGrow: 1, maxHeight: '400px', overflow: 'auto' }}>
        {Boolean(sections.length) &&
          sections.map((section) => (
            <Accordion
              key={section.sectionId}
              display={
                <Text variant="h3" m={0}>
                  {section.cardInfo.sectionMainHeader}
                </Text>
              }
            >
              <Stack spacing={1.5}>
                {fetchSectionQuestions(section.sectionId).map((question) => (
                  <Text
                    key={question.screenId}
                    variant="body2"
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => openEdit(question)}
                  >
                    {question.cardInfo.question}
                  </Text>
                ))}
              </Stack>
            </Accordion>
          ))}
      </Box>
      <Stack sx={{ mt: 'auto' }}>
        <Text variant="body1" textAlign="center" mb={1.25}>
          You agree and certify that all the submitted answers to questions are
          true and correct to the best of your knowledge and belief.
        </Text>
        <Button
          color="success"
          variant="contained"
          fullWidth
          onClick={actionHandlers.complete}
        >
          Complete survey
        </Button>
      </Stack>
    </Stack>
  );
}
