import { useContext } from 'react';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@ltvco/refresh-lib/vendors';
import { convertKeysToCamelCase } from '@ltvco/refresh-lib/utils';
import { useServiceToken } from '@ltvco/refresh-lib/v1';
import { aurelia } from 'api/requests/aurelia/aurelia';
import {
  CreateVehicleReviewParams,
  VehicleReview,
  VehicleReviewConverted,
  VehicleReviewParams,
  VehicleReviewResponse,
  VehicleReviewResponseConverted,
} from 'api/requests/aurelia/interfaces';

export const useAurelia = (params: VehicleReviewParams) => {
  const { logError } = useContext(AppConfig);
  const { data: tokenData } = useServiceToken('aurelia');
  const queryClient = useQueryClient();

  const transformData = (data: VehicleReviewResponse) => {
    const response: VehicleReviewResponseConverted = {
      vehicleReviews: data.vehicle_reviews.map((review: VehicleReview) =>
        convertKeysToCamelCase(review)
      ) as VehicleReviewConverted[],
      vehicleAggregatedReviewScores: {
        totalReviews: data.aggregated_review_scores?.total_reviews || 0,
        averageRating: data.aggregated_review_scores?.average_rating || 0,
        averageScores: {
          overallRating:
            data.aggregated_review_scores?.average_scores.overall_rating || 0,
          comfort:
            data.aggregated_review_scores?.average_scores.comfort_rating || 0,
          exterior: data.aggregated_review_scores?.average_scores.exterior || 0,
          interiorDesign:
            data.aggregated_review_scores?.average_scores.interior_design || 0,
          performance:
            data.aggregated_review_scores?.average_scores.performance || 0,
          reliability:
            data.aggregated_review_scores?.average_scores.reliability || 0,
          technology:
            data.aggregated_review_scores?.average_scores.technology || 0,
          value: data.aggregated_review_scores?.average_scores.value || 0,
        },
        vehicleDistribution:
          data.aggregated_review_scores?.vehicle_distribution || {},
      },
    };
    return response;
  };

  const result = useQuery({
    queryKey: ['aurelia'],
    queryFn: () => aurelia.getVehicleReviews(tokenData?.token, params),
    select: transformData,
    enabled:
      Boolean(tokenData?.token) &&
      Boolean(params.year) &&
      Boolean(params.make) &&
      Boolean(params.model),
    refetchOnMount: true,
  });

  const createVehicleReviewMutation = useMutation({
    mutationFn: (params: CreateVehicleReviewParams) => {
      return aurelia.createVehicleReview(tokenData.token, params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['aurelia'],
      });
    },
    onError: (err: Error) => {
      logError('Failed to submit a vehicle review', err as Error);
    },
  });

  return {
    ...result,
    createVehicleReviewMutation,
  };
};
