import { useContext } from 'react';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { request } from '@ltvco/refresh-lib/utils';
import { useQuery } from '@ltvco/refresh-lib/vendors';
import { smartcarConstants } from '../../utils/constants/smartcarConstants';

const baseQueryKey = 'smartcar-connected-vehicle';

export const useSmartcarConnectedVehicles = () => {
  const { logError } = useContext(AppConfig);

  const result = useQuery({
    queryKey: [baseQueryKey],
    queryFn: () => fetchSmartcarConnectedVehicles(),
    onError: (error) => {
      logError('Error - fetching connected vehicles', error as Error);
    },
  });

  return { ...result };
};

const fetchSmartcarConnectedVehicles = async () => {
  return await request(smartcarConstants.connectedVehicleUrl, {
    method: 'GET',
  });
};
