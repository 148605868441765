import { useContext } from 'react';
import { Box, Button } from '@ltvco/refresh-lib/theme';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { MenuItem as MenuItemType } from 'utils/constants/mobileMenu';

interface MenuItemProps {
  item: MenuItemType | { title: string; route: string; internal: boolean };
  handleRedirect: ({
    route,
    internal,
    logout,
  }: {
    route: string;
    internal: boolean;
    logout?: boolean;
  }) => void;
}

export const MenuItem = ({ item, handleRedirect }: MenuItemProps) => {
  const { trackEventGA4 } = useContext(AppConfig);
  const handleRedirectWithProps = () => {
    let route = '';
    let internal = false;
    let logout = false;

    trackEventGA4({
      event_name: 'header_engagement',
      type: `menu - ${item.title.toLowerCase()}`,
    });

    if ('hasLink' in item && item.hasLink && item.link) {
      route = item.link.route;
      internal = item.link.internal;
    } else if ('route' in item && 'internal' in item) {
      route = item.route;
      internal = item.internal;
    }

    if ('logout' in item && item.logout) {
      logout = true;
    }

    handleRedirect({ route, internal, logout });
  };

  return (
    <Box key={item.title} sx={{ py: 1 }}>
      <Button
        disableRipple
        fullWidth
        variant="text"
        startIcon={'icon' in item ? item.icon : null}
        sx={{
          justifyContent: 'flex-start',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        onClick={handleRedirectWithProps}
      >
        {item.title}
      </Button>
    </Box>
  );
};
