import { Text, Stack } from '@ltvco/refresh-lib/theme';
import { Warning } from '@mui/icons-material';
interface ErrorMessageWithIconProps {
  errorMessage: string;
}

export const ErrorMessageWithIcon = ({
  errorMessage,
}: ErrorMessageWithIconProps) => {
  return (
    <Stack direction="row" alignItems="center" my={1.5}>
      <Warning fontSize="inherit" color={'error'} />
      <Text variant="body1" sx={{ color: 'error.main', ml: 0.5 }}>
        {errorMessage}
      </Text>
    </Stack>
  );
};
