import { HistoryRounded } from '@mui/icons-material';
import { useQueryParams } from 'use-query-params';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppConstants } from '@ltvco/refresh-lib/ctx';
import { pluralizeString } from '@ltvco/refresh-lib/utils';
import {
  useRecentReports,
  stringifyParams,
  generateQueryConfig,
  ReportList,
  type ReportTypeOption,
  type ReportType,
  type ReportFilter,
  DeleteAllButton,
  useReportDeleteAll,
  DeleteModal,
  type RecentReportSerialized,
} from '@ltvco/refresh-lib/v2';
import { Stack, Text, Divider, Pagination } from '@ltvco/refresh-lib/theme';
import {
  LoadingReports,
  NullState,
  SearchAndFilterControls,
  RecentReportsCardDescription,
} from './components';
import { hasRunReports } from './utils';
import { useSession } from '@ltvco/refresh-lib/v1';

const queryConfig = generateQueryConfig();

export const RecentReports: React.FC = () => {
  const {
    config: { reportTypeFilterOptions },
  } = useContext(AppConstants);
  const { session } = useSession();
  const [queryParams, setQueryParams] = useQueryParams(queryConfig);
  const { sort_by, sort_direction } = queryParams;
  const filteredParams = stringifyParams(queryParams);
  const { data: queryData, isLoading } = useRecentReports(filteredParams);

  const totalReports =
    queryData?.meta?.report_quantities?.total_reports_count || 0;
  const count = Math.ceil(totalReports / 20);
  const reports =
    totalReports === 0 ? [] : (queryData?.reports as RecentReportSerialized[]);

  const [deleteAllReportsModalOpen, setDeleteAllReportsModalOpen] =
    useState(false);
  const [currentReports, setCurrentReports] = useState(reports);
  const [filterTypes, setFilterTypes] = useState<ReportFilter[]>(
    queryParams.report_type || []
  );

  const updateUrlFilter = useCallback(
    (filterTypes: ReportFilter[]) => {
      setQueryParams({ report_type: filterTypes as ReportType[] });
    },
    [setQueryParams, filterTypes]
  );

  const deleteAllMutation = useReportDeleteAll(setCurrentReports);

  const deleteAll = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    deleteAllMutation.mutate();
    setDeleteAllReportsModalOpen(false);
  };

  const handleGoBack = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDeleteAllReportsModalOpen(false);
  };

  const deleteCurrentReport = (permalink: string) =>
    setCurrentReports(
      currentReports.filter(
        (report: RecentReportSerialized) => report.id !== permalink
      )
    );

  const handlePageChange = (page: number) => {
    setQueryParams({ page });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setCurrentReports(reports);
  }, [reports]);

  useEffect(() => {
    document.title = 'Recent Reports - Bumper';
  }, []);

  // Remove the monitored report option from the filter options
  delete reportTypeFilterOptions.monitored_report;

  if (!isLoading && reports.length === 0 && !hasRunReports({ session })) {
    return (
      <Stack spacing={3.5}>
        <Stack direction="row" spacing={1} alignItems="center">
          <HistoryRounded sx={{ fontSize: '1.25rem' }} />
          <Text variant="h3">Recent Reports</Text>
        </Stack>
        <NullState />
      </Stack>
    );
  }

  return (
    <>
      <Stack spacing={3.5} paddingTop={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <HistoryRounded sx={{ fontSize: '1.25rem' }} />
          <Text variant="h3">Recent Reports</Text>
        </Stack>
        <SearchAndFilterControls
          filterTypes={filterTypes}
          setFilterTypes={(filterTypes) => {
            setFilterTypes(filterTypes);
            updateUrlFilter(filterTypes);
          }}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          reportTypeFilterOptions={
            reportTypeFilterOptions as { [key: string]: ReportTypeOption }
          }
          sort_by={sort_by}
          sort_direction={sort_direction}
        />
        <Stack spacing={1.5} direction="row">
          <Text variant="caption">
            {currentReports.length}{' '}
            {pluralizeString('report', currentReports.length)} viewed
          </Text>
          <Divider
            sx={{ borderColor: 'text.primary' }}
            orientation="vertical"
            flexItem
          />
          <DeleteAllButton
            setDeleteAllReportsModalOpen={setDeleteAllReportsModalOpen}
          />
        </Stack>
        {isLoading ? (
          <LoadingReports />
        ) : (
          <ReportList
            reportList={currentReports}
            deleteCurrentReport={deleteCurrentReport}
            RecentReportsCardDescription={RecentReportsCardDescription}
          />
        )}
        {!isLoading && count > 1 && (
          <Stack
            spacing={2}
            direction={'row'}
            sx={{ justifyContent: 'center' }}
          >
            <Pagination
              page={queryParams.page}
              count={count}
              onChange={(_: any, page: number) => handlePageChange(page)}
              variant="outlined"
              shape="rounded"
              sx={{
                '& .MuiPaginationItem-page, & .MuiPaginationItem-ellipsis': {
                  display: 'none',
                },
              }}
            />
          </Stack>
        )}
        <>
          <DeleteModal
            isOpen={deleteAllReportsModalOpen}
            setIsOpen={handleGoBack}
            deleteMutation={deleteAll}
            title={'Delete all reports confirmation'}
            confirmationText="Are you sure you want to delete ALL of your reports?"
            deleteText="Delete all"
          />
        </>
      </Stack>
    </>
  );
};
