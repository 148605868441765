import { useState } from 'react';
import { Stack, styled } from '@ltvco/refresh-lib/theme';
import {
  SectionCard as SectionCardI,
  QuestionCard as QuestionCardI,
  Card,
} from 'utils/constants/selfInspectionQuestionnaire';
import { EditMenu } from './EditMenu/EditMenu';
import { EditQuestion } from './EditQuestion/EditQuestion';

interface EditProps {
  sections: SectionCardI[];
  fetchSectionQuestions: (sectionId: number) => QuestionCardI[];
  updateCurrentScreenData: (questionScreenData: QuestionCardI) => void;
  updateAnswer: (screenId: string, newValue: string) => void;
  currentAnswer: string;
  cardData: Card | null;
  actionHandlers: {
    complete: () => void;
  };
}

const Container = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
}));

export function Edit({
  sections,
  fetchSectionQuestions,
  updateCurrentScreenData,
  updateAnswer,
  currentAnswer,
  cardData,
  actionHandlers,
}: EditProps) {
  const questionCard = cardData?.cardType === 'question';
  const [isEditing, setIsEditing] = useState(false);

  const handleEditing = (isEditing: boolean) => {
    setIsEditing(isEditing);
  };

  return (
    <Container>
      {isEditing && questionCard ? (
        <EditQuestion
          currentAnswer={currentAnswer}
          cardData={cardData}
          updateAnswer={updateAnswer}
          handleEditing={handleEditing}
        />
      ) : (
        <EditMenu
          sections={sections}
          fetchSectionQuestions={fetchSectionQuestions}
          handleEditing={handleEditing}
          updateCurrentScreenData={updateCurrentScreenData}
          actionHandlers={actionHandlers}
        />
      )}
    </Container>
  );
}
