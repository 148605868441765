import { useContext } from 'react';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useQuery } from '@ltvco/refresh-lib/vendors';
import { vehicleComplaints } from '@ltvco/refresh-lib/v1';

export const useVehicleComplaints = (
  year: string,
  make: string,
  model: string
) => {
  const { logError } = useContext(AppConfig);
  const result = useQuery({
    queryKey: ['vehicleComplaints'],
    queryFn: () => {
      return vehicleComplaints(year, make, model);
    },
    enabled: Boolean(year) && Boolean(make) && Boolean(model),
    onError: (error) => {
      logError('Error fetching vehicle complaints', error as Error);
    },
  });

  return { ...result };
};
