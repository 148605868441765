import { Box, Stack, Text, Button } from '@ltvco/refresh-lib/theme';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';

interface SubmissionFeedbackPanelProps {
  handleRedirect: () => void;
  isValidSubmission: boolean;
}

export const SubmissionFeedbackPanel = ({
  handleRedirect,
  isValidSubmission,
}: SubmissionFeedbackPanelProps) => {
  const headerText = isValidSubmission
    ? 'Review Approved'
    : 'Review Not Approved';
  const subText = isValidSubmission
    ? 'Thanks for submitting your review of this car.'
    : 'Thanks for submitting your review of this car. The title or written review was not approved. Please go back and resubmit.';
  const buttonText = isValidSubmission ? 'Back to Report' : 'Back to Review';
  return (
    <Stack
      justifyContent="center"
      sx={{
        minHeight: '94vh',
        backgroundColor: 'background.default',
      }}
    >
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        p={3}
        width={{ xs: '100%', sm: '658px' }}
        sx={{
          backgroundColor: 'background.paper',
        }}
      >
        {isValidSubmission ? (
          <Box fontSize="2.25rem" sx={{ color: '#388E3C' }}>
            <CheckCircleOutline fontSize="inherit" color="inherit" />
          </Box>
        ) : (
          <Box fontSize="2.25rem" sx={{ color: '#f1401d' }}>
            <HighlightOff fontSize="inherit" color="inherit" />
          </Box>
        )}

        <Text variant="h3" textAlign="center">
          {headerText}
        </Text>
        <Text variant="body1" textAlign="center">
          {subText}
        </Text>
        <Button
          sx={{ alignSelf: 'center' }}
          variant="contained"
          color="success"
          size="large"
          onClick={handleRedirect}
        >
          {buttonText}
        </Button>
      </Stack>
    </Stack>
  );
};
