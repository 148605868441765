import { styled, Card } from '@ltvco/refresh-lib/theme';

export const StyledRadioButtonCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flex: 1,
  height: '100%',
  width: '100%',
}));
