import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@ltvco/refresh-lib/vendors';
import { Box, Grid, styled } from '@ltvco/refresh-lib/theme';
import {
  AppConfig,
  AppConstants,
  ReportContextProvider,
} from '@ltvco/refresh-lib/ctx';
import {
  ReportLoading,
  SearchFormProps,
  SearchReportResponseData,
  reportGetters,
  useReportMonitors,
  useSession,
} from '@ltvco/refresh-lib/v1';
import { SearchFooter } from '@ltvco/refresh-lib/v2';
import { VehicleReport } from 'components/reports';
import { Sidenav } from 'components/sidenav/Sidenav';

const ReportBody = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  maxWidth: '1600px',

  [theme.breakpoints.up('md')]: {
    width: '85%',
    margin: 'auto',
  },

  [theme.breakpoints.up('lg')]: {
    width: '75%',
    margin: 'auto',
  },
}));

const FooterSearchContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2.5),

  // Not only the ReportBody, but also the AppContent (App.tsx) are limited to 1600px max width
  // So with this, we make sure the footer search is always 100% of the screen width
  [theme.breakpoints.up(1600)]: {
    width: '100vw',
  },
}));

interface CommonReportProps {
  onSearchSuccess?: SearchFormProps['onSuccess'];
  onSearchError?: SearchFormProps['onError'];
}

function ReportComponent({
  onSearchSuccess,
  onSearchError,
}: CommonReportProps) {
  const { reportType } = useParams();
  const { logError } = useContext(AppConfig);
  const {
    featureFlags: { propertyReportFlags },
  } = useContext(AppConstants);
  const queryClient = useQueryClient();
  const [queryParams] = useSearchParams();
  const permalinkQuery = queryParams.get('permalink') || '';
  const [permalink, setPermalink] = useState(permalinkQuery);
  const { session, setSession, useAccount } = useSession();
  const { refetch: refetchAccount } = useAccount(session.isAuthenticated);
  const reportGetter = reportGetters[reportType as keyof typeof reportGetters];
  if (typeof reportGetter !== 'function') {
    throw new Error(`Invalid report type: ${reportType}`);
  }
  const { reportIsMonitored, reportMonitorIsLoading } = useReportMonitors();
  const loading = !permalink || reportMonitorIsLoading;

  if (reportType === 'property') {
    // Append report flags before generating the new report
    queryParams.set('report_flags', JSON.stringify(propertyReportFlags));
  }

  const permalinkMutation = useMutation({
    mutationFn: () => reportGetter({ queryParams }),
    onSuccess: async (data: SearchReportResponseData) => {
      const { data: accountData } = await refetchAccount();
      setSession({
        account: accountData,
      });
      setPermalink(data.report.permalink);
    },
    onError: (error: Error, variables, context) => {
      logError('Report Route', error);

      const defaultOptions = queryClient.getDefaultOptions();

      // if mutation/query onError is overridden, we must still call the
      // global handler to make sure backend error codes are handled properly
      if (defaultOptions.mutations && defaultOptions.mutations.onError) {
        defaultOptions.mutations.onError(error, variables, context);
      }
    },
  });

  useEffect(() => {
    if (
      !permalink &&
      !permalinkMutation.isError &&
      !permalinkMutation.isLoading
    ) {
      permalinkMutation.mutate();
    } else if (permalinkQuery && permalink !== permalinkQuery) {
      setPermalink(permalinkQuery);
    }
  }, [permalink, permalinkMutation, permalinkQuery]);

  return (
    <ReportContextProvider permalink={permalink} reportType="vehicle">
      {loading ? (
        <ReportLoading menuItems={10} />
      ) : (
        <Grid sx={{ bgcolor: 'background.default' }} container>
          <Sidenav onReport={true} />
          <ReportBody>
            {reportType === 'vehicle' && (
              <VehicleReport
                permalink={permalink}
                isMonitored={reportIsMonitored}
              />
            )}
          </ReportBody>
        </Grid>
      )}

      <FooterSearchContainer>
        <SearchFooter
          onSearchSuccess={onSearchSuccess}
          onSearchError={onSearchError}
        />
      </FooterSearchContainer>
    </ReportContextProvider>
  );
}

const ReportWrapped: React.FC<CommonReportProps> = ({
  onSearchSuccess,
  onSearchError,
}) => {
  return (
    <ReportComponent
      onSearchSuccess={onSearchSuccess}
      onSearchError={onSearchError}
    />
  );
};

export const Report = ReportWrapped;
