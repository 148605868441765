import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorRequestData, ResponseError } from '@ltvco/refresh-lib/utils';
import {
  QueryClient,
  QueryClientProvider,
  ReactQueryDevtools,
} from '@ltvco/refresh-lib/vendors';
import { useSnackbar } from '@ltvco/refresh-lib/v1';

interface QueryWrapperProps {
  children: JSX.Element;
  handleLogout: (ga4Ttracker?: () => void) => void;
  trackEventGA4: (eventToTrack: object, eventName?: string) => void;
}

interface ResponseData {
  errors: string[];
  pp_version_bad: boolean;
  tos_version_bad: boolean;
}

export function QueryWrapper({
  children,
  handleLogout,
  trackEventGA4,
}: QueryWrapperProps) {
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          onError: (error) => {
            globalErrorHandler(error);
          },
          refetchOnWindowFocus: false,
        },
        mutations: {
          onError: (error) => {
            globalErrorHandler(error);
          },
        },
      },
    })
  );
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const ERROR_HANDLERS = {
    451: handleLegalRequiredError,
    426: handleUpgradeRequiredError,
    401: handleUnathorizedError,
    423: handleSessionBlockedError,
    429: handleRateLimitError,
    400: handleBadRequestError,
    404: handleNotFoundError,
  };

  function handleBadRequestError(responseData: ResponseData) {
    trackEventGA4({
      response: '400',
      message: 'BAD_REQUEST',
      event_name: 'whoops',
    });
    return;
  }

  function handleNotFoundError(responseData: ResponseData) {
    trackEventGA4({
      response: '404',
      message: 'PAGE_NOT_FOUND',
      event_name: 'whoops',
    });
    return;
  }
  function handleLegalRequiredError(responseData: ResponseData) {
    trackEventGA4({
      response: '451',
      message: 'LEGAL_REQUIRED',
      event_name: 'whoops',
    });
    if (responseData.tos_version_bad) {
      navigate('/error');
      return;
    }
    if (responseData.pp_version_bad) {
      // show Privacy modal
      navigate('/error');
      return;
    }

    navigate('noncompliance');
  }
  function handleUnathorizedError(responseData: ResponseData) {
    handleLogout(() =>
      trackEventGA4({
        response: '401',
        message: 'UNAUTHORIZED',
        event_name: 'whoops',
      })
    );
  }

  function handleUpgradeRequiredError(responseData: ResponseData) {
    trackEventGA4({
      response: '426',
      message: 'UPDATE_REQUIRED',
      event_name: 'whoops',
    });
    navigate('/upgrade/plan');
    return;
  }

  function handleSessionBlockedError(responseData: ResponseData) {
    trackEventGA4({
      response: '423',
      message: 'SESSION_BLOCKED',
      event_name: 'whoops',
    });
    handleUnathorizedError(responseData);
    enqueueSnackbar(
      `It looks like you've been logged out because your account is being used by another person or device`,
      {
        variant: 'error',
      }
    );
    navigate('/login');
  }

  function handleRateLimitError(
    responseData: ResponseData,
    requestData: ErrorRequestData
  ) {
    trackEventGA4({
      response: '429',
      message: 'RATE_LIMIT',
      event_name: 'whoops',
    });
    navigate('/captcha', {
      state: {
        requestData,
      },
    });
  }

  async function globalErrorHandler(error: ResponseError | any) {
    const status = error?.response?.status || null;

    if (!status) {
      return;
    }

    if ((status >= 200 && status < 300) || error.passthrough) {
      return;
    }

    const errorHandler = ERROR_HANDLERS[status as keyof typeof ERROR_HANDLERS];

    if (errorHandler) {
      // Some request may access the response body before the error handler and .json() will fail with
      // "Failed to execute 'json' on 'Response': body stream already read" - RS-20230606
      let data: ResponseData = await error.response.json();
      let requestData = error.request;

      errorHandler(data, requestData);
    } else {
      navigate('/error');
    }
  }

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <ReactQueryDevtools initialIsOpen />
        {children}
      </>
    </QueryClientProvider>
  );
}

export default QueryWrapper;
