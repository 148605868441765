import { useState, useEffect, useContext } from 'react';
import { useTheme, Stack } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import {
  BannerContainer,
  BannerContent,
  BannerText,
  GoodhoodCtaButton,
  PrimaryText,
  SecondaryText,
  StyledCloseButton,
} from './components';
import { Close } from '@mui/icons-material';
import { useIpAddress } from '@ltvco/refresh-lib/hooks';
import { useIpToZip } from 'hooks/useIpToZip';
import { DallasZips } from './constants';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

export const GoodhoodPromoBanner = () => {
  const { isMedium } = useScreenSize();
  const { trackEventGA4 } = useContext(AppConfig);

  const [isVisible, setIsVisible] = useState(() => {
    const saved = localStorage.getItem('goodhoodBannerVisisble');
    return saved !== null ? JSON.parse(saved) : true;
  });
  const ip = useIpAddress();
  const { data: cityStateLocationData } = useIpToZip(ip);
  const zipIsADallasZip = cityStateLocationData
    ? DallasZips.includes(cityStateLocationData?.entities?.locations[0]?.zip5)
    : false;

  const isOnLoginPage = location.pathname.includes('/login');

  const shouldRender = isVisible && zipIsADallasZip && !isOnLoginPage;

  useEffect(() => {
    let headerHeight = 0;
    const appContainer = document.getElementById('app-container');
    const mainHeaderContainer = document.getElementById('main-header');
    const outerHeaderContainer = document.getElementById(
      'outer-header-container'
    );

    const ssoPromoBanner = document.getElementById('sso-promo-banner');

    if (ssoPromoBanner) {
      headerHeight += ssoPromoBanner.clientHeight;
    }

    if (mainHeaderContainer) {
      mainHeaderContainer.style.height = '55px';
      headerHeight += mainHeaderContainer.clientHeight;
      if (!isMedium) {
        mainHeaderContainer.style.padding = '10px 0';
      }
    }

    if (shouldRender) {
      headerHeight += 48;
    }

    outerHeaderContainer!.style.height = `${headerHeight}px`;

    if (appContainer) {
      const marginTop = 'auto';

      appContainer.style.marginTop = marginTop;
    }
  }, [shouldRender]);

  useEffect(() => {
    localStorage.setItem('goodhoodBannerVisisble', JSON.stringify(isVisible));
  }, [isVisible]);

  const handleClose = () => {
    setIsVisible(false);
  };

  const navigateToLink = () => {
    window.open(
      'https://www.goodhood.auto/?utm_source=bumper',
      '_blank',
      'noopener,noreferrer'
    );
    trackEventGA4({
      event_name: 'header_engagement',
      type: 'goodhood CTA',
    });
    handleClose();
  };

  if (!shouldRender) return null;

  return (
    <BannerContainer data-testid="goodhood-promo-banner">
      <BannerContent>
        <Stack flexDirection="row" alignItems="center">
          <BannerText>
            <PrimaryText id="first-text">
              Free Goodhood 50-point inspection for Dallas Members!
            </PrimaryText>{' '}
          </BannerText>
          <GoodhoodCtaButton
            onClick={navigateToLink}
            data-testid="setup-button"
          >
            <SecondaryText>Claim Offer</SecondaryText>
          </GoodhoodCtaButton>
        </Stack>
        <StyledCloseButton onClick={handleClose} data-testid="close-button">
          <Close fontSize="small" />
        </StyledCloseButton>
      </BannerContent>
    </BannerContainer>
  );
};
