import { createRequestConfig } from '@ltvco/refresh-lib/ctx';
let appRequests = createRequestConfig();

// Here you can make changes to any of the default app requests coming from refresh lib
// appRequests = {
// ...appRequests,
// somethingIWantToChange: {
//   ...appRequests.somethingIWantToChange,
// fetchSomething: () => {new function here}
// }
// };

export { appRequests };
