import { request } from '@ltvco/refresh-lib/utils';
import { smartcarConstants } from 'utils/constants/smartcarConstants';
import {
  CoreResponse,
  SmartCarDetail,
} from 'components/smartcar/SmartcarInterfaces';

export interface SmartcarRequestsInterface {
  getVehicleInfo: (smartcarId: string) => Promise<SmartCarDetail>;
  lockVehicle: (smartcarId: string) => Promise<CoreResponse>;
  unlockVehicle: (smartcarId: string) => Promise<CoreResponse>;
  startCharge: (smartcarId: string) => Promise<CoreResponse>;
  stopCharge: (smartcarId: string) => Promise<CoreResponse>;
  disconnectVehicle: (smartcarId: string) => Promise<CoreResponse>;
}

const getVehicleInfo = async (smartcarId: string) => {
  return await request(
    `${smartcarConstants.connectedVehicleUrl}/${smartcarId}`,
    { method: 'GET' }
  );
};

const lockVehicle = async (smartcarId: string) => {
  return await request(
    `${smartcarConstants.connectedVehicleUrl}/${smartcarId}/lock`,
    { method: 'POST' }
  );
};

const unlockVehicle = async (smartcarId: string) => {
  return await request(
    `${smartcarConstants.connectedVehicleUrl}/${smartcarId}/unlock`,
    { method: 'POST' }
  );
};

const startCharge = async (smartcarId: string) => {
  return await request(
    `${smartcarConstants.connectedVehicleUrl}/${smartcarId}/start_charge`,
    { method: 'POST' }
  );
};

const stopCharge = async (smartcarId: string) => {
  return await request(
    `${smartcarConstants.connectedVehicleUrl}/${smartcarId}/stop_charge`,
    { method: 'POST' }
  );
};

const disconnectVehicle = async (smartcarId: string) => {
  return await request(
    `${smartcarConstants.connectedVehicleUrl}/${smartcarId}`,
    { method: 'DELETE' }
  );
};

export const SmartcarRequests: SmartcarRequestsInterface = {
  getVehicleInfo,
  lockVehicle,
  unlockVehicle,
  startCharge,
  stopCharge,
  disconnectVehicle,
};
