import React from 'react';
import { Grid, Text } from '@ltvco/refresh-lib/theme';
import { UpdatedQuestionnaire } from '../interface';

interface InspectionHeaderProps {
  headerAnswers: UpdatedQuestionnaire[];
}

export function InspectionHeader({ headerAnswers }: InspectionHeaderProps) {
  const headers = [
    'Inspection type',
    'Completed by',
    'Mileage reported',
    'Vehicle type',
  ];

  return (
    <Grid container>
      {headers.map((header, index) => (
        <React.Fragment key={header}>
          <Grid item xs={12} md={3}>
            <Text variant="h4" fontWeight={400} color={'text.secondary'}>
              {header}
            </Text>
          </Grid>
          <Grid item xs={12} md={9}>
            <Text variant="h4" fontWeight={400}>
              {headerAnswers[index]?.answer}
            </Text>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}
