import { Box, Button, Stack, Text, styled } from '@ltvco/refresh-lib/theme';
import { BPReward, logoClassStyle } from '@ltvco/refresh-lib/v1';

interface SecondaryRewardProps {
  reward: BPReward;
  handleOpenReward: (reward: BPReward) => void;
}

const StackContainer = styled(Stack)(({ theme }) => ({
  width: 'calc(50% - 10px)',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: `0 0 10px ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(0.5),
  padding: `${theme.spacing(2.5)} ${theme.spacing(1.75)}`,
  cursor: 'pointer',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const SecondaryReward = ({
  reward,
  handleOpenReward,
}: SecondaryRewardProps) => {
  const handleOpenRewardWrapper = () => {
    handleOpenReward(reward);
  };

  return (
    <StackContainer
      role="button"
      onClick={handleOpenRewardWrapper}
      data-testid={`${reward.name}-reward`}
    >
      <Box style={logoClassStyle(reward.image, '105px', '150px')} />
      <Text fontSize="1.125rem" mb={1.25} textAlign="center" fontWeight="bold">
        {reward.header}
      </Text>
      <Button variant="contained" color="success" fullWidth>
        {reward.cta}
      </Button>
    </StackContainer>
  );
};
