/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { CostTracker } from '../interfaces';
import { Stack, Input, Text } from '@ltvco/refresh-lib/theme';
import {
  useScreenSize,
  capitalizeString,
  commaFormat,
} from '@ltvco/refresh-lib/utils';
import { StyledCreditRowBox, StyledCreditRowStack } from '../components';

/**
 * Props for the CreditCalculatorRow component.
 *
 * @interface
 * @property {string} title - The title of the API request type.
 * @property {number} value - The number of credits per call for this request type.
 * @property {CostTracker} costTracker - The cost tracker object containing information about user inputs and costs.
 * @property {(title: string, newInput: number) => void} updateCostTracker - Function to update the cost tracker based on user input.
 */
interface CreditCalculatorRowProps {
  title: string;
  value: number;
  costTracker: CostTracker;
  updateCostTracker: (title: string, newInput: number) => void;
}

/**
 * React component for displaying a single row in the credit calculator.
 *
 * This component represents a row in the credit calculator, displaying the
 * title of the request type, the number of credits per call, and an input field
 * for the user to enter the expected number of calls. It also displays the
 * calculated cost based on the input.
 *
 * @param {CreditCalculatorRowProps} props - The props for the component.
 * @returns {React.ReactElement} A React element representing a row in the credit calculator.
 */
export const CreditCalculatorRow = ({
  title,
  value,
  costTracker,
  updateCostTracker,
}: CreditCalculatorRowProps) => {
  const { isMobile } = useScreenSize();

  const updateCost = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const input = inputValue.replace(/^0+/, '') || '0';

    if (!input || isNaN(Number(input)) || Number(input) < 0)
      return updateCostTracker(title, 0);

    updateCostTracker(title, Number(input));
    return null;
  };

  const visibleName = title
    ?.split('_')
    ?.map((segment: string) => capitalizeString(segment))
    ?.join(' ');

  return (
    <StyledCreditRowBox marginY={2} paddingY={1}>
      <Stack
        sx={(theme) => ({
          textAlign: 'left',
          [theme.breakpoints.up('md')]: {
            width: '40%',
          },
        })}
        justifyContent="center"
      >
        <Text variant="h4" mb={0.5}>
          {visibleName}
        </Text>
        <Text variant="body2">{value} credits per/call</Text>
      </Stack>
      <StyledCreditRowStack>
        <Input
          value={costTracker[title]?.input}
          type="number"
          onChange={updateCost}
          sx={(theme) => ({
            width: '80px',
            [theme.breakpoints.up('md')]: { width: '40%' },
          })}
        />
        {isMobile ? (
          <Text variant="body2" mt={1}>
            ({commaFormat(costTracker[title]?.currentInputCost)})
          </Text>
        ) : (
          <Text variant="h4" mt={1}>
            {commaFormat(costTracker[title]?.currentInputCost)}
          </Text>
        )}
      </StyledCreditRowStack>
    </StyledCreditRowBox>
  );
};
