import {
  Box,
  Button,
  LinearProgress,
  Rating,
  Stack,
  Text,
} from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { StarRate, StarBorder } from '@mui/icons-material';
import { AggregatedReviewScoreConverted } from 'api/requests/aurelia/interfaces';

interface AggregatedReviewProps {
  ymmText: string;
  aggregatedData: AggregatedReviewScoreConverted;
  redirectToVehicleReview: () => void;
}

export const AggregatedReview = ({
  ymmText,
  aggregatedData,
  redirectToVehicleReview,
}: AggregatedReviewProps) => {
  const { isMobile } = useScreenSize();
  const numericalRating = [
    {
      title: 'Overall Rating',
      value: aggregatedData.averageScores.overallRating,
    },
    { title: 'Comfort', value: aggregatedData.averageScores.comfort },
    {
      title: 'Interior Design',
      value: aggregatedData.averageScores.interiorDesign,
    },
    { title: 'Performance', value: aggregatedData.averageScores.performance },
    { title: 'Value', value: aggregatedData.averageScores.value },
    { title: 'Exterior Styling', value: aggregatedData.averageScores.exterior },
    { title: 'Reliability', value: aggregatedData.averageScores.reliability },
    { title: 'Technology', value: aggregatedData.averageScores.technology },
  ];

  return (
    <Stack
      p={2}
      mb={4.5}
      sx={{
        border: '2px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
      }}
      spacing={2}
      direction={{ xs: 'column', lg: 'row' }}
    >
      <Stack width={{ xs: '100%', lg: '50%' }}>
        <Text variant="h3" mb={1.5}>
          {ymmText}
        </Text>
        <Stack direction="row" alignItems="center" spacing={1} mb={3}>
          <Rating
            readOnly
            value={aggregatedData.averageRating}
            precision={0.1}
            icon={
              <Box color="primary.dark">
                <StarRate fontSize="inherit" color="inherit" />
              </Box>
            }
            emptyIcon={<StarBorder fontSize="inherit" />}
          />
          <Text variant="h6" color="text.secondary" mb={0}>
            {aggregatedData.averageRating}
          </Text>
        </Stack>
        <Stack direction="column" spacing={1}>
          {Object.entries(aggregatedData.vehicleDistribution).map(
            ([key, value], index) => (
              <Stack
                key={key}
                direction="row"
                alignItems="center"
                spacing={1.2}
              >
                <Text variant="h6" color="text.primary">
                  {index + 1} star
                </Text>
                <Box sx={{ flex: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={value as number}
                  />
                </Box>
                <Text variant="body2" color="text.secondary" width={'40px'}>
                  {`${value}%`}
                </Text>
              </Stack>
            )
          )}
        </Stack>
        {!isMobile && (
          <Button
            sx={{ alignSelf: 'center', mt: 'auto' }}
            variant="contained"
            color="success"
            onClick={redirectToVehicleReview}
          >
            Write a review
          </Button>
        )}
      </Stack>
      <Stack
        width={{ xs: '100%', lg: '50%' }}
        py={1.5}
        px={2}
        spacing={1}
        sx={{
          backgroundColor: 'rgba(247, 247, 247, 1)',
          flex: 1,
        }}
      >
        <Text variant="h6" color="text.primary">
          Additional Ratings
        </Text>
        <Stack direction="column" spacing={1.5}>
          {numericalRating.map((rating) => (
            <Stack
              key={rating.title}
              direction="row"
              alignItems="center"
              spacing={1.5}
            >
              <Text variant="body2" color="text.primary" sx={{ flex: 1 }}>
                {rating.title}
              </Text>
              <Stack direction="row" alignItems="center">
                <Rating
                  readOnly
                  size="small"
                  value={rating.value}
                  precision={0.1}
                  icon={
                    <Box color="primary.dark">
                      <StarRate fontSize="inherit" color="inherit" />
                    </Box>
                  }
                  emptyIcon={<StarBorder fontSize="inherit" />}
                />
                <Text variant="h6" color="text.primary" mb={0} ml={1}>
                  {rating.value.toFixed(1)}
                </Text>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
      {isMobile && (
        <Button
          sx={{ alignSelf: 'center', mt: 'auto' }}
          variant="contained"
          color="success"
          onClick={redirectToVehicleReview}
        >
          Write a review
        </Button>
      )}
    </Stack>
  );
};
