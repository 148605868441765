import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { useHeaderContext } from 'context/HeaderContext';
import { HeaderItemName } from '../types';

interface OpenCloseChevronProps {
  name: HeaderItemName;
}

export const OpenCloseChevron = ({ name }: OpenCloseChevronProps) => {
  const { currentDropdown } = useHeaderContext();

  return currentDropdown === name ? <KeyboardArrowUp /> : <KeyboardArrowDown />;
};
