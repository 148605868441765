import { Control } from 'react-hook-form';
import { Box, Stack, Text } from '@ltvco/refresh-lib/theme';
import { FormValues, RadioQuestion } from '../interfaces';
import { radioList } from '../constants';
import { FormRadio } from './FormRadio/FormRadio';

interface RadioQuestionsProps {
  control: Control<FormValues, any>;
}

export const RadioQuestions = ({ control }: RadioQuestionsProps) => {
  const radioQuestions: RadioQuestion[] = radioList;

  return (
    <Stack spacing={2.5}>
      <Text variant="h4">Additional Questions</Text>
      {radioQuestions.map((question) => (
        <Box key={question.name}>
          <Text variant="h5">{question.label}</Text>
          <FormRadio
            name={question.name}
            options={question.options}
            control={control}
          />
        </Box>
      ))}
    </Stack>
  );
};
