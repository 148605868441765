import { Button, Text, Radio, Box } from '@ltvco/refresh-lib/theme';
import { StyledRadioButtonCard } from './components';

/**
 * Props for RadioButtonCard.
 *
 * @typedef {Object} RadioButtonCardProps
 * @property {string} titleText - The title text of the radio button card.
 * @property {string|JSX.Element} [bodyText] - The body content of the card, which can be a string or JSX element.
 * @property {string} currentlySelected - The title text of the currently selected radio button.
 * @property {Function} handleClick - Callback function to handle click events.
 */
interface RadioButtonCardProps {
  titleText: string;
  bodyText?: string | JSX.Element;
  currentlySelected: string;
  handleClick: (titleText: string) => void;
}

/**
 * RadioButtonCard component.
 *
 * This component displays a card with a radio button, title, and optional body text. It is used to represent a selectable option.
 *
 * @param {RadioButtonCardProps} props - The props for the component.
 * @returns {JSX.Element} The RadioButtonCard component.
 */
export function RadioButtonCard({
  titleText,
  bodyText,
  currentlySelected,
  handleClick,
}: RadioButtonCardProps) {
  const handleButtonClick = () => {
    handleClick(titleText);
  };

  return (
    <StyledRadioButtonCard
      component={Button}
      variant="outlined"
      onClick={handleButtonClick}
    >
      <Radio
        checked={titleText === currentlySelected}
        color="success"
        sx={{ padding: 0 }}
      />
      <Box textAlign="left" ml={0.5}>
        <Text variant="body1" fontWeight="bold" m={0} mt={0.4}>
          {titleText}
        </Text>
        {bodyText && <Text variant="body2">{bodyText}</Text>}
      </Box>
    </StyledRadioButtonCard>
  );
}
