import { useEffect } from 'react';
import { grey } from '@mui/material/colors';
import { subscribe, unsubscribe } from '@ltvco/refresh-lib';
import { styled, Box, ClickAwayListener, Menu } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { useQueryClient } from '@ltvco/refresh-lib/vendors';
import {
  ForwardRefDiv,
  VehicleSearchForm,
  OnSuccessCallback,
  OnErrorCallback,
} from '@ltvco/refresh-lib/v1';
import { useHeaderContext } from 'context/HeaderContext';
import { HeaderItemName } from '../../types';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: '1rem',
  position: 'absolute',
  background: 'white',
  borderRadius: '4px',
  border: `1px solid ${grey[300]}`,
  left: 0,
  width: '100vw',
  top: '55px',

  [theme.breakpoints.up('md')]: {
    left: 'inherit',
    right: '50px',
    top: 'inherit',
    width: '800px',
  },
}));

const StyledMenu = styled(Menu)({
  width: '0',
  height: '0',

  '>.MuiPaper-root': {
    width: '800px',
    maxWidth: 'inherit',
    height: '266px',
    maxHeight: '266px',
    padding: '16px',
  },

  '&.ymm-search-no-padding': {
    '.MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
});

const StyledVehicleSearchForm = styled(Box)({
  '& > .MuiList-root': {
    padding: 0,
  },
});

interface HeaderSearchDropdownProps {
  onSearchError?: OnErrorCallback;
  name: HeaderItemName;
  handleClose: () => void;
  onSearchSuccess: OnSuccessCallback;
  id: string;
  placement?: 'footer' | 'header' | 'dashboard';
  anchorEl?: HTMLElement | null;
  setMenuAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  openSearch?: () => void;
}

export const HeaderSearchDropdown = ({
  onSearchError,
  name,
  handleClose,
  onSearchSuccess,
  id,
  placement,
  anchorEl,
  setMenuAnchorEl,
  openSearch,
}: HeaderSearchDropdownProps) => {
  const { isMobile, isMedium } = useScreenSize();
  const { currentDropdown, handleSetCurrentDropdown } = useHeaderContext();
  const queryContext = useQueryClient();

  useEffect(() => {
    subscribe('openVehicleSearch', () => {
      if (openSearch) {
        openSearch();
      }
    });

    return () => {
      unsubscribe('openVehicleSearch', () => {});
    };
  }, []);

  useEffect(() => {
    if (currentDropdown === name && !isMobile && !isMedium && setMenuAnchorEl) {
      setMenuAnchorEl(document.getElementById('header-nav-item-Search'));
    }
  }, [currentDropdown, isMedium]);

  const handleClickAway = (event: Event) => {
    const allowedElements = [
      `#${id}`,
      '#year-select-listbox',
      '#make-select-listbox',
      '#model-select-listbox',
      '#trim-select-listbox',
      '#states-select-listbox',
      '#custom-modal',
      '#mobile-header-search-button',
      '#footer-vin-search-link',
      '#footer-plate-search-link',
      '#footer-ymm-search-link',
    ];
    const clickedOnAllowedElement = allowedElements.some((selector) =>
      (event && (event.target as Element))?.closest(selector)
    );
    if (
      !clickedOnAllowedElement ||
      (currentDropdown !== null && currentDropdown !== name)
    ) {
      handleClose();
    }
  };

  const onError =
    onSearchError ||
    (((error: unknown, variables: unknown, context: unknown) => {
      handleSetCurrentDropdown(null);
      queryContext
        ?.getDefaultOptions()
        ?.mutations?.onError?.(error, variables, context);
    }) as OnErrorCallback);

  if (currentDropdown !== name) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ForwardRefDiv>
        {isMedium ? (
          <StyledBox>
            <VehicleSearchForm
              // @ts-ignore:next-line
              onSuccess={onSearchSuccess as OnSuccessCallback}
              onError={onError as OnErrorCallback}
              clearFields
              vinInformationOptions
              placement={placement}
              widerDropdowns={true}
            />
          </StyledBox>
        ) : anchorEl ? (
          <StyledMenu
            id={id}
            anchorEl={anchorEl}
            onClose={handleClose}
            open={currentDropdown === name}
            data-cy="header-menu"
            hideBackdrop
            disableAutoFocusItem
            className="ymm-search-no-padding"
          >
            <VehicleSearchForm
              // @ts-ignore:next-line
              onSuccess={onSearchSuccess as OnSuccessCallback}
              onError={onError as OnErrorCallback}
              clearFields
              vinInformationOptions
              placement={placement}
              widerDropdowns={true}
            />
          </StyledMenu>
        ) : (
          <></>
        )}
      </ForwardRefDiv>
    </ClickAwayListener>
  );
};
