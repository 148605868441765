import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Link, Pill, Stack, Text } from '@ltvco/refresh-lib/theme';
import { commaFormat, useScreenSize } from '@ltvco/refresh-lib/utils';
import { HardcodedPlan, useApiDataPoints } from '@ltvco/refresh-lib/v1';
import { BUMPER_HARDCODED_JSON_API_PLANS } from 'utils/constants/hardcodedPlans';
import { CreditCalculatorRow } from './CreditCalculatorRow/CreditCalculatorRow';
import {
  StyledCreditCalculatorCard,
  StyledRowHeader,
  StyledSuggestedPlanCard,
  StyledSuggestedPlanCardStack,
} from './components';
import { CostItem, CostTracker } from './interfaces';

const customPlanTitle = 'Enterprise Custom Plan';

/**
 * React component for the credit calculator.
 *
 * This component provides a calculator for estimating the number of credits
 * needed per month based on API usage. It includes a list of API endpoints
 * and input fields for users to enter their expected monthly calls. It also
 * suggests a plan based on the total estimated credits.
 *
 * @returns {React.ReactElement} A React element representing the credit calculator.
 */
export const CreditCalculator = () => {
  const navigate = useNavigate();
  const hardcodedPlans: HardcodedPlan[] = [...BUMPER_HARDCODED_JSON_API_PLANS];

  const { isMobile } = useScreenSize();
  const { isLoading, data: apiDataPoints } = useApiDataPoints();
  const [costTracker, setCostTracker] = useState<CostTracker | null>(null);
  const [totalCost, setTotalCost] = useState(0);
  const [suggestedPlan, setSuggestedPlan] = useState<HardcodedPlan | null>(
    null
  );

  const customPlanNeeded = suggestedPlan?.title === customPlanTitle;

  // Lifecycle
  useEffect(() => {
    setSuggestedPlan(suggestPlan());
  }, [totalCost]);

  useEffect(() => {
    if (apiDataPoints) {
      const newCostTracker: Record<string, CostItem> = {};
      Object.entries(apiDataPoints).forEach(([key, value]) => {
        newCostTracker[key as string] = {
          title: key,
          input: 0,
          costValue: value as number,
          currentInputCost: 0,
        };
      });
      setCostTracker(newCostTracker);
    }
  }, [apiDataPoints]);

  /**
   * Suggests a plan based on the total cost.
   *
   * Finds the most suitable plan from hardcoded plans based on the total cost
   * calculated from user inputs.
   *
   * @returns {HardcodedPlan | undefined} The suggested plan or undefined if no suitable plan is found.
   */
  const suggestPlan = () => {
    const foundPlan = hardcodedPlans.find((plan) => {
      if (!plan.credits) return false;
      return plan.credits >= totalCost;
    });

    if (!foundPlan) {
      return {
        title: customPlanTitle,
      } as HardcodedPlan;
    }

    return foundPlan;
  };

  const handleClick = () => {
    navigate('/upgrade/plan', {
      state: {
        plan_type: suggestedPlan?.plan_type,
        unique_key: suggestedPlan?.unique_key,
      },
    });
  };

  /**
   * Updates the cost tracker for a given item.
   *
   * Modifies the cost tracker state to reflect changes in user input. Updates
   * the input value and calculates the current input cost for a specific API
   * request type.
   *
   * @param {string} title - The title of the item to update in the cost tracker.
   * @param {number} newInput - The new input value for the item.
   */
  const updateCostTracker = (title: string, newInput: number) => {
    // Check if costTracker exists and has the item (title) to update.
    if (!costTracker || !costTracker[title]) return;

    // Retrieve the current state of the item's cost tracker.
    const currentCostTracker = costTracker[title];

    // Update the item's input value.
    currentCostTracker.input = newInput;

    currentCostTracker.currentInputCost =
      newInput * currentCostTracker.costValue;

    const newCostTracker: CostTracker = {
      ...costTracker,
      [title]: currentCostTracker,
    };

    setCostTracker(newCostTracker);

    if (!newCostTracker) {
      setTotalCost(0);
    }

    // Calculate the total cost by summing up the currentInputCost of all items.
    const total: number = Object.values(newCostTracker).reduce((acc, curr) => {
      return acc + (curr?.currentInputCost || 0);
    }, 0);

    setTotalCost(total);
  };

  const planTitleText = () => {
    if (customPlanNeeded) {
      return <>{suggestedPlan.title}</>;
    }

    if (suggestedPlan?.title) {
      return (
        <>{`${suggestedPlan.title} Plan: ${commaFormat(
          suggestedPlan.credits
        )} Credits Monthly`}</>
      );
    }
  };

  // JSX
  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box>
      <StyledCreditCalculatorCard variant="outlined">
        <Text variant="h2">Credit Calculator</Text>
        <Text
          sx={(theme) => ({ [theme.breakpoints.down('md')]: { mx: 2, my: 4 } })}
        >
          Use the calculator below to identify which raw data API plan is right
          for you. Enter the expected API calls per month to each endpoint and
          see a total of credits needed.
        </Text>
        <StyledRowHeader>
          <Text variant="body2">Request Type</Text>
          <Text variant="body2">Calls Monthly</Text>
          <Text variant="body2">Credits Monthly</Text>
        </StyledRowHeader>
        {apiDataPoints &&
          costTracker &&
          Object.entries(apiDataPoints).map(([key, value]) => {
            return (
              <CreditCalculatorRow
                key={`credit-calculator-row-${key}`}
                title={key}
                value={value as number}
                costTracker={costTracker}
                updateCostTracker={updateCostTracker}
              />
            );
          })}
      </StyledCreditCalculatorCard>
      <Stack
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row-reverse',
          },
        })}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: '#F8F8F8', padding: 2 }}
        >
          <Text variant="h3">{commaFormat(totalCost)}</Text>
          <Text variant="body2">Estimated Credits Needed Per Month</Text>
        </Stack>
        <StyledSuggestedPlanCard>
          <StyledSuggestedPlanCardStack>
            {suggestedPlan &&
              (isMobile ? (
                <Stack alignItems="center" justifyContent="center">
                  <Pill
                    sx={(theme) => ({
                      borderColor: '#8736C1',
                      color: theme.palette.success.contrastText,
                      marginBottom: 2,
                    })}
                    color="success"
                    label="Suggested Plan"
                    variant="outlined"
                  />
                  <Text variant="h4" marginBottom={2}>
                    {planTitleText()}
                  </Text>
                  {customPlanNeeded ? (
                    <Button
                      variant="contained"
                      color="error"
                      component={Link}
                      href="mailto:bussiness@bumper.com"
                    >
                      Contact Us
                    </Button>
                  ) : (
                    <Button
                      onClick={handleClick}
                      variant="contained"
                      color="error"
                    >
                      Select Plan
                    </Button>
                  )}
                </Stack>
              ) : (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <Stack>
                    <Text variant="h5">Suggested Plan</Text>
                    <Text variant="h4" marginBottom={0}>
                      {planTitleText()}
                    </Text>
                  </Stack>
                  {customPlanNeeded ? (
                    <Button
                      variant="contained"
                      color="error"
                      component={Link}
                      href="mailto:bussiness@bumper.com"
                    >
                      Contact Us
                    </Button>
                  ) : (
                    <Button
                      onClick={handleClick}
                      variant="contained"
                      color="error"
                    >
                      Select Plan
                    </Button>
                  )}
                </Box>
              ))}
          </StyledSuggestedPlanCardStack>
        </StyledSuggestedPlanCard>
      </Stack>
    </Box>
  );
};
