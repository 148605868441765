import { Box, Button, Text, Divider, styled } from '@ltvco/refresh-lib/theme';
import { Article } from '@ltvco/refresh-lib/api';
import { UnderlineArticlesButton } from './ArticlesContainer';
import { ArrowForwardIosOutlined } from '@mui/icons-material';
interface RelatedArticlesProps {
  articles: any;
}

export const ArrowForwardIcon = styled(ArrowForwardIosOutlined)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    borderRadius: theme.spacing(2),
    fontSize: '1.15rem',
    padding: theme.spacing(0.3),
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  })
);

export const RelatedArticles = ({ articles }: RelatedArticlesProps) => {
  const openArticle = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <Box>
      <Text variant="h3" color="primary.dark" mb={0}>
        Articles for you
      </Text>
      {articles?.slice(0, 3).map((article: Article) => (
        <Box flexDirection="column" display="flex">
          <Divider sx={{ marginY: 1.5 }} />
          <UnderlineArticlesButton
            sx={{ alignItems: 'center', fontSize: '1.25rem', mb: 1.5 }}
            onClick={() => openArticle(article.sectionURL || '')}
          >
            {article.section}
            <ArrowForwardIcon />
          </UnderlineArticlesButton>
          <img
            src={article.image}
            alt={article.section}
            style={{ marginBottom: '12px' }}
          />
          <Text variant="h5" mb={1.5}>
            {article.title}
          </Text>
          <Text fontSize="0.75rem" fontWeight="300" mb={1.5}>
            {article.article_date}
          </Text>
          <Text fontSize="0.875rem" fontWeight="300" mb={1.5}>
            {article.description}
          </Text>
          <Button
            variant="contained"
            color="success"
            onClick={() => openArticle(article.link)}
          >
            Read article
          </Button>
        </Box>
      ))}
    </Box>
  );
};
