import {
  Box,
  Button,
  Card,
  Grid,
  styled,
  Text,
} from '@ltvco/refresh-lib/theme';
import imgShowroomStacked from '../../images/img-showroom-stacked.svg';
import imgShowroomGraphic from '../../images/img-showroom-graphic.png';
import { chromeExtensionDownloadLink } from '../../utils/constants/chromeExtensionConstants';

export const BumperShowroomAdd = () => {
  const ContainerBox = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${imgShowroomGraphic}), linear-gradient(transparent, transparent)`,
    backgroundPosition: 'right -10px bottom',
    backgroundSize: '250px auto',
    backgroundRepeat: 'no-repeat',
    border: `2px solid ${theme.palette.lineChart.main}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '491px',
    ':hover': {
      cursor: 'pointer',
    },
  }));

  return (
    <Card sx={{ p: 2.5 }}>
      <Grid
        item
        order={3}
        role="button"
        onClick={() => {
          window.open(chromeExtensionDownloadLink, '_blank');
        }}
      >
        <ContainerBox>
          <Box p={2.5} display="flex" flexDirection="column" textAlign="center">
            <Box>
              <img
                src={imgShowroomStacked}
                style={{ marginBottom: '20px' }}
                alt="Showroom icon"
              />
            </Box>
            <Text fontSize="1.75rem" fontWeight="bold" lineHeight={1.5}>
              Simplify your car research
            </Text>
            <Text variant="body1" mt={2.5}>
              Save vehicles across the web and view them in one spot. Helping
              you learn more about a vehicle
            </Text>
          </Box>
          <Button variant="contained" color="success" sx={{ width: '165px' }}>
            Get Showroom
          </Button>
        </ContainerBox>
      </Grid>
    </Card>
  );
};
