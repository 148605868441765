import { useState } from 'react';
import { Card, Text } from '@ltvco/refresh-lib/theme';
import { useSession } from '@ltvco/refresh-lib/v1';
import { useBowtie } from 'hooks/useBowtie';
import { SelfInspectionResult } from './SelfInspectionResult/SelfInspectionResult';

interface SelfInspectionResultProps {
  vin: string;
  vehicleType?: {
    vehicleType: string | null;
    vehicleSize: string | null;
    vehicleDoors: number | null | undefined;
  };
}

export const SelfInspectionResults = ({
  vin,
  vehicleType,
}: SelfInspectionResultProps) => {
  const {
    session: { account },
  } = useSession();
  const bowtie = useBowtie(vin);
  const [openAccordionId, setOpenAccordionId] = useState<number | null>(0);

  const handleOpenAccordion = (index: number) => {
    if (openAccordionId === index) {
      setOpenAccordionId(null);
      return;
    }

    setOpenAccordionId(index);
  };

  if (bowtie.isLoading || bowtie.isError) {
    return null;
  }

  const sortedAnswerSets = bowtie.data.answer_sets.sort(
    (a: { created_at: string }, b: { created_at: string }) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  return (
    <Card
      id="self-inspection"
      variant="outlined"
      sx={{ boxShadow: 1, borderRadius: 0, p: 2.5 }}
    >
      <Text variant="h2" color="primary.main">
        Self-Inspections
      </Text>
      <Text variant="body1" mb={1.5}>
        Please be advised that these vehicle self inspections were conducted by
        other users and not professionals. Potential purchasers should conduct
        their own inspection and/or have the vehicle inspected by a certified
        mechanic.
      </Text>
      {sortedAnswerSets.map((answerSet, index) => (
        <SelfInspectionResult
          key={answerSet.created_at}
          answerSet={{
            answers: answerSet.answers,
            createdDate: answerSet.created_at,
          }}
          expanded={openAccordionId === index}
          handleOpenAccordion={handleOpenAccordion}
          index={index}
          vehicleType={vehicleType}
        />
      ))}
    </Card>
  );
};
