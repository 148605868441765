import { useEffect, useState } from 'react';
import { Box, Divider } from '@ltvco/refresh-lib/theme';
import { Articles, MyGarageContainer } from '@ltvco/refresh-lib/v1';
import { useAurelia } from 'components/Aurelia/useAurelia';
import { Marketplace } from 'components/Marketplace/Marketplace';
import { Rewards } from 'components/Rewards/Rewards';
import { SmartcarContainer } from 'components/smartcar/SmartcarContainer';
import { ConnectCTAModal } from 'components/smartcar/modals/connectCTAModal';

export function MyGarage() {
  const {
    savedVehicles: { data: savedVehiclesData },
    savedSearches: { data: savedSearchesData },
    listedVehicles: { data: listedVehiclesData },
  } = useAurelia();

  useEffect(() => {
    document.title = 'My Vehicle - Bumper';
  }, []);

  const [connectCTAModalOpen, setConnectCTAModalOpen] = useState(false);
  const handleCloseModal = () => {
    setConnectCTAModalOpen(false);
  };

  const handleOpenModal = () => {
    setConnectCTAModalOpen(true);
  };

  return (
    <Box>
      <MyGarageContainer
        title="My Bumper"
        additionalTabs={[
          {
            label: 'Add via Bumper Connect',
            content: <Box />,
            customHandler: handleOpenModal,
          },
        ]}
        Smartcar={(vin: string) => <SmartcarContainer vin={vin} />}
      >
        <Marketplace
          savedVehiclesCount={savedVehiclesData?.total_listings}
          savedSearchesCount={savedSearchesData?.total_user_searches}
          listedVehiclesCount={listedVehiclesData?.total_vehicles}
        />
        <Divider sx={{ marginY: 1.5 }} />
        <Articles />
        <Divider sx={{ marginY: 1.5 }} />
        <Rewards />
      </MyGarageContainer>
      {connectCTAModalOpen && (
        <ConnectCTAModal
          open={connectCTAModalOpen}
          handleCloseModal={handleCloseModal}
        />
      )}
    </Box>
  );
}
