import { Accordion, Box, Button, styled } from '@ltvco/refresh-lib/theme';
import { MenuItem } from '../MenuItem/MenuItem';
import { MenuItem as MenuItemType } from 'utils/constants/mobileMenu';
import { AccordionName } from '../MobileHeaderMenuDropdown/MobileHeaderMenuDropdown';

interface MenuAccordionProps {
  item: MenuItemType;
  expanded: boolean;
  handleRedirect: ({
    route,
    internal,
  }: {
    route: string;
    internal: boolean;
  }) => void;
  handleActiveAccordion: (title: AccordionName) => void;
}

const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    margin: 0;
    border: none;

    box-shadow: none;

    &:before {
      height: 0;
    }

    .MuiAccordionSummary-root,
    .MuiAccordionSummary-content {
      padding: 0;
      margin: 0;
    }
  }
`;

export const MenuAccordion = ({
  item,
  expanded,
  handleActiveAccordion,
  handleRedirect,
}: MenuAccordionProps) => {
  const handleAccordionToggle = () => {
    handleActiveAccordion(item.title as AccordionName);
  };

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={handleAccordionToggle}
      display={
        <Box key={item.title} sx={{ py: 1 }}>
          <Button
            fullWidth
            variant="text"
            startIcon={item.icon}
            sx={{ justifyContent: 'flex-start' }}
          >
            {item.title}
          </Button>
        </Box>
      }
    >
      {item.subMenu!.map((subItem) => (
        <MenuItem
          item={subItem}
          key={subItem.title}
          handleRedirect={handleRedirect}
        />
      ))}
    </StyledAccordion>
  );
};
