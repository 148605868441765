import { Control, Controller } from 'react-hook-form';
import { Rating, FormControl, Box } from '@ltvco/refresh-lib/theme';
import { StarRate, StarBorder } from '@mui/icons-material';
import { FormRatingNames, FormValues } from '../../interfaces';
import { ErrorMessageWithIcon } from 'components/shared/ErrorMessageWithIcon';

export interface FormRatingProps {
  name: FormRatingNames;
  control: Control<FormValues, any>;
}

export function FormRating({ name, control }: FormRatingProps) {
  return (
    <FormControl>
      <Controller
        name={name}
        control={control}
        defaultValue={-1}
        rules={{ required: true }}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <>
            <Box>
              <Rating
                name={name}
                value={Number(value)}
                onChange={onChange}
                icon={
                  <Box color="primary.dark">
                    <StarRate fontSize="inherit" color="inherit" />
                  </Box>
                }
                emptyIcon={<StarBorder fontSize="inherit" />}
              />
            </Box>
            {errors[name]?.message && (
              <ErrorMessageWithIcon
                errorMessage={errors[name]?.message?.toString() ?? ''}
              />
            )}
          </>
        )}
      />
    </FormControl>
  );
}
