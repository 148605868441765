import { useQuery } from '@ltvco/refresh-lib/vendors';
import { useServiceToken, useSession } from '@ltvco/refresh-lib/v1';
import { fetchSavedVehicles } from './AureliaRequests';

const namespace = '/aurelia';

export const useAurelia = () => {
  const { data: tokenData } = useServiceToken('aurelia');
  const {
    session: { account },
  } = useSession();
  const userCode = account?.account.user_info.user_code || '';
  const userListingsURL = `${namespace}/user_listings/`;
  const userSearchesURL = `${namespace}/user_searches/`;
  const userVehiclesURL = `${namespace}/user_vehicles/`;

  const savedVehicles = useQuery({
    queryKey: ['savedVehicles'],
    queryFn: () =>
      fetchSavedVehicles(userCode, tokenData?.token, userListingsURL),
    enabled: Boolean(tokenData?.token),
  });

  const savedSearches = useQuery({
    queryKey: ['savedSearches'],
    queryFn: () =>
      fetchSavedVehicles(userCode, tokenData?.token, userSearchesURL),
    enabled: Boolean(tokenData?.token),
  });

  const listedVehicles = useQuery({
    queryKey: ['listedVehicles'],
    queryFn: () =>
      fetchSavedVehicles(userCode, tokenData?.token, userVehiclesURL),
    enabled: Boolean(tokenData?.token),
  });

  return {
    savedVehicles,
    savedSearches,
    listedVehicles,
  };
};
