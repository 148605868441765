import { SvgIcon, SvgIconProps } from '@ltvco/refresh-lib/theme';

export const BPLogo = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="-2 -2 36 36" {...props}>
      <path
        d="M11.989 11.7761C14.2226 9.65 17.1999 8.4842 20.2828 8.53012C26.7501 8.61574 31.8999 13.9966 31.9239 20.4665C31.9093 21.4905 31.076 22.3129 30.0532 22.3128L28.9083 22.3128C29.5262 22.2112 30.2372 21.9157 30.2737 21.1375C30.3298 19.8103 29.7766 19.1253 29.2368 18.5527C28.6649 17.9453 26.0753 17.0195 24.4264 16.7653C24.2382 16.7364 24.057 16.6729 23.8919 16.578C16.8821 12.5778 10.34 13.0809 3.93423 15.7325C3.69229 15.8306 3.4336 15.8805 3.17259 15.8797C2.89215 15.877 2.62989 16.0184 2.47776 16.2543C1.93807 17.1189 1.64552 21.9005 2.62208 22.3135L1.88222 22.3135C0.848968 22.3136 0.00885647 21.4847 0 20.4566C0.0268889 15.5145 3.9688 11.3886 8.9298 11.3056C9.96936 11.2873 11.0041 11.4466 11.989 11.7761Z"
        fill="black"
      />
      <path
        d="M9.23487 22.3133L10.0587 22.3133C10.0233 20.8161 8.80251 19.6107 7.29748 19.6056C5.78613 19.6005 4.55326 20.8077 4.5176 22.3135L5.32361 22.3134C5.3929 21.287 6.24447 20.4826 7.2792 20.4805C8.31446 20.4813 9.1668 21.2864 9.23487 22.3133Z"
        fill="black"
      />
      <path
        d="M22.0473 22.313L22.8524 22.313C22.9209 21.2854 23.7731 20.4822 24.8063 20.4805C25.8396 20.4818 26.6935 21.2838 26.7635 22.3129L27.5837 22.3128C27.5138 20.832 26.2911 19.6649 24.8062 19.6699C23.3274 19.6749 22.1166 20.8405 22.0473 22.313Z"
        fill="black"
      />
      <path
        d="M12.5339 17.6841C12.6461 17.6841 12.737 17.5931 12.737 17.4808L12.7504 14.7087C12.7512 14.6536 12.7293 14.6006 12.6898 14.5622C12.6504 14.5237 12.5969 14.5032 12.5419 14.5054C10.5629 14.5745 8.60393 14.9259 6.72405 15.5489C6.47753 15.6336 6.31211 15.8659 6.3125 16.1269V17.0741C6.3125 17.411 6.5853 17.6841 6.92181 17.6841H12.5339Z"
        fill="black"
      />
      <path
        d="M13.8179 17.684C17.4043 17.684 20.7849 17.6733 22.5808 17.5555C22.7256 17.5399 22.8443 17.4335 22.8758 17.2911C22.9072 17.1487 22.8445 17.0021 22.7198 16.9267C20.662 15.7307 16.9099 14.5721 13.8179 14.5052C13.6703 14.5052 13.5507 14.625 13.5507 14.7728V17.4164C13.5507 17.5642 13.6703 17.684 13.8179 17.684Z"
        fill="black"
      />
    </SvgIcon>
  );
};
