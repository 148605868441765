import { Box, Text } from '@ltvco/refresh-lib/theme';
import { useSession } from '@ltvco/refresh-lib/v1';
import { FreeUserRewardContainer } from './FreeUserRewardContainer';
import { PremiumUserRewardContainer } from './PremiumUserRewardContainer';

export const Rewards = () => {
  const {
    session: { account },
  } = useSession();
  const isFreeUser = account?.account?.subscription_info?.free_user;

  return (
    <Box>
      <Text variant="h3" mb={2.5} mt={1.5}>
        Rewards
      </Text>
      {isFreeUser ? (
        <FreeUserRewardContainer displayCTABanner={true} />
      ) : (
        <PremiumUserRewardContainer />
      )}
    </Box>
  );
};
