import { useContext } from 'react';
import {
  Box,
  Text,
  Stack,
  styled,
  PureTextButton,
} from '@ltvco/refresh-lib/theme';
import { AppConstants } from '@ltvco/refresh-lib/ctx';
import { AttachMoney, Favorite, Search } from '@mui/icons-material';
import marketplaceIcon from '../../images/marketplace-icon.svg';

export interface MarketplaceProps {
  savedVehiclesCount: number;
  savedSearchesCount: number;
  listedVehiclesCount: number;
}

const BoxContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  boxShadow: `0 0 ${theme.spacing(1.25)} ${theme.palette.primary.main}`,
  borderRadius: `${theme.spacing(0.5)}`,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: `${theme.spacing(1.25)}`,
  width: 'calc(25% - 15px)',
  [theme.breakpoints.down('lg')]: {
    width: 'calc(50% - 10px)',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const UnderlineButton = styled(PureTextButton)(() => ({
  fontSize: '0.875rem',
  padding: '0',
  width: 'fit-content',
  ':hover': {
    textDecoration: 'underline',
  },
}));

export const Marketplace = ({
  savedVehiclesCount = 0,
  savedSearchesCount = 0,
  listedVehiclesCount = 0,
}: MarketplaceProps) => {
  const {
    links: { marketplaceBaseUrl },
  } = useContext(AppConstants);

  const items = [
    {
      name: 'View now',
      icon: <img src={marketplaceIcon} alt="Marketplace Icon" />,
      visibleText: 'Buy a car',
      link: marketplaceBaseUrl,
    },
    {
      name: 'Sell your car',
      icon: <AttachMoney fontSize="large" sx={{ color: '#212121' }} />,
      visibleText: listedVehiclesCount ? listedVehiclesCount : 'Sell your car',
      link: listedVehiclesCount
        ? `${marketplaceBaseUrl}listed-vehicles`
        : `${marketplaceBaseUrl}list-your-car`,
    },
    {
      name: 'Saved vehicles',
      icon: <Favorite fontSize="large" sx={{ color: '#212121' }} />,
      visibleText: savedVehiclesCount,
      link: `${marketplaceBaseUrl}showroom`,
    },
    {
      name: 'Saved searches',
      icon: <Search fontSize="large" sx={{ color: '#212121' }} />,
      visibleText: savedSearchesCount,
      link: `${marketplaceBaseUrl}saved-searches`,
    },
  ];

  const handleOpenItemInNewTab = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <Box data-testid="marketplace-container">
      <Text variant="h3" mb={2.5} mt={1.5}>
        Marketplace
      </Text>
      <Stack
        flexDirection="row"
        flexWrap="wrap"
        rowGap={1.5}
        display="flex"
        justifyContent="space-between"
      >
        {items.map((item) => (
          <BoxContainer data-testid="marketplace-item" key={item.name}>
            {item.icon}
            <Text variant="h3" mb={2.5} mt={1.5} textAlign="center">
              {item.visibleText}
            </Text>
            <UnderlineButton
              color="success"
              onClick={() => handleOpenItemInNewTab(item.link || '')}
            >
              {item.name}
            </UnderlineButton>
          </BoxContainer>
        ))}
      </Stack>
    </Box>
  );
};
