import { Box, Text, Divider, Link } from '@ltvco/refresh-lib/theme';
import { Article } from '@ltvco/refresh-lib/api';
import { ArrowForwardIcon } from './RelatedArticles';

interface RelatedVideoArticlesProps {
  videoArticles: any;
}

export const RelatedVideoArticles = ({
  videoArticles,
}: RelatedVideoArticlesProps) => {
  const videoBlogUrl = 'https://www.bumper.com/video/';
  const articleURLChar = videoArticles[0].video.split('.be/');
  const formattedURL = `https://www.youtube.com/embed/${articleURLChar[1]}`;

  return (
    <Box display="flex" flexDirection="column">
      <Text variant="h3" color="primary.dark">
        Videos for you
      </Text>
      <Divider sx={{ mt: 1, mb: 1.5 }} />
      <iframe
        title="Video article"
        src={formattedURL}
        style={{ marginBottom: '20px', border: 'none' }}
      />
      <Link
        href={videoArticles[0].link}
        target={'_blank'}
        underline="none"
        color={'success.main'}
        fontSize="0.875rem"
        mb={0.5}
        fontWeight="bold"
      >
        {videoArticles[0].title}
      </Link>
      <Divider sx={{ mb: 1 }} />
      <Link
        href={videoBlogUrl}
        target={'_blank'}
        color={'success.main'}
        fontSize="0.875rem"
        mb={0.5}
        fontWeight="bold"
      >
        More videos
        <ArrowForwardIcon
          sx={{
            fontSize: '0.875rem',
          }}
        />
      </Link>
      {videoArticles.slice(1, 5).map((article: Article) => (
        <Link
          href={article.link}
          target={'_blank'}
          color={'success.main'}
          mb={0.5}
          fontWeight="bold"
        >
          {article.title}
        </Link>
      ))}
      <Divider sx={{ mt: 1.5 }} />
    </Box>
  );
};
