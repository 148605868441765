export const getDefaultUserReportPreferences = () => {
  return [
    {
      navSection: 'Overview',
      sections: [
        {
          nameToDisplay: 'Smart Insights',
          nameToRecord: 'smart_insights',
          checked: false,
        },
      ],
    },
    {
      navSection: 'Damage History',
      sections: [
        {
          nameToDisplay: 'Accidents Reported',
          nameToRecord: 'accidents_reported',
          checked: false,
        },
        {
          nameToDisplay: 'Damage Reported',
          nameToRecord: 'damage_reported',
          checked: false,
        },
        {
          nameToDisplay: 'Repair Quotes',
          nameToRecord: 'repair_quotes',
          checked: false,
        },
        {
          nameToDisplay: 'Salvage Records',
          nameToRecord: 'salvage_records',
          checked: false,
        },
      ],
    },
    {
      navSection: 'Title History',
      sections: [
        {
          nameToDisplay: 'Ownership History',
          nameToRecord: 'ownership_history',
          checked: false,
        },
        {
          nameToDisplay: 'Title Records',
          nameToRecord: 'title_records',
          checked: false,
        },
        {
          nameToDisplay: 'Title Brands',
          nameToRecord: 'title_brands',
          checked: false,
        },
        {
          nameToDisplay: 'Lien Records',
          nameToRecord: 'lien_records',
          checked: false,
        },
        {
          nameToDisplay: 'Impound Records',
          nameToRecord: 'impound_records',
          checked: false,
        },
        {
          nameToDisplay: 'Export Records',
          nameToRecord: 'export_records',
          checked: false,
        },
        {
          nameToDisplay: 'Theft Records',
          nameToRecord: 'theft_records',
          checked: false,
        },
      ],
    },
    {
      navSection: 'Market Value',
      sections: [
        {
          nameToDisplay: 'Market Value',
          nameToRecord: 'market_value',
          checked: false,
        },
        {
          nameToDisplay: 'Ownership Costs',
          nameToRecord: 'ownership_costs',
          checked: false,
        },
        {
          nameToDisplay: 'Sale Listings',
          nameToRecord: 'sales',
          checked: false,
        },
        { nameToDisplay: 'Auctions', nameToRecord: 'auctions', checked: false },
      ],
    },
    {
      navSection: 'Maintenance & Repairs',
      sections: [
        {
          nameToDisplay: 'Service and Repairs',
          nameToRecord: 'service_and_repairs',
          checked: false,
        },
        {
          nameToDisplay: 'Inspections',
          nameToRecord: 'inspections',
          checked: false,
        },
        {
          nameToDisplay: 'Self Inspection',
          nameToRecord: 'self_inspection',
          checked: false,
        },
        {
          nameToDisplay: 'Maintenance Schedule',
          nameToRecord: 'maintenance_schedule',
          checked: false,
        },
        { nameToDisplay: 'Recalls', nameToRecord: 'recalls', checked: false },
        {
          nameToDisplay: 'Detailed History',
          nameToRecord: 'detailed_history',
          checked: false,
        },
      ],
    },
    {
      navSection: 'Vehicle Specs',
      sections: [
        {
          nameToDisplay: 'Specs',
          nameToRecord: 'specifications',
          checked: false,
        },
      ],
    },
    {
      navSection: 'Additional Info',
      sections: [
        {
          nameToDisplay: 'Warranties',
          nameToRecord: 'warranties',
          checked: false,
        },
        {
          nameToDisplay: 'Vehicle Safety',
          nameToRecord: 'vehicle_safety',
          checked: false,
        },
      ],
    },
  ];
};
