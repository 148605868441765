import { useEffect } from 'react';
import { ReferralForm } from '@ltvco/refresh-lib/v1';

export const Referrals = () => {
  useEffect(() => {
    document.title = 'Referrals - Bumper';
  }, []);

  return <ReferralForm />;
};
