import {
  Box,
  Stack,
  Text,
  FormControlLabel,
  Checkbox,
} from '@ltvco/refresh-lib/theme';
import { FormInput } from '../FormInput/FormInput';
import { FormValues } from '../interfaces';
import { FieldErrors } from 'react-hook-form';

interface SubmittingAsFormProps {
  firstName: string;
  register: any;
  handleSubmitAnonymous: (event: React.SyntheticEvent) => void;
  setZipCode: (zip: string) => void;
  errors: FieldErrors<FormValues>;
}

export const SubmittingAsForm = ({
  firstName,
  register,
  handleSubmitAnonymous,
  setZipCode,
  errors,
}: SubmittingAsFormProps) => {
  const handleChange = (e: React.FocusEvent<HTMLInputElement>) => {
    setZipCode(e.target.value);
  };

  return (
    <Stack spacing={1} mb={3}>
      <Text variant="h4" mb={0}>
        Submitting as
      </Text>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <Box flex={{ xs: 12, sm: 7 }}>
          <FormInput
            title="Name"
            name={'name'}
            placeholder={firstName}
            disabled={true}
            register={register}
          />
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="Submit as anonymous"
            onChange={handleSubmitAnonymous}
          />
        </Box>
        <Box>
          <FormInput
            title="Zip Code"
            name={'zip'}
            placeholder="Zip Code"
            register={register}
            handleChange={handleChange}
            error={errors?.zip?.message}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
