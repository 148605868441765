export const BUMPER_HARDCODED_PDF_API_PLANS = [
  {
    amazon_id: 'none for this plan',
    amount: 15000,
    app_only: 'Web',
    apple_id: 'none for this plan',
    google_id: 'none for this plan',
    name: 'Starter Users <br /><span class="includes">Includes 1 Month of 100 Reports per month and API Access</span>',
    title: 'Starter',
    subtitle:
      '50 reports billed<br><strong>every month</strong> at $19.99 plus applicable sales tax',
    recurring: true,
    renewal_period: 1,
    renewal_period_type: 'month',
    report_tier: 'person_report',
    unique_key:
      '150_00_1_month_nofree_afill_freshness_limit_100_freepdf_vehicle_api_access_bumper',
    internal_name:
      'Subscription_$150.00_1Months_0FreeTrial_Recurring_Limit_100_nofreeafill_freshness_freePDF_vehicle_api_access_bumper',
    discount: null,
    public_price_description: '$19.99 plus applicable sales tax every month',
    default: false,
    api_access: true,
    monthly_report_limit: 100,
    price_per_report: 150,
    plan_type: 'pdf',
  },
  {
    amazon_id: 'none for this plan',
    amount: 67500,
    app_only: 'Web',
    apple_id: 'none for this plan',
    google_id: 'none for this plan',
    name: 'Moderate Users - Includes 1 Month of 100 Reports',
    title: 'Standard',
    subtitle:
      '100 reports billed<br><strong>every month</strong> at $35.99 plus applicable sales tax',
    recurring: true,
    renewal_period: 1,
    renewal_period_type: 'month',
    report_tier: 'person_report',
    unique_key:
      '675_00_1_month_nofree_afill_freshness_limit_500_freepdf_vehicle_api_access_bumper',
    internal_name:
      'Subscription_$675.00_1Months_0FreeTrial_Recurring_Limit_500_nofreeafill_freshness_freePDF_vehicle_api_access_bumper',
    discount: 'SAVE 10%',
    public_price_description: '$35.99 plus applicable sales tax every month',
    default: true,
    api_access: true,
    monthly_report_limit: 500,
    price_per_report: 135,
    plan_type: 'pdf',
  },
  {
    amazon_id: 'none for this plan',
    amount: 120000,
    app_only: 'Web',
    apple_id: 'none for this plan',
    google_id: 'none for this plan',
    name: 'Moderate Users - Includes 1 Month of 250 Reports',
    title: 'Plus',
    subtitle:
      '250 reports billed<br><strong>every month</strong> at $84.97 plus applicable sales tax',
    recurring: true,
    renewal_period: 1,
    renewal_period_type: 'month',
    report_tier: 'person_report',
    unique_key:
      '1200_00_1_month_nofree_afill_freshness_limit_1000_freepdf_vehicle_api_access_bumper',
    internal_name:
      'Subscription_$1200.00_1Months_0FreeTrial_Recurring_Limit_1000_nofreeafill_freshness_freePDF_vehicle_api_access_bumper',
    discount: 'SAVE 20%',
    public_price_description: '$84.97 plus applicable sales tax every month',
    default: true,
    api_access: true,
    monthly_report_limit: 1000,
    price_per_report: 120,
    plan_type: 'pdf',
  },
];

export const BUMPER_HARDCODED_JSON_API_PLANS = [
  {
    amazon_id: 'none for this plan',
    credits: 25000,
    costPerCredit: 0.02,
    amount: 50000,
    app_only: 'Web',
    apple_id: 'none for this plan',
    google_id: 'none for this plan',
    name: '',
    title: 'Starter',
    subtitle: '',
    recurring: true,
    renewal_period: 1,
    renewal_period_type: 'month',
    report_tier: 'person_report',
    unique_key:
      '500_00_1_month_nofree_afill_freshness_limit_250_credits_25000_freepdf_vehicle_raw_api_access_bumper',
    internal_name:
      'Subscription_$500.00_1Months_0FreeTrial_Recurring_Limit_250_Credits_25000_nofreeafill_freshness_freePDF_vehicle_rawapi_access_bumper',
    discount: null,
    public_price_description: '',
    default: false,
    api_access: true,
    monthly_report_limit: 250,
    price_per_report: 0,
    plan_type: 'json',
  },
  {
    amazon_id: 'none for this plan',
    credits: 100000,
    costPerCredit: 0.009,
    amount: 90000,
    app_only: 'Web',
    apple_id: 'none for this plan',
    google_id: 'none for this plan',
    name: '',
    title: 'Standard',
    subtitle: '',
    recurring: true,
    renewal_period: 1,
    renewal_period_type: 'month',
    report_tier: 'person_report',
    unique_key:
      '900_00_1_month_nofree_afill_freshness_limit_1000_credits_100000_freepdf_vehicle_raw_api_access_bumper',
    internal_name:
      'Subscription_$900.00_1Months_0FreeTrial_Recurring_Limit_1000_Credits_100000_nofreeafill_freshness_freePDF_vehicle_rawapi_access_bumper',
    discount: null,
    public_price_description: '',
    default: false,
    api_access: true,
    monthly_report_limit: 1000,
    price_per_report: 0,
    plan_type: 'json',
  },
  {
    amazon_id: 'none for this plan',
    credits: 200000,
    costPerCredit: 0.00875,
    amount: 175000,
    app_only: 'Web',
    apple_id: 'none for this plan',
    google_id: 'none for this plan',
    name: '',
    title: 'Plus',
    subtitle: '',
    recurring: true,
    renewal_period: 1,
    renewal_period_type: 'month',
    report_tier: 'person_report',
    unique_key:
      '1750_00_1_month_nofree_afill_freshness_limit_2000_credits_200000_freepdf_vehicle_raw_api_access_bumper',
    internal_name:
      'Subscription_$1750.00_1Months_0FreeTrial_Recurring_Limit_2000_Credits_200000_nofreeafill_freshness_freePDF_vehicle_rawapi_access_bumper',
    discount: null,
    public_price_description: '',
    default: false,
    api_access: true,
    monthly_report_limit: 2000,
    price_per_report: 0,
    plan_type: 'json',
  },
];
