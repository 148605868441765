import React, { useEffect, useState } from 'react';
import { publish } from '@ltvco/refresh-lib';
import { constants } from 'appConstants';
import { useHeaderContext } from 'context/HeaderContext';
const {
  links: {
    dontSellInfoLink,
    affiliateLink,
    aboutLink,
    supportLink,
    partnerProgramLink,
  },
} = constants;
const REWARDS_LINK = 'https://www.bumper.com/rf/dashboard/rewards';
const CUSTOM_PLAN_LINK =
  'mailto:business@bumper.com?subject=Custom%20Plan%20Request';
const SELL_YOUR_CAR_LINK = 'https://www.bumper.com/marketplace/sell-your-car';

export const generateFooterLinks = ({
  contactUsHandler,
}: {
  contactUsHandler: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}) => {
  const [searchType, setSearchType] = useState<string | null>(null);
  const { handleSetCurrentDropdown } = useHeaderContext();
  let timeoutId: ReturnType<typeof setTimeout>;

  const openVehicleSearchForm = (type: string, event: React.MouseEvent) => {
    event.preventDefault();
    handleSetCurrentDropdown(null);
    setTimeout(() => {
      publish('openVehicleSearch');
      handleSetCurrentDropdown('search');
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setSearchType(type);
      }, 100);
    }, 50);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (searchType) {
      publish(`open${searchType}Search`);
      // this is to reset the search type after the search form is opened
      // so that the search form can be opened again by clicking on the same link
      // without having to click on another link first
      // the useEffect will only run when the searchType changes
      setSearchType(null);
    }
  }, [searchType]);

  return [
    {
      title: 'MORE SEARCHES',
      links: [
        {
          text: 'VIN Search',
          href: '#',
          onClick: (event: React.MouseEvent) =>
            openVehicleSearchForm('Vin', event),
        },
        {
          text: 'License Plate Search',
          href: '#',
          onClick: (event: React.MouseEvent) =>
            openVehicleSearchForm('Plate', event),
        },
        {
          text: 'Year Make Model Search',
          href: '#',
          onClick: (event: React.MouseEvent) =>
            openVehicleSearchForm('YMM', event),
        },
        {
          text: 'Marketplace Search',
          href: 'https://www.bumper.com/marketplace',
        },
      ],
    },
    {
      title: 'RESOURCES',
      links: [
        {
          text: 'Sell your car',
          href: SELL_YOUR_CAR_LINK,
        },
        {
          text: 'Auto Insurance',
          href: 'https://insurance.bumper.com/',
        },
        {
          text: 'Market Value',
          href: 'https://www.bumper.com/tools/market-value/',
        },
      ],
    },
    {
      title: 'SUPPORT',
      links: [
        {
          text: 'My Account',
          href: 'https://www.bumper.com/rf/dashboard/billing',
        },
        { text: 'Customer Care', href: supportLink },
        { text: 'Contact Us', href: '#', onClick: contactUsHandler },
        {
          text: 'Do Not Sell or Share My Personal Information',
          href: dontSellInfoLink,
        },
        { text: 'Affiliates', href: affiliateLink },
        { text: 'Partner Program', href: partnerProgramLink },
      ],
    },
    {
      title: 'COMPANY',
      links: [
        { text: 'About Bumper', href: aboutLink },
        { text: 'Rewards', href: REWARDS_LINK },
        { text: 'Blog', href: 'https://www.bumper.com/blog/' },
        {
          text: 'Sample Report',
          href: 'https://www.bumper.com/sample-report/',
        },
        { text: 'Request Custom Plan', href: CUSTOM_PLAN_LINK },
      ],
    },
  ];
};
