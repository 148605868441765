import { Button, Stack, Text } from '@ltvco/refresh-lib/theme';
import { Info } from '@mui/icons-material';
import { QuestionCard as QuestionCardI } from 'utils/constants/selfInspectionQuestionnaire';
import { SelectQuestion } from '../../Question/SelectQuestion/SelectQuestion';
import { InputQuestion } from '../../Question/InputQuestion/InputQuestion';

interface EditQuestionProps {
  cardData: QuestionCardI;
  updateAnswer: (screenId: string, newValue: string) => void;
  handleEditing: (isEditing: boolean) => void;
  currentAnswer: string;
}

export function EditQuestion({
  cardData,
  currentAnswer,
  updateAnswer,
  handleEditing,
}: EditQuestionProps) {
  const isSelectQuestion = cardData.questionType === 'select';
  const isInputQuestion = cardData.questionType === 'input';

  const handleAnswerChangeWithId = (newValue: string) => {
    updateAnswer(cardData.screenId, newValue);
  };

  const closeEdit = () => {
    handleEditing(false);
  };

  return (
    <Stack
      height="100%"
      direction="column"
      sx={{ flexGrow: 1, py: 2.5, px: 2 }}
    >
      <Text variant="h3" mb={1.25}>
        {cardData.cardInfo.question}
      </Text>
      {cardData.cardInfo?.description && (
        <Text variant="body2" mb={1.25}>
          <Info fontSize="inherit" /> {cardData.cardInfo.description}
        </Text>
      )}
      {isSelectQuestion && (
        <SelectQuestion
          choices={cardData.cardInfo.choices}
          currentAnswer={currentAnswer}
          updateAnswer={handleAnswerChangeWithId}
        />
      )}
      {isInputQuestion && (
        <InputQuestion
          placeholder={cardData.cardInfo.placeholder}
          currentAnswer={currentAnswer}
          updateAnswer={handleAnswerChangeWithId}
        />
      )}
      <Stack width="100%" mt="auto" spacing={2.5}>
        <Button
          color="success"
          variant="contained"
          fullWidth
          onClick={closeEdit}
        >
          Update answer
        </Button>
      </Stack>
    </Stack>
  );
}
