import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, styled } from '@ltvco/refresh-lib/theme';
import {
  useAccount,
  useReportRedirect,
  useSession,
} from '@ltvco/refresh-lib/v1';
import { CreateAnswerSetParams } from 'api/requests/bowtie/interfaces';
import { useBowtie } from 'hooks/useBowtie';
import {
  Card,
  Questionnaire,
  QuestionCard as QuestionCardI,
  SectionCard as SectionCardI,
} from 'utils/constants/selfInspectionQuestionnaire';
import { Edit } from './Screens/Edit/Edit';
import { LemonSquad } from './Screens/LemonSquad/LemonSquad';
import { Miscellaneous } from './Screens/Miscellaneous/Miscellaneous';
import { Question } from './Screens/Question/Question';
import { Section } from './Screens/Section/Section';

const lemonSquadInspectionPartnerUrl = 'https://lemonsquad.com/bumperdotcom';

const StyledSelfInspectionContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#fff',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '400px',
    maxHeight: '607px',
    marginTop: theme.spacing(4),
  },
}));

type AnswersType = {
  [key: string]: {
    custom_attribute: string;
    response: string;
    screen_id: string;
  };
};

export function SelfInspection() {
  const [questionnaire, setQuestionnaire] = useState<Card[]>([]);
  const [answers, setAnswers] = useState<AnswersType>({});
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const [currentScreenData, setCurrentScreenData] = useState<Card | null>(null);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [inEditMode, setInEditMode] = useState(false);
  const [showLemonSquad, setShowLemonSquad] = useState(false);
  const isLastScreen = currentScreenIndex === questionnaire.length - 1;

  const location = useLocation();
  const navigate = useNavigate();
  const { redirectToReportUrl } = useReportRedirect();
  const {
    session: { isAuthenticated },
  } = useSession();
  const { data: account } = useAccount(isAuthenticated);
  const { createVehicleSelfInspectionMutation } = useBowtie(
    answers['BUMP-001-003']?.response
  );

  const vin = location?.state?.vin;
  const permalink = location?.state?.permalink;
  const miscellaneousCard = currentScreenData?.cardType === 'misc';
  const sectionCard = currentScreenData?.cardType === 'section';
  const questionCard = currentScreenData?.cardType === 'question';

  useEffect(() => {
    if (questionnaire.length === 0) {
      setQuestionnaire(Questionnaire);
    }

    setCurrentScreenData(questionnaire[currentScreenIndex]);
  }, [questionnaire, currentScreenIndex]);

  useEffect(() => {
    const answers = (
      questionnaire.filter(
        (card): card is QuestionCardI => card.cardType === 'question'
      ) as QuestionCardI[]
    ).reduce((accumulator, currentCard) => {
      accumulator[currentCard.screenId] = {
        custom_attribute: currentCard.cardInfo.question,
        response: '',
        screen_id: currentCard.screenId,
      };

      if (vin && currentCard.screenId === 'BUMP-001-003') {
        accumulator[currentCard.screenId].response = vin;
      }

      return accumulator;
    }, {} as { [key: string]: { screen_id: string; custom_attribute: string; response: string } });

    setAnswers(answers);
  }, [questionnaire, vin]);

  useEffect(() => {
    if (!currentScreenData || currentScreenData.cardType !== 'question') return;
    const currentAnswers = answers as { [key: string]: { response: string } };
    setCurrentAnswer(currentAnswers[currentScreenData.screenId].response);
  }, [currentScreenData, answers]);

  const resetState = () => {
    setShowLemonSquad(false);
    setCurrentScreenData(null);
    setCurrentScreenIndex(0);
    setCurrentAnswer('');
  };

  const nextScreen = () => {
    if (currentAnswer === 'Professional inspection') {
      setShowLemonSquad(true);
      return;
    }

    if (currentScreenIndex < questionnaire.length - 1) {
      setCurrentScreenIndex((prevIndex) => prevIndex + 1);
    }
  };

  const backScreen = () => {
    if (currentScreenIndex > 0) {
      setCurrentScreenIndex((prevIndex) => prevIndex - 1);
    }
  };

  const exitScreen = () => {
    resetState();

    if (permalink) {
      redirectToReportUrl('vehicle', permalink);
    } else {
      navigate('/dashboard/home');
    }
  };

  const update = () => {
    setInEditMode(true);
  };

  const complete = () => {
    if (!account?.account?.user_info?.user_code) return;

    const data: CreateAnswerSetParams = {
      plan_id: 'BUMP-001',
      user_code: account?.account?.user_info?.user_code,
      identifier: answers['BUMP-001-003'].response,
      answers: Object.values(answers).map((answer) => ({
        custom_attribute: answer.custom_attribute,
        response: [answer.response],
        screen_id: answer.screen_id,
      })),
    };

    createVehicleSelfInspectionMutation.mutate(data);
    nextScreen();

    if (inEditMode) {
      setInEditMode(false);
    }
  };

  const handleViewInspection = () => {
    if (permalink) {
      redirectToReportUrl('vehicle', `${permalink}#self-inspection`);
    } else {
      navigate('/dashboard/home');
    }
    resetState();
  };

  const backToVehicleInspection = () => {
    setShowLemonSquad(false);
  };

  const lemonSquadRedirect = () => {
    resetState();
    window.open(
      lemonSquadInspectionPartnerUrl,
      '_blank',
      'noopener noreferrer'
    );

    navigate('/dashboard/home');
  };

  const updateCurrentScreenData = (questionScreenData: QuestionCardI) => {
    setCurrentScreenData(questionScreenData);
  };

  const updateAnswer = (screenId: string, newValue: string) => {
    const currentAnswers: AnswersType = { ...answers };
    currentAnswers[screenId].response = newValue;
    setAnswers(currentAnswers);
  };

  const actionHandlers = {
    next: nextScreen,
    back: backScreen,
    exit: exitScreen,
    complete,
    update,
    backToVehicleInspection,
    lemonSquadRedirect,
    viewInspection: handleViewInspection,
  };

  const sections: SectionCardI[] = questionnaire.filter(
    (card): card is SectionCardI => card.cardType === 'section'
  );

  const sectionQuestions = (sectionId: number) => {
    return questionnaire.filter(
      (card): card is QuestionCardI =>
        card.cardType === 'question' && card.sectionId === sectionId
    );
  };

  const sectionProgress = (item: QuestionCardI) => {
    const questionList = sectionQuestions(item.sectionId);
    const index = questionList.indexOf(item);

    return index === -1 ? -1 : ((index + 1) / questionList.length) * 100;
  };

  return (
    <StyledSelfInspectionContainer sx={{ boxShadow: { sm: 3 } }}>
      {showLemonSquad && <LemonSquad actionHandlers={actionHandlers} />}
      {inEditMode && (
        <Edit
          sections={sections}
          fetchSectionQuestions={sectionQuestions}
          updateCurrentScreenData={updateCurrentScreenData}
          cardData={currentScreenData}
          currentAnswer={currentAnswer}
          updateAnswer={updateAnswer}
          actionHandlers={actionHandlers}
        />
      )}
      {!inEditMode && !showLemonSquad && miscellaneousCard && (
        <Miscellaneous
          cardData={currentScreenData}
          actionHandlers={actionHandlers}
          isLastScreen={isLastScreen}
        />
      )}
      {!inEditMode && !showLemonSquad && sectionCard && (
        <Section
          sections={sections}
          cardData={currentScreenData}
          actionHandlers={actionHandlers}
        />
      )}
      {!inEditMode && !showLemonSquad && questionCard && (
        <Question
          sectionProgress={sectionProgress}
          cardData={currentScreenData}
          currentAnswer={currentAnswer}
          updateAnswer={updateAnswer}
          actionHandlers={actionHandlers}
        />
      )}
    </StyledSelfInspectionContainer>
  );
}
