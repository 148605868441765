import { Divider, Text, Stack } from '@ltvco/refresh-lib/theme';

interface DescriptionDatesProps {
  isOnDashboard: boolean;
  updatedDate: string;
  createdDate: string;
}
export const DescriptionDates = ({
  isOnDashboard,
  updatedDate,
  createdDate,
}: DescriptionDatesProps) => {
  if (isOnDashboard) {
    return (
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={1}>
          <Text>Updated {updatedDate}</Text>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: 'text.secondary' }}
          />
        </Stack>
        <Text>Created {createdDate}</Text>
      </Stack>
    );
  }
  return (
    <Stack direction="row" spacing={1}>
      <Text>Updated {updatedDate}</Text>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ borderColor: 'text.secondary' }}
      />
      <Text>Created {createdDate}</Text>
    </Stack>
  );
};
