export function generateYMMErrorMessage(errors: {
  year: boolean;
  make: boolean;
  model: boolean;
}) {
  const requiredFields: string[] = ['year', 'make', 'model'];
  const missingFields: string[] = [];

  requiredFields.forEach((field) => {
    if (errors[field as keyof typeof errors]) {
      missingFields.push(field);
    }
  });

  if (missingFields.length > 0) {
    return {
      hasError: true,
      errorMessage: `The following fields are required: ${missingFields.join(
        ', '
      )}`,
    };
  }

  return {
    hasError: false,
    errorMessage: '',
  };
}
