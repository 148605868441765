import { Button, Input, styled } from '@ltvco/refresh-lib/theme';

export const StyledInput = styled(Input)(({ theme }) => ({
  '.MuiInputBase-root': {
    height: '100px',
  },
  '& .MuiInputBase-input': {
    height: '100vh',
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(1),
  alignSelf: 'flex-start',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  color: theme.palette.text.secondary,
}));
