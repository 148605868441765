import React, { useEffect } from 'react';
import { Accordion, Grid, Text, Stack } from '@ltvco/refresh-lib/theme';
import { CheckCircle, Warning } from '@mui/icons-material';
import { Question } from 'components/SelfInspection/SelfInspectionResults/SelfInspectionResult/InspectionDetail/Question/Question';
import { UpdatedQuestionnaire } from '../interface';

interface InspectionDetailProps {
  section: string;
  questionnaire: UpdatedQuestionnaire[];
  expanded: boolean;
  handleOpenAccordion: (index: number) => void;
  index: number;
}

export function InspectionDetail({
  section,
  questionnaire,
  expanded,
  handleOpenAccordion,
  index,
}: InspectionDetailProps) {
  const [alertText, setAlertText] = React.useState<string>('');
  const [alertCount, setAlertCount] = React.useState<number>(0);

  useEffect(() => {
    const nonAlertResponses = [
      'No issues found',
      'Smells like new',
      'No response',
      '',
    ];

    const foundResponses = questionnaire?.some(
      (question) => question.answer !== 'No response'
    );
    let alertText = '';
    let alertCount = 0;

    if (foundResponses) {
      alertCount = questionnaire.reduce((acc, curr) => {
        return nonAlertResponses.includes(`${curr.answer}`) ? acc : (acc += 1);
      }, 0);

      alertText = 'No issues found';
      const formattedIssue = alertCount === 1 ? 'issue' : 'issues';

      if (alertCount > 0) {
        alertText = `${alertCount} ${formattedIssue} found`;
      }

      setAlertText(alertText);
      setAlertCount(alertCount);
    }
  }, [questionnaire]);
  const handleActiveAccordion = () => {
    handleOpenAccordion(index);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleActiveAccordion}
      key={section}
      display={
        <Stack>
          <Stack direction="row" alignItems="center" mb={0.5}>
            {alertCount === 0 ? (
              <CheckCircle fontSize="inherit" color={'success'} />
            ) : (
              <Warning fontSize="inherit" color={'error'} />
            )}
            <Text
              variant="body1"
              sx={{
                fontWeight: alertCount === 0 ? 'normal' : 'bold',
                color: alertCount === 0 ? 'primary.main' : 'error.main',
                ml: 0.5,
              }}
            >
              {alertText}
            </Text>
          </Stack>
          <Text variant="h4" m={0}>
            {section}
          </Text>
        </Stack>
      }
    >
      <Grid container>
        {questionnaire?.map((question) => (
          <Question key={question.question} question={question} />
        ))}
      </Grid>
    </Accordion>
  );
}
