import { RadioQuestion, RatingQuestion } from './interfaces';

export const ratingList: RatingQuestion[] = [
  { name: 'comfortRating', label: 'Comfort' },
  { name: 'interiorDesign', label: 'Interior Design' },
  { name: 'performance', label: 'Performance' },
  { name: 'value', label: 'Value for the money' },
  { name: 'exterior', label: 'Exterior styling' },
  { name: 'reliability', label: 'Reliability' },
  { name: 'technology', label: 'Technology' },
];

export const radioList: RadioQuestion[] = [
  {
    name: 'recommend',
    label: 'Would you recommend this vehicle?',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  {
    name: 'vehiclePrimaryUsage',
    label: 'Primary use for this car',
    options: [
      { label: 'Commuting', value: 'Commuting' },
      { label: 'Transporting Family', value: 'Family' },
      { label: 'Having fun', value: 'Leisure' },
    ],
  },
  {
    name: 'reviewingAs',
    label: 'Reviewing as',
    options: [
      { label: 'Owner', value: 'Owner' },
      { label: 'Former Owner', value: 'Former Owner' },
    ],
  },
];

export const defaultValues = {
  year: '',
  make: '',
  model: '',
  trim: '',
  overallRating: 0,
  comfortRating: 0,
  interiorDesign: 0,
  performance: 0,
  value: 0,
  exterior: 0,
  reliability: 0,
  technology: 0,
  title: '',
  review: '',
  recommend: '',
  vehiclePrimaryUsage: '',
  reviewingAs: '',
  name: '',
  zip: '',
  city: '',
  state: '',
};
