import { Box, Button, Stack, Text, styled } from '@ltvco/refresh-lib/theme';
import { BPReward, logoClassStyle } from '@ltvco/refresh-lib/v1';
import firstRewardBanner from '../../images/sirius_xm_banner.png';

interface PrimaryRewardProps {
  reward: BPReward;
  handleOpenReward: (reward: BPReward) => void;
}

const PrimaryRewardContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  boxShadow: `0 0 ${theme.spacing(1.25)} ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: 'calc(50% - 10px)',
  backgroundColor: '#0828e7',

  [theme.breakpoints.down('lg')]: {
    width: '100%',
    marginBottom: theme.spacing(1.5),
  },
}));

export const PrimaryReward = ({
  reward,
  handleOpenReward,
}: PrimaryRewardProps) => {
  const handleOpenRewardWrapper = () => {
    handleOpenReward(reward);
  };

  return (
    <PrimaryRewardContainer data-testid={`${reward.name}-reward`}>
      <Box
        style={logoClassStyle(
          firstRewardBanner || '',
          '209px',
          '100%',
          'cover'
        )}
      />
      <Stack p={2.5}>
        <Text
          fontSize="1.125rem"
          mb={2.5}
          color="primary.contrastText"
          fontWeight="bold"
        >
          {reward.disclaimer}
        </Text>
        <Text
          fontSize="2.5rem"
          mb={2.5}
          color="primary.contrastText"
          fontWeight="bold"
          lineHeight={1.2}
        >
          {reward?.header}
        </Text>
        <Button
          variant="contained"
          color="success"
          fullWidth
          onClick={handleOpenRewardWrapper}
        >
          {reward.cta}
        </Button>
      </Stack>
    </PrimaryRewardContainer>
  );
};
