import { ToggleButtonGroup, Stack } from '@ltvco/refresh-lib/theme';
import PlanSelectorButton from './PlanSelectorButton/PlanSelectorButton';

export interface PlanTypeName {
  public: string;
  internal: string;
}

interface PlanTypeSelectorProps {
  planTypes: PlanTypeName[];
  selectedPlanType: string;
  setSelectedPlanType: (planType: string) => void;
}

export default function PlanTypeSelector({
  planTypes,
  selectedPlanType,
  setSelectedPlanType,
}: PlanTypeSelectorProps) {
  return (
    <Stack mb={2} alignItems="center">
      <ToggleButtonGroup>
        {planTypes.map((planType) => (
          <PlanSelectorButton
            key={`plan-type-button-${planType.internal}`}
            planType={planType}
            selectedPlanType={selectedPlanType}
            setSelectedPlanType={setSelectedPlanType}
          />
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}
