import { useContext } from 'react';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@ltvco/refresh-lib/vendors';
import {
  enqueueSnackbar,
  useServiceToken,
  useSession,
} from '@ltvco/refresh-lib/v1';
import { bowtie } from 'api/requests/bowtie/bowtie';
import { CreateAnswerSetParams } from 'api/requests/bowtie/interfaces';

const baseQueryKey = 'bowtieVehicleSelfInspection';

export const useBowtie = (vin: string) => {
  const {
    session: { account },
  } = useSession();
  const { data: tokenData } = useServiceToken('bow_tie');
  const { logError } = useContext(AppConfig);
  const queryClient = useQueryClient();

  const userCode = account?.account?.user_info?.user_code || '';

  const result = useQuery({
    queryKey: [`${baseQueryKey}-${vin}-${userCode}`],
    queryFn: () =>
      bowtie.getVehicleSelfInspections(tokenData?.token, {
        planId: 'BUMP-001',
        vin: vin!,
        userCode: userCode!,
      }),
    enabled: Boolean(tokenData?.token) && Boolean(vin) && Boolean(userCode),
    refetchOnMount: false,
  });

  const createVehicleSelfInspectionMutation = useMutation({
    mutationFn: (params: CreateAnswerSetParams) => {
      return bowtie.createVehicleSelfInspection(tokenData.token, params);
    },
    onSuccess: (response: { success: any }) => {
      if (response.success) {
        queryClient.invalidateQueries([`${baseQueryKey}-${vin}-${userCode}`]);
        enqueueSnackbar('Successful submitted an inspection', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    },
    onError: (error: Error) => {
      enqueueSnackbar('Failed to submit an inspection', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      logError('Failed to submit an inspection', error as Error);
    },
  });

  return {
    ...result,
    createVehicleSelfInspectionMutation,
  };
};
