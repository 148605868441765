import VehicleInspectionIntro from 'images/vehicle_inspection_intro.png';
import VehicleInspectionSection0 from 'images/vehicle_inspection_section_0.png';
import VehicleInspectionSection1 from 'images/vehicle_inspection_section_1.png';
import VehicleInspectionSection2 from 'images/vehicle_inspection_section_2.png';
import VehicleInspectionSection3 from 'images/vehicle_inspection_section_3.png';
import VehicleInspectionSection4 from 'images/vehicle_inspection_section_4.png';
import LemonSquad from 'images/lemon_squad_inspection_logo_mobile.png';

interface CardInfo {
  icon: string | null;
  cardHeader: string;
  cardBody: string;
  cardAdditionalInfo?: string | null;
}

export interface LemonSquadCard {
  cardType: string;
  cardInfo: {
    icon: string;
    cardHeader: string;
    cardBody: string;
  };
  hasBack: boolean;
  primaryAction: {
    text: string;
    action: string;
  };
  secondaryAction: {
    text: string;
    action: string;
  };
}
export interface MiscellaneousCard {
  cardType: 'misc';
  cardInfo: CardInfo;
  hasBack: boolean;
  primaryAction: {
    text: string;
    action: string;
  };
  secondaryAction?: {
    text: string;
    action: string;
  };
}

export interface SectionCard {
  cardType: 'section';
  sectionId: number;
  cardInfo: {
    icon: string;
    sectionSubHeader: string;
    sectionWarningText?: string | null;
    sectionMainHeader: string;
    sectionBody: string;
    sectionAdditionalInfo: string;
  };
  hasBack: boolean;
  primaryAction: {
    text: string;
    action: string;
  };
  secondaryAction: {
    text: string;
    action: string;
  };
}

interface SelectQuestionCard {
  cardType: 'question';
  sectionId: number;
  questionType: 'select';
  screenId: string;
  cardInfo: {
    question: string;
    description?: string;
    choices: string[];
    unskippable?: boolean;
  };
  hasBack: boolean;
  primaryAction: {
    text: string;
    action: string;
  };
  secondaryAction: {
    text: string;
    action: string;
  };
}

interface InputQuestionCard {
  cardType: 'question';
  sectionId: number;
  questionType: 'input';
  screenId: string;
  cardInfo: {
    question: string;
    description?: string;
    placeholder: string;
    unskippable?: boolean;
    isAlphaNumeric?: boolean;
  };
  hasBack: boolean;
  primaryAction: {
    text: string;
    action: string;
  };
  secondaryAction: {
    text: string;
    action: string;
  };
}

export type QuestionCard = SelectQuestionCard | InputQuestionCard;

export type Card = MiscellaneousCard | SectionCard | QuestionCard;

export const lemonSquadCard: LemonSquadCard = {
  cardType: 'lemonSquad',
  cardInfo: {
    icon: LemonSquad,
    cardHeader: 'You will now be redirected to LemonSquad',
    cardBody:
      'We have partnered with LemonSquad, the leader in pre-purchase inspections, to provide you access to qualified professional inspectors',
  },
  hasBack: true,
  primaryAction: {
    text: 'Continue to Lemonsquad',
    action: 'lemonSquadRedirect',
  },
  secondaryAction: { text: 'Exit', action: 'exit' },
};

export const Questionnaire: Card[] = [
  {
    cardType: 'misc',
    cardInfo: {
      icon: VehicleInspectionIntro,
      cardHeader: 'Vehicle Self-Inspection',
      cardBody:
        'Get guided through an easy-to-complete series of questions that will give you insight into the quality of the vehicle.',
      cardAdditionalInfo:
        'Once completed you will receive a final report of the vehicle that can be printed and shared.',
    },
    hasBack: false,
    primaryAction: { text: 'Start self-inspection', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'section',
    sectionId: 1,
    cardInfo: {
      icon: VehicleInspectionSection0,
      sectionSubHeader: '(1/5)',
      sectionWarningText: null,
      sectionMainHeader: 'Vehicle basics',
      sectionBody:
        'Get started by answering a few general questions about your vehicle',
      sectionAdditionalInfo: '',
    },
    hasBack: true,
    primaryAction: { text: 'Start vehicle basics', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 1,
    questionType: 'select',
    screenId: 'BUMP-001-001',
    cardInfo: {
      question: 'Which kind of inspection would you like to perform?',
      choices: ['Self-inspection', 'Professional inspection'],
      unskippable: false,
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 1,
    questionType: 'select',
    screenId: 'BUMP-001-002',
    cardInfo: {
      question: 'Are you the owner or buyer?',
      choices: ['Owner', 'Buyer', 'Other'],
      unskippable: false,
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 1,
    questionType: 'input',
    screenId: 'BUMP-001-003',
    cardInfo: {
      question: 'What is the VIN of the vehicle?',
      placeholder: 'VIN number',
      unskippable: false,
      isAlphaNumeric: true,
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 1,
    questionType: 'input',
    screenId: 'BUMP-001-004',
    cardInfo: {
      question: 'How many miles does the vehicle have? ',
      placeholder: 'Enter current mileage',
      unskippable: false,
      isAlphaNumeric: false,
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'section',
    sectionId: 2,
    cardInfo: {
      icon: VehicleInspectionSection1,
      sectionSubHeader: '(2/5)',
      sectionWarningText: null,
      sectionMainHeader: 'Exterior inspection',
      sectionBody:
        'Answer a few questions about the general state of the exterior of your vehicle',
      sectionAdditionalInfo: '',
    },
    hasBack: true,
    primaryAction: { text: 'Start exterior section', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 2,
    questionType: 'select',
    screenId: 'BUMP-001-005',
    cardInfo: {
      question: 'Inspect the underbody',
      description:
        'The underbody of a vehicle is often hit by debris and salt on the roads. Start by checking for any excessive rust. This can be a sign that the vehicle has been close to salt water air or cold weather areas. Then, check for anything hanging or dangling from under the car.',
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 2,
    questionType: 'select',
    screenId: 'BUMP-001-006',
    cardInfo: {
      question: 'Inspect for body panel gaps',
      description:
        "The car's individual panels can give you a clue into any possible damage that wasn't reported to insurance. All around the car, body gaps should be consistent. If there's a wider gap on the left side of the hood than the right side, for example, that could indicate there has been a front-end collision that has been repaired at some point.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 2,
    questionType: 'select',
    screenId: 'BUMP-001-007',
    cardInfo: {
      question: 'Inspect paint color',
      description:
        'Paint wears as it ages, and swirl marks and scratches are going to happen. However, the paint should all appear consistent on every part of the exterior. A car that has panels that look like they have different amounts of wear or the color is a little off could be hiding body damage that has been repaired—to who knows what standard.',
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 2,
    questionType: 'select',
    screenId: 'BUMP-001-008',
    cardInfo: {
      question: 'Inspect the lights',
      description:
        "All the exterior bulbs should be functioning. Also, the plastic housings will fade and yellow with time and sun exposure. If the headlamps or tail lamps are mismatched in color or yellowing, one of them was likely replaced. And if the headlamps are yellowed and cloudy, that's a sure sign the car has spent much of its life outside in the sun, affecting the integrity of the paint, trim, tires and interior.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 2,
    questionType: 'select',
    screenId: 'BUMP-001-009',
    cardInfo: {
      question: 'Inspect wheels and tires',
      description:
        "Tires are the only part of a car that touches the road, making them your No.1 safety item. Pay close attention to wheels and tires. Examine the rims and hubcaps for curb rash, which indicates possible suspension and steering damage. Inspect the tread depth to confirm it's sufficient for safe driving. Inspect the tire pressures all around. Uneven pressures often indicate a leak or lack of quality maintenance. All four tires should be the same brand and model. Mismatched tires can handle differently, compromising safety.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'section',
    sectionId: 3,
    cardInfo: {
      icon: VehicleInspectionSection2,
      sectionSubHeader: '(3/5)',
      sectionWarningText: null,
      sectionMainHeader: 'Interior inspection',
      sectionBody:
        'Answer a few questions about the general state of the interior of the vehicle',
      sectionAdditionalInfo: '',
    },
    hasBack: true,
    primaryAction: { text: 'Start interior section', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 3,
    questionType: 'select',
    screenId: 'BUMP-001-010',
    cardInfo: {
      question: 'Inspect the upholstery',
      description:
        "Everything should feel like it's in good condition, with appropriate amounts of wear. Look for sun-bleached or cracked plastic trim that signals a car has been primarily parked outside.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 3,
    questionType: 'select',
    screenId: 'BUMP-001-011',
    cardInfo: {
      question: 'Inspect for odors',
      description:
        "Pay particularly close attention to musty, moldy or sour smells. Mildew or mold in a car could mean it has been water-damaged, so search for rust on the seat frames, under the floor mats and in the spare wheel well. If there's rust or water collecting in these locations, it could be a sign of larger issues.",
      choices: ['Smells like new', 'Some odors', 'Numerous or severe odors'],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 3,
    questionType: 'select',
    screenId: 'BUMP-001-012',
    cardInfo: {
      question: 'Inspect the instruments',
      description:
        "Turn the ignition on while you watch the instrument cluster. All the warning lights should come on for a few seconds and then go off. That is known as a bulb check. The gauges should sweep from one end to the other and back, and then nothing should stay on after it's done. If the instruments aren't working, it might not affect anything, but an instrument cluster repair is an expensive upgrade to have to make.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 3,
    questionType: 'select',
    screenId: 'BUMP-001-013',
    cardInfo: {
      question: 'Inspect the windows',
      description:
        "Roll the windows down one by one. They should go down smoothly from the master switch on the driver's door as well as the individual switch on the passenger doors. If there's a stutter during the movement or it doesn't work consistently, a window motor, regulator or switch could be faulty.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 3,
    questionType: 'select',
    screenId: 'BUMP-001-014',
    cardInfo: {
      question: 'Inspect Drive controls',
      description:
        'Most late-model cars come with convenience functions like Bluetooth, cruise control, steering wheel audio controls and more. Try each button like a kid playing around to see if everything works. Something as simple as a nonfunctioning button can mean an expensive repair or even a safety concern in a collision.',
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'section',
    sectionId: 4,
    cardInfo: {
      icon: VehicleInspectionSection3,
      sectionSubHeader: '(4/5)',
      sectionWarningText: null,
      sectionMainHeader: 'Engine and drivetrain',
      sectionBody:
        'Now take a look at the engine and drive train and report what you see',
      sectionAdditionalInfo: '',
    },
    hasBack: true,
    primaryAction: {
      text: 'Start engine and drivetrain section',
      action: 'next',
    },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 4,
    questionType: 'select',
    screenId: 'BUMP-001-015',
    cardInfo: {
      question: 'Inspect for noises',
      description:
        'With the engine running, listen for abnormal noises. Anything harsh—rattling, knocking or screeching from the engine bay—could be a costly repair waiting in the wings.',
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 4,
    questionType: 'select',
    screenId: 'BUMP-001-016',
    cardInfo: {
      question: 'Inspect engine oil',
      description:
        "It should be golden brown and smooth to the touch on the dipstick. Black or gritty oil indicates poor maintenance while milky engine oil tells you water is present. That's bad.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 4,
    questionType: 'select',
    screenId: 'BUMP-001-017',
    cardInfo: {
      question: 'Inspect transmission fluid',
      description:
        'Cars with automatic transmissions normally have a dipstick to check the fluid. Fluid is commonly red and should be clear. If it smells burnt, looks dark or is milky, it needs maintenance.',
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 4,
    questionType: 'select',
    screenId: 'BUMP-001-018',
    cardInfo: {
      question: 'Inspect coolant',
      description:
        "Clean fluid without any particles is what you want. With the engine cold, open the cap to check the coolant. If there are crusty bits in the coolant, it's overdue for a flush. If there's oil in the coolant, the car might have a head gasket leak.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 4,
    questionType: 'select',
    screenId: 'BUMP-001-019',
    cardInfo: {
      question: 'Inspect for shifting',
      description:
        "Before going for a drive, sit in the driver's seat with the engine running and operate the shifter through the gears. Each movement should result in quiet engagement. Knocking noises or a hard bump into gear could mean the transmission is on the way out.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'section',
    sectionId: 5,
    cardInfo: {
      icon: VehicleInspectionSection4,
      sectionSubHeader: '(5/5)',
      sectionWarningText: 'DO NOT COMPLETE WHILE DRIVING',
      sectionMainHeader: 'Test drive',
      sectionBody: 'Final step: take a test drive and report what you find',
      sectionAdditionalInfo: '',
    },
    hasBack: true,
    primaryAction: {
      text: 'Start engine and drivetrain section',
      action: 'next',
    },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 5,
    questionType: 'select',
    screenId: 'BUMP-001-020',
    cardInfo: {
      question: 'Inspect for warning lights',
      description:
        "With the engine running, check for warning lights. Any amber or red lights are warnings that something isn't working as it should or needs attention soon. Any blue or green lights are simply informational and nothing concerning.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 5,
    questionType: 'select',
    screenId: 'BUMP-001-021',
    cardInfo: {
      question: 'Inspect brakes',
      description:
        "Get a feel for the brakes. When you press the pedal, it should travel smoothly and stop before reaching the floor. While you're driving, grinding or squealing can indicate rusty rotors or worn-out brake pads. Vibrations when you apply the brakes are often caused by warped rotors.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 5,
    questionType: 'select',
    screenId: 'BUMP-001-022',
    cardInfo: {
      question: 'Inspect steering',
      description:
        "Turn corners to the left and right and to varying degrees. Consistent steering motions that always return to center are normal. If the car feels like it's binding or it's hard to steer, there could be underlying power steering or suspension issues to address.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 5,
    questionType: 'select',
    screenId: 'BUMP-001-023',
    cardInfo: {
      question: 'Inspect acceleration',
      description:
        "Feel the power. Test the acceleration as much as you can legally and safely. Hesitations aren't normal, nor are warning lights that come on when you drive aggressively. If the acceleration is underwhelming, there could be tune-up concerns.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 5,
    questionType: 'select',
    screenId: 'BUMP-001-024',
    cardInfo: {
      question: 'Inspect for noises and vibration',
      description:
        'As you drive over bumps, turn corners, hit highway speeds and slow down, keep a keen ear out for noises and a feel for odd shakes. Clunks and rattles are usually loose steering or suspension parts, while vibrations could be bad tires or brakes.',
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'question',
    sectionId: 5,
    questionType: 'select',
    screenId: 'BUMP-001-025',
    cardInfo: {
      question: 'Inspect climate controls',
      description:
        "Try out all the heat and air conditioning settings. After the engine is warm, the heat should be toasty warm and the air conditioning ice cold. If they aren't, there could be expensive problems to repair, from a defective heater control to a failed air conditioning compressor.",
      choices: [
        'No issues found',
        'Some signs of wear',
        'Numerous or severe signs of wear',
      ],
    },
    hasBack: true,
    primaryAction: { text: 'Next Question', action: 'next' },
    secondaryAction: { text: 'Exit', action: 'exit' },
  },
  {
    cardType: 'misc',
    cardInfo: {
      icon: null,
      cardHeader: 'Last step!',
      cardBody:
        'You agree and certify that all the submitted answers to questions are true and correct to the best of your knowledge and belief.',
      cardAdditionalInfo: null,
    },
    hasBack: true,
    primaryAction: { text: 'Complete', action: 'complete' },
    secondaryAction: { text: 'Review and update', action: 'update' },
  },
  {
    cardType: 'misc',
    cardInfo: {
      icon: null,
      cardHeader: 'Congratulations!',
      cardBody: 'You have completed the vehicle inspection',
      cardAdditionalInfo: 'Your results are now ready to view in the report',
    },
    hasBack: false,
    primaryAction: { text: 'View inspection', action: 'viewInspection' },
  },
];
