import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Text,
} from '@ltvco/refresh-lib/theme';
import InspectionSurvey from 'images/inspection_survey.png';
import InspectionEngine from 'images/inspection-engine.svg';
import InspectionExterior from 'images/inspection-exterior.svg';
import InspectionInterior from 'images/inspection-interior.svg';
import InspectionSteeringWheel from 'images/inspection-steering-wheel.svg';

interface ReportSectionSelfInspectionCTAProps {
  vin?: string;
  permalink?: string;
}

export const ReportSectionSelfInspectionCTA = ({
  vin,
  permalink,
}: ReportSectionSelfInspectionCTAProps) => {
  const navigate = useNavigate();

  const redirectToVehicleInspectionSurvey = () => {
    navigate('/vehicle-self-inspection', { state: { vin, permalink } });
  };

  return (
    <Card
      id="self-inspection-cta"
      variant="outlined"
      sx={{ boxShadow: 1, borderRadius: 0, p: 0 }}
    >
      <Grid container>
        <Grid item xs={12} md={6}>
          <img
            src={InspectionSurvey}
            alt="inspection survey"
            width="100%"
            height="100%"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <Text variant="h1" color="primary.dark" fontStyle="italic">
              Complete a pre-purchase vehicle self-inspection
            </Text>
            <Text variant="body1">
              Help avoid costly and unforeseen automotive repairs by completing
              a pre-purchase vehicle self-inspection. Be more confident in the
              used car you're buying with Bumper's guided self-inspection and
              road test
            </Text>
            <Button
              variant="contained"
              fullWidth
              sx={{ my: 2.5 }}
              onClick={redirectToVehicleInspectionSurvey}
            >
              Start inspection
            </Button>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={1}
              sx={{
                borderTop: '1px solid #9c9b9b',
                borderBottom: '1px solid #9c9b9b',
                pt: 0.5,
                pb: 1.5,
              }}
            >
              <Grid item xs={12} sm={6}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Avatar
                    src={InspectionExterior}
                    sx={{ width: 19, height: 19 }}
                    variant="square"
                  />
                  <Text>Inspect exterior</Text>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Avatar
                    src={InspectionInterior}
                    sx={{ width: 19, height: 19 }}
                    variant="square"
                  />
                  <Text>Inspect interior</Text>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Avatar
                    src={InspectionEngine}
                    sx={{ width: 19, height: 19 }}
                    variant="square"
                  />
                  <Text>Inspect engine</Text>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Avatar
                    src={InspectionSteeringWheel}
                    sx={{ width: 19, height: 19 }}
                    variant="square"
                  />
                  <Text>Road test</Text>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
