const connectedVehicleUrl = '/api/v5/connected_vehicles';
const clientId = '6b4376ed-0606-42b5-b693-d8567353b9f1';
const redirectUri =
  'https://javascript-sdk.smartcar.com/v2/redirect?app_origin=https://www.bumper.com';

const scope = [
  'read_engine_oil',
  'read_battery',
  'read_charge',
  'control_charge',
  'read_fuel',
  'control_security',
  'read_odometer',
  'read_tires',
  'read_vehicle_info',
  'read_vin',
];
const resolutions = {
  TRY_LATER: 'Please try again later.',
  MANUFACTURER_ACCOUNT_ISSUE: 'Check your manufacturer account and try again.',
  REAUTHENTICATE: 'Please try again later.',
  SUBSCRIPTION: 'Check your subscription with the manufacturer and try again.',
  TURN_ON_VEHICLE: 'Turn on the vehicle and try again.',
  STOP_CHARGING: 'Stop charging the vehicle and try again.',
  RECONNECT_CHARGER: 'Reconnect charger and try again.',
  CLOSE_DOOR: 'Close the door and try again.',
  CLOSE_HOOD: 'Close the hood and try again.',
  TURN_OFF_VEHICLE: 'Turn off the vehicle and try again.',
  RE_ENABLE_REMOTE_ACCESS: 'Re-enable remove access and try again.',
  CLOSE_TRUNK: 'Close the trunk and try again.',
  CONNECT_TO_INTERNET: 'Connect the vehicle to internet and try again.',
};
const authErrors = {
  AccessDenied: {
    message: 'User denied access to the requested permissions.',
  },
  VehicleIncompatible: {
    message: "The user's vehicle is not compatible.",
  },
  InvalidSubscription: {
    message: 'User does not have an active subscription.',
    resolution: resolutions['SUBSCRIPTION'],
  },
};

export const smartcarConstants = {
  connectedVehicleUrl,
  clientId,
  redirectUri,
  scope,
  resolutions,
  authErrors,
};
