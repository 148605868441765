import { useEffect } from 'react';
import { PerksPage, useSession } from '@ltvco/refresh-lib/v1';
import { FreeUserRewardContainer } from 'components/Rewards/FreeUserRewardContainer';

export function RewardsPage() {
  const {
    session: { account },
  } = useSession();
  const isFreeUser = account?.account?.subscription_info?.free_user;

  useEffect(() => {
    document.title = 'Rewards - Bumper';
  }, []);

  return isFreeUser ? (
    <FreeUserRewardContainer displayCTABanner={true} displayBenefits={true} />
  ) : (
    <PerksPage title="Rewards" filterByCategories={true} />
  );
}
