/*
  Once we test and mature this code a little more and add the functionality to pull plans from the API,
  we can evaluate what of this code should be made shared in the refresh-lib
*/
import { type Plan, costPerReport, savings } from '@ltvco/refresh-lib/payments';

export interface CurrentPlan {
  title: string;
  amount: number;
  /** Renewal period in months */
  renewalPeriod: number;
  /** Report limit */
  limit: number;
  costPerReport?: number;
}

export const brandPlans: Plan[] = [
  {
    key: '19_99_1_month_nofree_afill_freshness_limit_50_freepdf_bumper_upsell',
    amount: 19.99,
    limit: 50,
    renewalPeriod: 1,
    title: 'Starter',
    description: 'Starting small and simple',
  },
  {
    key: '38_99_1_month_nofree_afill_limit_100_freepdf_bumper_upsell',
    amount: 38.99,
    limit: 100,
    renewalPeriod: 1,
    mostPopular: true,
    title: 'Standard',
    description: 'Double your search capacity',
  },
  {
    key: '89_99_1_month_nofree_afill_limit_250_freepdf_bumper_upsell',
    amount: 89.99,
    limit: 250,
    renewalPeriod: 1,
    title: 'Plus',
    description: 'Your comprehensive reports package',
  },
  {
    key: '165_99_1_month_nofree_afill_limit_500_freepdf_bumper_upsell',
    amount: 165.99,
    limit: 500,
    renewalPeriod: 1,
    title: 'Premium',
    description: 'Take your vehicle search to the next level',
  },
];

export function getPlanTitleAndDescription(planLimit: number) {
  const plan = brandPlans.find((plan) => plan.limit === planLimit);
  if (planLimit === -1 || !plan) {
    return { title: 'Power User' };
  }
  return { title: plan.title, description: plan.description };
}

/** Returns the savings of the plan compared to the current plan */
export function planSavings(plan: Plan, currentPlan: CurrentPlan) {
  if (currentPlan.limit === -1 || plan.limit === -1) return;

  return savings(
    currentPlan.amount,
    currentPlan.renewalPeriod,
    currentPlan.limit,
    plan.amount,
    plan.renewalPeriod,
    plan.limit
  );
}

/** Returns the cost per report of the plan if it's not unlimited */
export function planCostPerReport(plan: {
  amount: number;
  renewalPeriod: number;
  limit: number;
}) {
  if (plan.limit === -1) return;

  return costPerReport(plan.amount, plan.renewalPeriod, plan.limit);
}

/** Returns the plans that are better than the current plan */
export function getPlans(currentPlan: CurrentPlan) {
  return brandPlans
    .filter((plan) => plan.limit > currentPlan.limit)
    .map((plan) => ({
      ...plan,
      costPerReport: planCostPerReport(plan),
      savings: planSavings(plan, currentPlan),
      renewalPeriodType: 'months',
    }));
}

export function getBillingFrequency(renewalPeriod: number) {
  switch (renewalPeriod) {
    case 1:
      return 'monthly';
    case 3:
      return 'every 3 months';
    case 12:
      return 'annually';
    default:
      return 'N/A';
  }
}
