import * as Yup from 'yup';

export const vehicleReviewSchema = Yup.object({
  year: Yup.string().required('Year is required'),
  make: Yup.string().required('Make is required'),
  model: Yup.string().required('Model is required'),
  trim: Yup.string(),
  overallRating: Yup.number()
    .required('Overall rating is required')
    .min(1, 'Overall rating is required'),
  comfortRating: Yup.number()
    .required('Comfort rating is required')
    .min(1, 'Comfort rating is required'),
  interiorDesign: Yup.number()
    .required('Interior rating is required')
    .min(1, 'Interior rating is required'),
  performance: Yup.number()
    .required('Performance rating is required')
    .min(1, 'Performance rating is required'),
  value: Yup.number()
    .required('Value rating is required')
    .min(1, 'Value rating is required'),
  exterior: Yup.number()
    .required('Exterior rating is required')
    .min(1, 'Exterior rating is required'),
  reliability: Yup.number()
    .required('Reliability rating is required')
    .min(1, 'Reliability rating is required'),
  technology: Yup.number()
    .required('Technology rating is required')
    .min(1, 'Technology rating is required'),
  title: Yup.string()
    .required('Title is required')
    .min(5, 'Min 5 characters')
    .max(60, 'Max 60 characters'),
  review: Yup.string()
    .required('Review is required')
    .min(5, 'Min 5 characters')
    .max(500, 'Max 500 characters'),
  recommend: Yup.string().required('Recommendation is required'),
  vehiclePrimaryUsage: Yup.string().required('Primary use is required'),
  reviewingAs: Yup.string().required('Reviewing as is required'),
  name: Yup.string().required('Name is required'),
  zip: Yup.string()
    .length(5, 'Zip code must be 5 digits')
    .matches(/^[0-9]{5}/)
    .required('Zip code is required'),
  city: Yup.string().required('City, State is required'),
  state: Yup.string().required('City, State is required'),
});
