import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, Grid, styled } from '@ltvco/refresh-lib/theme';
import { AppConstants } from '@ltvco/refresh-lib/ctx';
import {
  enqueueSnackbar,
  filterActivePaymentMethod,
  PaymentMethods,
  ProcessingSpinnerModal,
  TosModal,
  useAccount,
  useGetPaymentMethod,
  useProcessBraintree,
  useProcessCard,
  useProcessPayPal,
  useSession,
  useSubmitLegal,
} from '@ltvco/refresh-lib/v1';
import {
  type Plan,
  type HardcodedPlan,
  UpgradeFailureModal,
  UpgradePlan,
  UpgradeSuccessModal,
  useGetPaymentType as getPaymentType,
  PaymentsSelectionModal,
  RadioPaymentsModal,
} from '@ltvco/refresh-lib/payments';
import {
  BUMPER_HARDCODED_JSON_API_PLANS,
  BUMPER_HARDCODED_PDF_API_PLANS,
} from 'utils/constants/hardcodedPlans';
import { useLimitedPlanInfo } from 'utils/useLimitedPlanInfo';
import {
  CurrentPlan,
  getBillingFrequency,
  getPlanTitleAndDescription,
  getPlans,
  planCostPerReport,
} from './plans';
import { formatForLib } from './formatForLib';
import PlanTypeSelector from './PlanTypeSelector/PlanTypeSelector';

const UpgradePlanContent = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3.5, 2),
  marginTop: 'max(0px,calc(40px - 1vw))',
  paddingLeft: 0,
  paddingRight: 0,
}));

enum State {
  None,
  SelectPayment,
  AddPayment,
  ProcessingPayment,
  Success,
  Error,
}

const publicTypeNames = [
  { internal: 'basic', public: 'Consumer Plans' },
  { internal: 'json', public: 'RawData API Plans' },
  { internal: 'pdf', public: 'PDF API Plans' },
];

type BpPlan = Plan | HardcodedPlan;

export const UpgradePlanPage = () => {
  const location = useLocation();
  const {
    links: { contactForm },
  } = useContext(AppConstants);
  const { refetch: refetchAccount } = useAccount(true);
  const {
    session: { account: accountResponse },
  } = useSession();
  const {
    accountLoaded,
    isStaff,
    isLimitedUser,
    isCancelledSubscription,
    planAmount: amount,
    reportLimit: limit,
    renewalPeriod,
  } = useLimitedPlanInfo();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [allPlans, setAllPlans] = useState<Record<string, BpPlan[]>>({});
  const [plans, setPlans] = useState<BpPlan[]>([]);
  const [selectedPlanType, setSelectedPlanType] = useState<string>('basic');
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [upgradeState, setUpgradeState] = useState(State.None);
  const isState = (state: State) => upgradeState === state;

  const externalSelectedPlanUniqueKey = location.state?.unique_key || '';
  const externalSelectedPlanType = location.state?.plan_type || '';

  const externalSelectedPlan = plans.find(
    (plan) => plan.unique_key === externalSelectedPlanUniqueKey
  );

  const title = isStaff ? 'Staff' : getPlanTitleAndDescription(limit).title;
  const currentPlan: CurrentPlan = useMemo(() => {
    return {
      title,
      amount,
      renewalPeriod,
      limit,
      costPerReport: planCostPerReport({ amount, renewalPeriod, limit }),
    };
  }, [amount, limit, renewalPeriod, title]);

  useEffect(() => {
    if (!accountLoaded) return;

    generatePlansArray();

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [accountLoaded, currentPlan, location.state?.unique_key]);

  const showDefaultError = () => {
    enqueueSnackbar(
      'Something went wrong, please try again or contact customer service',
      { variant: 'error' }
    );
  };

  const generatePlansArray = () => {
    const dynamicPlans = getPlans(currentPlan);
    const allPlans = {
      basic: dynamicPlans,
      json: formatForLib(BUMPER_HARDCODED_JSON_API_PLANS),
      pdf: formatForLib(BUMPER_HARDCODED_PDF_API_PLANS),
    };
    setAllPlans(allPlans);
    setSelectedPlanType(externalSelectedPlanType || 'basic');
    setPlans(
      allPlans[externalSelectedPlanType as keyof typeof allPlans] ||
        allPlans.basic
    );
  };

  const selectPlanType = (planType: string) => {
    setSelectedPlanType(planType);
    setPlans(allPlans[planType]);
  };

  const onSelectPlan = (plan: Plan) => {
    setSelectedPlan(plan);
    setBillingFrecuency(getBillingFrequency(plan.renewalPeriod));
    setBilledPrice(`$${plan.amount}`);
    setOpenTosModal(true);
  };

  // TOS

  const updateLegalQuery = useSubmitLegal();
  const [openTosModal, setOpenTosModal] = useState(false);
  const [processingPayment, setProcessingPayment] = useState<boolean>(false);
  const [billingFrecuency, setBillingFrecuency] = useState('monthly');
  const [billedPrice, setBilledPrice] = useState('');

  const onCloseTos = () => setOpenTosModal(false);
  const onAcceptTos = () => {
    setProcessingPayment(true);
    updateLegalQuery.mutate(
      { legal_doc: 'tos' },
      {
        onSuccess: () => upgradePlan(),
        onError: () => showDefaultError(),
        onSettled: () => onCloseTos(),
      }
    );
  };

  // Payment

  const paymentMethodQuery = useGetPaymentMethod();
  const processCardQuery = useProcessCard();
  const processPayPalQuery = useProcessPayPal();
  const processBraintreeQuery = useProcessBraintree();
  const [activePayment, setActivePayment] = useState<PaymentMethods>();
  const [upgradeErrors, setUpgradeErrors] = useState<string[]>([]);
  const [hadPaymentErrors, setHadPaymentErrors] = useState(false);

  useEffect(() => {
    if (!paymentMethodQuery.isLoading && !paymentMethodQuery.isError) {
      setActivePayment(filterActivePaymentMethod(paymentMethodQuery?.data));
    }
  }, [paymentMethodQuery]);

  const upgradePlan = () => {
    if (!selectedPlan || !activePayment) return;

    setUpgradeState(State.ProcessingPayment);

    const plan = { unique_key: selectedPlan.key };
    const queryOptions: any = {
      onSuccess: () => {
        refetchAccount();
        setUpgradeState(State.Success);
      },
      onError(error: Error) {
        console.error('error', error);
        const cause = error.cause as string[];

        setUpgradeErrors(cause);
        setUpgradeState(State.Error);
      },
    };

    interface MutationFunctions {
      paypal: typeof processPayPalQuery;
      braintree: typeof processBraintreeQuery;
      card: typeof processCardQuery;
    }

    const mutationFunctions: MutationFunctions = {
      paypal: processPayPalQuery,
      braintree: processBraintreeQuery,
      card: processCardQuery,
    };

    const paymentType: 'card' | 'braintree' | 'paypal' =
      getPaymentType(activePayment);

    const mutationFunction = mutationFunctions[paymentType] || processCardQuery;

    const mutationParams = {
      plan,
      ...((paymentType === 'paypal' && {
        paypalId: activePayment.id,
      }) as { paypalId: number }),
      ...((paymentType === 'card' && {
        cardId: activePayment.id,
      }) as {
        cardId: number;
      }),
      ...((paymentType === 'braintree' && {
        braintreeId: activePayment.id,
      }) as { braintreeId: number }),
      source_of_payment: 'manual_upgrade_attempt',
    };
    setProcessingPayment(false);
    mutationFunction.mutate(mutationParams, queryOptions);
  };

  const hasPlans = plans.length > 0;

  return (
    <UpgradePlanContent container={hasPlans}>
      {isLoading && <CircularProgress sx={{ my: 30 }}></CircularProgress>}

      {!isLoading && (
        <>
          <UpgradePlan
            currentPlan={{
              title: currentPlan.title,
              limit: isLimitedUser ? currentPlan.limit : 'unlimited',
              costPerReport: currentPlan.costPerReport,
              canceled: isCancelledSubscription,
            }}
            externalSelectedPlan={externalSelectedPlan}
            plans={plans as Plan[]}
            onSelectPlan={onSelectPlan}
            onContactUs={() => {
              let url = contactForm;

              if (accountResponse?.account.user_info.email) {
                url += `?email_address=${encodeURIComponent(
                  accountResponse.account.user_info.email
                )}`;
              }

              window.open(url, '_blank', 'noopener,noreferrer');
            }}
            buttonGroup={
              <PlanTypeSelector
                selectedPlanType={selectedPlanType}
                setSelectedPlanType={selectPlanType}
                planTypes={publicTypeNames}
              />
            }
          />

          <TosModal
            open={openTosModal}
            showCheckbox={true}
            billingFrecuency={billingFrecuency}
            billedPrice={billedPrice}
            cta={{
              text: 'Accept and Upgrade',
              extraWide: true,
              processingPayment,
            }}
            showPaymentMethod={true}
            acceptTos={onAcceptTos}
            onChangePaymentMethod={() => setUpgradeState(State.SelectPayment)}
            onClose={onCloseTos}
          />

          <PaymentsSelectionModal
            open={isState(State.SelectPayment)}
            cta="Use This Payment Method"
            onAddPaymentMethod={() => setUpgradeState(State.AddPayment)}
            onSelectedPaymentMethod={() => {
              if (hadPaymentErrors) {
                setHadPaymentErrors(false);
                upgradePlan();
              } else {
                setUpgradeState(State.None);
              }
            }}
            onClose={() => {
              if (hadPaymentErrors) {
                setHadPaymentErrors(false);
                setUpgradeState(State.Error);
              } else {
                setUpgradeState(State.None);
              }
            }}
          />

          <RadioPaymentsModal
            isOpen={isState(State.AddPayment)}
            onCloseHandle={() => setUpgradeState(State.None)}
          />

          <ProcessingSpinnerModal open={isState(State.ProcessingPayment)} />

          <UpgradeSuccessModal
            open={isState(State.Success)}
            onAccept={() => {
              setUpgradeState(State.None);

              navigate('/dashboard/home');
            }}
          />

          <UpgradeFailureModal
            open={isState(State.Error)}
            errors={upgradeErrors}
            onTryAgain={() => upgradePlan()}
            onChangeCard={() => {
              setHadPaymentErrors(true);
              setUpgradeState(State.SelectPayment);
            }}
            onClose={() => {
              setUpgradeState(State.None);
            }}
          />
        </>
      )}
    </UpgradePlanContent>
  );
};
