import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { useSession } from '@ltvco/refresh-lib/v1';
import { constants } from 'appConstants';
// import { publish } from '@ltvco/refresh-lib';

export const AccountInformationOptions = [
  {
    name: 'Edit Name',
    display: 'Edit Name',
    value: 'Edit Name',
    constantValue: 'EDIT_NAME',
  },
  {
    name: 'Edit Email',
    display: 'Edit Email',
    value: 'Edit Email',
    constantValue: 'EDIT_EMAIL',
  },
  {
    name: 'Add Phone Number',
    display: 'Add Phone Number',
    value: 'Add Phone Number',
    constantValue: 'ADD_PHONE_NUMBER',
  },
  {
    name: 'Change Password',
    display: 'Change Password',
    value: 'Change Password',
    constantValue: 'CHANGE_PASSWORD',
  },
  {
    name: 'Edit Payment Info',
    display: 'Edit Payment Info',
    value: 'Edit Payment Info',
    constantValue: 'EDIT_PAYMENT_INFO',
  },
];

export const editOptionClicked = (
  editing: string | null,
  openModal: (open: boolean) => void,
  setEditing: Dispatch<SetStateAction<string | null>>
) => {
  if (editing === 'Edit Payment Info') {
    openModal(true);
  } else {
    setEditing(
      AccountInformationOptions.find((option) => option.name === editing)
        ?.constantValue || editing
    );
  }
};

export const GenericCtaCardProps = (navigate: NavigateFunction) => {
  // has_sso_connections
  const {
    session: { account },
  } = useSession();

  const has_sso_connections = account?.account?.user_info?.has_sso_connections;
  // AB Tests
  const isInSingleSignVariation = useFeatureIsOn('RTRN-68');

  const singleSignCta = {
    headerText: 'Connect or Edit Login Options',
    bodyText:
      'Add your Google or Apple Login on top of your account password & email',
    buttonText: 'Edit Login Options',
    imgUrl: constants.images.imgSingleSignOnCta,
    onCtaClick: () =>
      window.open('https://www.bumper.com/sso_connections', '_blank'),
    dataCy: 'login_options_cta',
  };

  const props = [
    // Commented out until both are ready
    // {
    //   headerText: 'Multiple ways to Search',
    //   bodyText:
    //     'Search a vehicle by VIN, License Plate or Year, Make and Model',
    //   buttonText: 'Search now',
    //   imgUrl: constants.imgSearch,
    //   onCtaClick: () => {
    //     publish('openVinSearch');
    //   },
    // },
    // {
    //   headerText: 'Monitor reports',
    //   bodyText: 'Get alerted about new data on reports as we become aware',
    //   buttonText: 'Search now',
    //   imgUrl: constants.imgBellMonitoring,

    //   onCtaClick: () => {
    //     // publish('openPersonSearch');
    //     navigate('/dashboard/monitoring');
    //   },
    // },
    {
      headerText: '24-hour access',
      bodyText:
        "It's always the right time to satisfy your curiosity by looking up a vehicle!",
      imgUrl: constants.images.imgContactHours,
      dataCy: '24-hour_cta',
    },
    {
      headerText: 'Unlimited customer care',
      bodyText: "We're here to help you get the most out of your membership.",
      imgUrl: constants.images.imgHeadset,
      dataCy: 'unclaimed_cta',
    },
  ];

  if (has_sso_connections || isInSingleSignVariation) {
    props.push(singleSignCta);
  }

  return { ctaCardProps: props, length: props.length };
};
