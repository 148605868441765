import { Box, Card, Text } from '@ltvco/refresh-lib/theme';
import { MobileAppsCtaButtons } from '@ltvco/refresh-lib/v1';
import { constants } from 'appConstants';

export function BPMobileAppsCtaCard() {
  return (
    <Card
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'center',
        bgcolor: 'grey.100',
        paddingBottom: 0,
        paddingTop: 4,

        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          alignItems: 'center',
          padding: theme.spacing(2),
        },
      })}
    >
      <Box>
        <img alt="Mobile App" src={constants.images.imgMobileApp} />
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: theme.spacing(3),

          [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),
          },
        })}
      >
        <Text variant="h2" textAlign="center">
          Access our app with one easy tap
        </Text>
        <Box sx={(theme) => ({ mt: theme.spacing(2) })}>
          <MobileAppsCtaButtons />
        </Box>
      </Box>
    </Card>
  );
}
