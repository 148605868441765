import { useEffect } from 'react';
import { Stack } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { SelfInspection } from 'components/SelfInspection/SelfInspection';

export function SelfInspectionPage() {
  const { isMobile } = useScreenSize();
  useEffect(() => {
    document.title = 'Self Inspection - Bumper';
  }, []);

  return (
    <Stack
      alignItems="center"
      sx={{
        height: `calc(100vh - ${isMobile ? 68 : 63}px)`,
        pt: 1.5,
      }}
    >
      <SelfInspection />
    </Stack>
  );
}
