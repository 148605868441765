import { useNavigate } from 'react-router-dom';
import { Card } from '@ltvco/refresh-lib/theme';
import { useAurelia } from 'hooks/useAurelia';
import { EmptyVehicleReviews } from './EmptyVehicleReviews/EmptyVehicleReviews';
import { ReviewsContainer } from './ReviewsContainer/ReviewsContainer';

interface VehicleReviewsProps {
  ymmText: string;
  ymm: {
    year: string;
    make: string;
    model: string;
    trim?: string;
  };
  permalink: string;
}

export const VehicleReviews = ({
  ymmText,
  ymm,
  permalink,
}: VehicleReviewsProps) => {
  const navigate = useNavigate();
  const redirectToVehicleReview = () => {
    navigate('/vehicle-review', { state: { ymmText, ymm, permalink } });
  };

  const { data } = useAurelia({
    year: ymm.year,
    make: ymm.make,
    model: ymm.model,
    trim: ymm?.trim && '',
  });

  return (
    <Card
      id="vehicle-review"
      variant="outlined"
      sx={{ boxShadow: 1, borderRadius: 0, p: 2.5 }}
    >
      {Boolean(data?.vehicleReviews.length) ? (
        <ReviewsContainer
          ymmText={ymmText}
          reviews={data?.vehicleReviews!}
          aggregatedData={data?.vehicleAggregatedReviewScores!}
          redirectToVehicleReview={redirectToVehicleReview}
        />
      ) : (
        <EmptyVehicleReviews
          redirectToVehicleReview={redirectToVehicleReview}
          ymm={ymm}
        />
      )}
    </Card>
  );
};
