import { Box, Text, Table, Button } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { ApiDocumentation } from 'utils/constants/apiDocumentation';

/**
 * Props for the ApiInfo component.
 *
 * @interface
 * @property {('pdf'|'json')?} currentlySelectedPlanType - The currently selected plan type, optional.
 */
interface ApiInfoProps {
  currentlySelectedPlanType?: 'pdf' | 'json';
}

/**
 * React component for displaying API information.
 *
 * This component renders a table showing details of the API endpoints,
 * including method, endpoint, and a description. It filters the API
 * documentation based on the currently selected plan type.
 *
 * @param {ApiInfoProps} props - The props for the component.
 * @returns {React.ReactElement} A React element representing the API information table.
 */
export default function ApiInfo({ currentlySelectedPlanType }: ApiInfoProps) {
  const { isMobile } = useScreenSize();
  /**
   * Builds rows for the API information table.
   *
   * Generates JSX elements for each row in the table based on the API documentation
   * and the currently selected plan type (if any).
   *
   * @returns {JSX.Element[][]} An array of JSX element arrays representing table rows.
   */
  const buildRows = () => {
    const docs: JSX.Element[][] = [];
    for (const key in ApiDocumentation) {
      if (!currentlySelectedPlanType || currentlySelectedPlanType === key) {
        const doc = ApiDocumentation[key as keyof typeof ApiDocumentation];
        doc.forEach((d) => {
          docs.push([
            <Text>{d.method}</Text>,
            <Text>{d.endpoint}</Text>,
            <Text>{d.description}</Text>,
            <a
              href="https://apidocs.bumper.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="contained" color="success">
                Docs
              </Button>
            </a>,
          ]);
        });
      }
    }

    return docs;
  };

  const buildMobileRows = () => {
    const docs: JSX.Element[] = [];

    for (const key in ApiDocumentation) {
      if (!currentlySelectedPlanType || currentlySelectedPlanType === key) {
        const doc = ApiDocumentation[key as keyof typeof ApiDocumentation];

        doc.forEach((d, index) => {
          docs.push(
            <Table
              key={index}
              className="striped styleless-header mobile"
              headers={[
                <Text
                  variant="h5"
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                >
                  {d.method}
                </Text>,
              ]}
              rows={[
                ['Endpoint', d.endpoint],
                ['Description', d.description],
                [
                  'Docs',
                  <a
                    href="https://apidocs.bumper.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button variant="contained" color="success">
                      Docs
                    </Button>
                  </a>,
                ],
              ]}
            />
          );
        });
      }
    }

    return docs;
  };

  return (
    <Box>
      <Text variant="h3">API Info</Text>
      {isMobile ? (
        buildMobileRows()
      ) : (
        <Table
          className="striped"
          headerRowStyle={{
            color: 'primary.contrastText',
            backgroundColor: 'primary.main',
            fontWeight: 'bold',
          }}
          headers={['Method', 'End point', 'Description', 'Actions']}
          rows={buildRows()}
        />
      )}
    </Box>
  );
}
