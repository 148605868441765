import { Text } from '@ltvco/refresh-lib/theme';
import { MiscellaneousCard } from 'utils/constants/selfInspectionQuestionnaire';

interface DataCardProps {
  cardData: MiscellaneousCard;
}

export const DataCard = ({ cardData }: DataCardProps) => {
  return (
    <>
      <Text
        variant="h2"
        textAlign="center"
        fontStyle="italic"
        color="primary.dark"
      >
        {cardData.cardInfo.cardHeader}
      </Text>
      <Text variant="body1" textAlign="center" mb={1.25}>
        {cardData.cardInfo.cardBody}
      </Text>
      <Text variant="body1" textAlign="center" mb={2.25}>
        {cardData.cardInfo.cardAdditionalInfo}
      </Text>
    </>
  );
};
