import { Box, Stack, styled } from '@ltvco/refresh-lib/theme';

export const HeaderContainer = styled(Box)(() => ({
  height: '55px',
}));

export const StyledContainer = styled(Stack)(() => ({
  position: 'fixed',
  zIndex: 20,
  width: '100%',
  height: 'inherit',
}));

export const StyledHeader = styled(Stack)(({ theme }) => ({
  height: 'inherit',
  width: '100%',
  zIndex: 16,
  boxShadow: 'rgba(117, 117, 117, 0.5) 0px 1px 2px',
  background: theme.palette.background.paper,
}));

export const StyledHeaderNav = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  padding: '0 20px',
}));

export const StyledBannerContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));
