import { createContext, useContext, useState } from 'react';
import { DropdownHeaderItemName } from '../components/ClassicHeader/ClassicHeaderContents/types';

type HeaderContextState = {
  currentDropdown: DropdownHeaderItemName;
  handleSetCurrentDropdown: (
    newCurrentDropdown: DropdownHeaderItemName
  ) => void;
};

const HeaderContext = createContext({
  currentDropdown: null,
  handleSetCurrentDropdown: () => {},
} as HeaderContextState);

export const useHeaderContext = () => useContext(HeaderContext);

interface HeaderProviderProps {
  children: JSX.Element;
}

export const HeaderProvider = ({ children }: HeaderProviderProps) => {
  const [currentDropdown, setCurrentDropdown] =
    useState<DropdownHeaderItemName>(null);

  const handleSetCurrentDropdown = (
    newCurrentDropdown: DropdownHeaderItemName
  ) => {
    setCurrentDropdown(newCurrentDropdown);
  };

  const values = {
    currentDropdown,
    handleSetCurrentDropdown,
  };

  return (
    <HeaderContext.Provider value={values}>{children}</HeaderContext.Provider>
  );
};
