import { Box, Stack, Text, Rating } from '@ltvco/refresh-lib/theme';
import {
  capitalizeString,
  formatDateFromReport,
} from '@ltvco/refresh-lib/utils';
import { StarRate, StarBorder } from '@mui/icons-material';
interface ReviewCardProps {
  review: any;
}
export const ReviewCard = ({ review }: ReviewCardProps) => {
  const numericalRating = [
    { title: 'Overall Rating', value: review.overallRating },
    { title: 'Comfort', value: review.comfortRating },
    { title: 'Interior Design', value: review.interiorDesign },
    { title: 'Performance', value: review.performance },
    { title: 'Value for the money', value: review.value },
    { title: 'Exterior Styling', value: review.exterior },
    { title: 'Reliability', value: review.reliability },
    { title: 'Technology', value: review.technology },
  ];

  return (
    <Stack spacing={1.5}>
      <Stack
        py={2.5}
        px={3}
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '4px',
        }}
        spacing={1}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Rating
            readOnly
            value={review.averageRating}
            precision={0.1}
            icon={
              <Box color="primary.dark">
                <StarRate fontSize="inherit" color="inherit" />
              </Box>
            }
            emptyIcon={<StarBorder fontSize="inherit" />}
          />
          <Text variant="h6" color="text.secondary" mb={0}>
            {review.averageRating.toFixed(1)}
          </Text>
        </Stack>
        <Text variant="h5" color="text.primary">
          {review.title}
        </Text>
        <Text variant="body2" color="text.primary">
          {review.review}
        </Text>
        <Stack>
          <Text variant="body2" color="text.secondary">
            {review.name} from {capitalizeString(review.city)}, {review.state}
          </Text>
          <Text variant="h6" color="text.primary" fontSize={12}>
            {formatDateFromReport(review.createdAt, 'MMM dd, yyyy')} •{' '}
            {review.reviewingAs}
          </Text>
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 2.25, sm: 20 }}
        >
          <Stack direction="column" spacing={1.2}>
            {numericalRating.map((rating) => (
              <Stack key={rating.title} direction="row" spacing={1.5}>
                <Text variant="h6" color="text.primary">
                  {`${rating.value}.0`}
                </Text>
                <Text variant="body2" color="text.primary">
                  {rating.title}
                </Text>
              </Stack>
            ))}
          </Stack>
          <Box>
            <Stack direction="column">
              <Text variant="body2" color="text.secondary">
                Primary use
              </Text>
              <Text variant="h6" color="text.primary">
                {review.vehiclePrimaryUsage}
              </Text>
            </Stack>
            <Stack direction="column">
              <Text variant="body2" color="text.secondary">
                Recommends car
              </Text>
              <Text variant="h6" color="text.primary">
                {review.recommend === 'true' ? 'Yes' : 'No'}
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
