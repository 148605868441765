import { request } from '@ltvco/refresh-lib/utils';
import {
  AnswerSetParams,
  AnswerSetsResponse,
  CreateAnswerSetParams,
  CreateAnswerSetResponse,
} from './interfaces';

const bowtieUrl = '/bow/v1/';

interface VehicleSelfInspectionInterface {
  getVehicleSelfInspections: (
    token: string,
    params: AnswerSetParams
  ) => Promise<AnswerSetsResponse>;
  createVehicleSelfInspection: (
    token: string,
    params: CreateAnswerSetParams
  ) => Promise<CreateAnswerSetResponse>;
}

const getVehicleSelfInspections = async (
  token: string,
  params: AnswerSetParams
) => {
  const data = new URLSearchParams();
  data.append('plan_id', params.planId);
  data.append('identifier', params.vin);
  data.append('u_c', params.userCode);

  const init = {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  };

  return await request(`${bowtieUrl}answer_sets?${data}`, init);
};

const createVehicleSelfInspection = async (
  token: string,
  params: CreateAnswerSetParams
) => {
  const init = {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify(params),
  };

  return await request(`${bowtieUrl}answer_sets`, init);
};

export const bowtie: VehicleSelfInspectionInterface = {
  getVehicleSelfInspections,
  createVehicleSelfInspection,
};
