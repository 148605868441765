import {
  Avatar,
  Box,
  Stack,
  Text,
  Button,
  styled,
} from '@ltvco/refresh-lib/theme';
import EmptyVehicleReviewsImg from 'images/owner-reviews-empty.png';

interface EmptyVehicleReviewsProps {
  redirectToVehicleReview: () => void;
  ymm: {
    year: string;
    make: string;
    model: string;
    trim?: string;
  };
}

const StyledAvatar = styled(Avatar)`
  max-width: 404px;
  width: 100%;
  height: 100%;
  margin: auto;
`;

export const EmptyVehicleReviews = ({
  ymm,
  redirectToVehicleReview,
}: EmptyVehicleReviewsProps) => {
  const vehicleYMMText = Object.values(ymm).filter(Boolean).join(' ');

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 4 }}>
      <Box width={{ xs: '100%' }}>
        <Stack direction="column" width="100%">
          <Text variant="h2" color="primary.main">
            Owner Reviews
          </Text>
          <Text variant="body1" mb={3} color="text.secondary">
            There are no reviews for the {vehicleYMMText} yet. Be the first to
            leave a review!
          </Text>
          <Button
            sx={{ alignSelf: 'flex-start' }}
            variant="contained"
            color="success"
            onClick={redirectToVehicleReview}
          >
            Write a review
          </Button>
        </Stack>
      </Box>
      <Box width={{ xs: '100%' }}>
        <Stack>
          <StyledAvatar
            src={EmptyVehicleReviewsImg}
            alt="Vehicle Review Image"
            variant="square"
          />
        </Stack>
      </Box>
    </Stack>
  );
};
