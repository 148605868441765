// @ts-nocheck
import { useState, useEffect, useContext } from 'react';
import Smartcar from '@smartcar/auth';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@ltvco/refresh-lib/vendors';
import { request } from '@ltvco/refresh-lib/utils';
import { useVehicleSearch } from '@ltvco/refresh-lib/hooks';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useSnackbar } from '@ltvco/refresh-lib/v1';
import { SmartcarRequests } from 'api/requests/smartcar/SmartcarRequests';
import { smartcarConstants } from '../../utils/constants/smartcarConstants';

interface SmartcarClient {
  clientId: string;
  redirectUri: string;
  scope: string[];
  mode: string;
  responseType: string;
  instanceId: string;
}

const baseQueryKey = 'smartcar';

export const useSmartcar = (smartcarId: string = '') => {
  const [client, setClient] = useState<null | SmartcarClient>(null);
  const [shouldRunReports, setShouldRunReports] = useState(true);

  const { logError } = useContext(AppConfig);
  const { generateAndClaimVehicleReport } = useVehicleSearch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!client) {
      initClient();
    }
  }, [client]);

  const result = useQuery({
    queryKey: [`${baseQueryKey}-${smartcarId}`],
    queryFn: () => SmartcarRequests.getVehicleInfo(smartcarId),
    enabled: Boolean(smartcarId),
    refetchOnMount: false,
  });

  const initClient = () => {
    try {
      const newClient: SmartcarClient = new Smartcar({
        clientId: smartcarConstants.clientId,
        redirectUri: smartcarConstants.redirectUri,
        scope: smartcarConstants.scope,
        onComplete: (error: Error, code: any) => {
          if (error) {
            logError('Smartcar client init', error as Error);
          } else {
            connectVehicles(code);
          }
        },
      });
      setClient(newClient);
    } catch (err) {
      logError('Smartcar client init', err as Error);
    }
  };

  const connectVehicles = async (code: string) => {
    const data = new URLSearchParams();
    data.append('code', code);

    const init = {
      method: 'POST',
      body: data,
    };

    const response = await request(smartcarConstants.connectedVehicleUrl, init);

    if (response.success) {
      if (shouldRunReports) {
        const vins = response.vins || [];
        vins.forEach((vin: string) => {
          generateAndClaimVehicleReport.mutate({ vin });
        });
      }

      queryClient.invalidateQueries(['smartcar-connected-vehicle']);
    } else {
      logError('Connecting vehicles', new Error('Connecting vehicles'));
    }
  };

  const openDialog = (shouldRunReports = true) => {
    setShouldRunReports(shouldRunReports);

    if (!client) {
      initClient();
    }

    if (client) {
      (client as SmartcarClient).openDialog({});
    } else {
      logError('Open smartcar modal', new Error('Open smartcar modal'));
    }
  };

  const lockVehicleMutation = useMutation({
    mutationFn: () => SmartcarRequests.lockVehicle(smartcarId),
    onSuccess: (response) => {
      if (response.success) {
        enqueueSnackbar('Successful lock vehicle', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar('Lock vehicle error', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      logError('Lock vehicle error', error as Error);
    },
  });

  const unlockVehicleMutation = useMutation({
    mutationFn: () => {
      return SmartcarRequests.unlockVehicle(smartcarId);
    },
    onSuccess: (response) => {
      if (response.success) {
        enqueueSnackbar('Successful unlock vehicle', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar('Unlock vehicle error', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      logError('Unlock vehicle error', error as Error);
    },
  });

  const startChargeMutation = useMutation({
    mutationFn: () => {
      return SmartcarRequests.startCharge(smartcarId);
    },
    onSuccess: (response) => {
      if (response.success) {
        enqueueSnackbar('Successful start charging vehicle', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar('Charging vehicle error', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      logError('Charging vehicle error', error as Error);
    },
  });

  const stopChargeMutation = useMutation({
    mutationFn: () => {
      return SmartcarRequests.stopCharge(smartcarId);
    },
    onSuccess: (response) => {
      if (response.success) {
        enqueueSnackbar('Successful stop charging vehicle', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar('Stop charging vehicle error', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      logError('Stop charging vehicle error', error as Error);
    },
  });

  const disconnectVehicleMutation = useMutation({
    mutationFn: () => {
      return SmartcarRequests.disconnectVehicle(smartcarId);
    },
    onSuccess: (response) => {
      if (response.success) {
        enqueueSnackbar('Successful disconnected vehicle', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        queryClient.invalidateQueries({
          queryKey: [`${baseQueryKey}-${smartcarId}`],
        });
        queryClient.invalidateQueries(['smartcar-connected-vehicle']);
      }
    },
    onError: (error) => {
      enqueueSnackbar('Failed to disconnect vehicle error', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      logError('Failed to disconnect vehicle error', error as Error);
    },
  });

  return {
    openDialog,
    lockVehicleMutation,
    unlockVehicleMutation,
    startChargeMutation,
    stopChargeMutation,
    disconnectVehicleMutation,
    ...result,
  };
};
