import { Box, Stack } from '@ltvco/refresh-lib/theme';
import { ApiStatus, ApiUpgradeCta, ApiKeysList } from '@ltvco/refresh-lib/v1';
import ApiFaqs from 'components/FAQ/ApiFaqs/ApiFaqs';
import ApiInfo from 'components/ApiInfo/ApiInfo';
import { CreditCalculator } from 'components/CreditCalculator/CreditCalculator';

/**
 * React component for the API non-gated page.
 *
 * This component is the main container for the API non-gated page, which includes
 * various components such as ApiStatus, ApiUpgradeCta, ApiKeysList, ApiInfo,
 * CreditCalculator, and ApiFaqs. It displays information and functionalities
 * accessible without gating, such as API key management and FAQs.
 *
 * @returns {React.ReactElement} A React element representing the API non-gated page.
 */
export function ApiNonGatedPage() {
  return (
    <Stack spacing={4}>
      <ApiStatus />
      <Box>
        <ApiUpgradeCta />
      </Box>
      <ApiKeysList />
      <ApiInfo />
      <CreditCalculator />
      <ApiFaqs />
    </Stack>
  );
}
