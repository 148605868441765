import { Avatar, Box } from '@ltvco/refresh-lib/theme';
import {
  Restore,
  List,
  NotificationsNone,
  DirectionsCar,
  Code,
  ExtensionOutlined,
  PersonAdd,
  Logout,
} from '@mui/icons-material';
import RewardIcon from 'images/reward-icon-outline.svg';
import MarketplaceMultiIcon from 'images/marketplace-icon-multi.svg';
import WrenchOutlineIcon from 'images/wrench-icon-outline.svg';
import { AccordionName } from 'components/MobileHeaderNav/MobileHeaderMenuDropdown/MobileHeaderMenuDropdown';
import { constants } from 'appConstants';

export interface MenuItem {
  title: AccordionName | string;
  icon: JSX.Element;
  hasLink: boolean;
  link: {
    route: string;
    internal: boolean;
  } | null;
  hasSubMenu: boolean;
  subMenu:
    | {
        title: string;
        route: string;
        internal: boolean;
      }[]
    | null;
  logout?: boolean;
}

type MenuItems = MenuItem[];

export const MenuItems: MenuItems = [
  {
    title: 'Recent Reports',
    icon: (
      <Box sx={{ fontSize: '24px', color: 'primary.dark' }}>
        <Restore fontSize="inherit" color="inherit" />
      </Box>
    ),
    hasLink: true,
    link: {
      route: '/dashboard/reports',
      internal: true,
    },
    hasSubMenu: false,
    subMenu: null,
  },
  {
    title: 'Saved Reports and Lists',
    icon: (
      <Box sx={{ fontSize: '24px', color: 'primary.dark' }}>
        <List fontSize="inherit" color="inherit" />
      </Box>
    ),
    hasLink: true,
    link: {
      route: '/dashboard/lists',
      internal: true,
    },
    hasSubMenu: false,
    subMenu: null,
  },
  {
    title: 'Rewards',
    icon: (
      <Avatar
        src={RewardIcon}
        alt="Reward"
        variant="square"
        sx={{ width: 20, height: 20 }}
      />
    ),
    hasLink: true,
    link: {
      route: '/dashboard/rewards',
      internal: true,
    },
    hasSubMenu: false,
    subMenu: null,
  },
  {
    title: 'Monitoring',
    icon: (
      <Box sx={{ fontSize: '24px', color: 'primary.dark' }}>
        <NotificationsNone fontSize="inherit" color="inherit" />
      </Box>
    ),
    hasLink: true,
    link: {
      route: '/dashboard/monitoring',
      internal: true,
    },
    hasSubMenu: false,
    subMenu: null,
  },
  {
    title: 'Tools',
    icon: (
      <Avatar
        src={WrenchOutlineIcon}
        alt="Reward"
        variant="square"
        sx={{ width: 20, height: 20 }}
      />
    ),
    hasLink: false,
    link: null,
    hasSubMenu: true,
    subMenu: [
      {
        title: 'Compare vehicles',
        route: 'https://www.bumper.com/tools/compare-cars/',
        internal: false,
      },
      {
        title: 'Market Value',
        route: 'https://www.bumper.com/tools/market-value/',
        internal: false,
      },
      {
        title: 'Helpful articles',
        route: 'https://www.bumper.com/blog/',
        internal: false,
      },
    ],
  },
  {
    title: 'My Bumper',
    icon: (
      <Box sx={{ fontSize: '24px', color: 'primary.dark' }}>
        <DirectionsCar fontSize="inherit" color="inherit" />
      </Box>
    ),
    hasLink: false,
    link: null,
    hasSubMenu: true,
    subMenu: [
      {
        title: 'My Bumper',
        route: '/dashboard/home',
        internal: true,
      },
      {
        title: 'Listed Vehicles',
        route: `${constants.links.marketplaceBaseUrl}listed-vehicles/`,
        internal: false,
      },
      {
        title: 'Saved Vehicles',
        route: `${constants.links.marketplaceBaseUrl}showroom/`,
        internal: false,
      },
      {
        title: 'Saved Searches',
        route: `${constants.links.marketplaceBaseUrl}saved-searches/`,
        internal: false,
      },
    ],
  },
  {
    title: 'Marketplace',
    icon: (
      <Avatar
        src={MarketplaceMultiIcon}
        alt="Marketplace"
        variant="square"
        sx={{ width: 20, height: 20 }}
      />
    ),
    hasLink: false,
    link: null,
    hasSubMenu: true,
    subMenu: [
      {
        title: 'Buy',
        route: `${constants.links.marketplaceBaseUrl}`,
        internal: false,
      },
      {
        title: 'Sell',
        route: `${constants.links.marketplaceBaseUrl}sell-your-car`,
        internal: false,
      },
    ],
  },
  {
    title: 'Bumper API',
    icon: (
      <Box sx={{ fontSize: '24px', color: 'primary.dark' }}>
        <Code fontSize="inherit" color="inherit" />
      </Box>
    ),
    hasLink: true,
    link: {
      route: '/dashboard/api',
      internal: true,
    },
    hasSubMenu: false,
    subMenu: null,
  },
  {
    title: 'Chrome Extension',
    icon: (
      <Box sx={{ fontSize: '24px', color: 'primary.dark' }}>
        <ExtensionOutlined fontSize="inherit" color="inherit" />
      </Box>
    ),
    hasLink: true,
    link: {
      route:
        'https://chrome.google.com/webstore/detail/save-cars-across-many-sit/ddcabilfaldblnbjjdakdhdkggmncplh?source=rf',
      internal: false,
    },
    hasSubMenu: false,
    subMenu: null,
  },
  {
    title: 'Send Invite',
    icon: (
      <Box sx={{ fontSize: '24px', color: 'primary.dark' }}>
        <PersonAdd fontSize="inherit" color="inherit" />
      </Box>
    ),
    hasLink: true,
    link: {
      route: '/dashboard/referrals',
      internal: true,
    },
    hasSubMenu: false,
    subMenu: null,
  },
  {
    title: 'Logout',
    icon: (
      <Box sx={{ fontSize: '24px', color: 'primary.dark' }}>
        <Logout fontSize="inherit" color="inherit" />
      </Box>
    ),
    hasLink: false,
    link: null,
    hasSubMenu: false,
    subMenu: null,
    logout: true,
  },
];
