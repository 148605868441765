import { Card, Text, Button, Stack, Box } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { StyledApiFeatureBox } from './components';
import { Check } from '@mui/icons-material';

/**
 * Props for the ApiFeaturesCard component.
 *
 * @interface
 * @property {('pdf'|'json')} currentlySelectedPlanType - The currently selected plan type.
 * @property {(planType: 'pdf' | 'json') => void} handlePlanTypeChange - Callback function to handle plan type change.
 */
interface ApiFeaturesCardProps {
  currentlySelectedPlanType: 'pdf' | 'json';
  handlePlanTypeChange: (planType: 'pdf' | 'json') => void;
}

const featureSet = [
  'Accidents',
  'Sales listings',
  'Market Value',
  'Imports',
  'Salvage records',
  'Title History',
  'Trade-In Values',
  'Recalls',
  'Junk records',
  'Title Brands',
  'Liens',
  'Warranties',
  'Theft records',
  'NMVTIS Data',
  'Exports',
  'Specs',
];

const featuresThatShouldShowAsterisk = [
  'Salvage records',
  'Title History',
  'Junk records',
  'Title Brands',
  'NMVTIS Data',
];

/**
 * Builds an array of feature sets. Adds an asterisk to certain features if JSON is selected.
 *
 * @param {boolean} isJson - Determines if the selected plan type is JSON.
 * @returns {string[]} An array of feature set strings, with asterisks added as needed due to compliance.
 */
const buildFeatureSetArray = (isJson: boolean) => {
  return featureSet.map((feature) => {
    if (featuresThatShouldShowAsterisk.includes(feature) && isJson) {
      return `${feature}*`;
    }

    return feature;
  });
};

/**
 * React component for displaying API features.
 *
 * Depending on the selected plan type (PDF or JSON), this component displays
 * a list of features with specific annotations and information.
 *
 * @param {ApiFeaturesCardProps} props - The props for the component.
 * @returns {React.ReactElement} A React element representing the API features card.
 */
export default function ApiFeaturesCard({
  currentlySelectedPlanType,
  handlePlanTypeChange,
}: ApiFeaturesCardProps) {
  const { isTablet } = useScreenSize();
  const selectedPlanTypeIsJson = currentlySelectedPlanType === 'json';

  const featureSetWithAsterisks = buildFeatureSetArray(selectedPlanTypeIsJson);

  const title =
    currentlySelectedPlanType === 'pdf'
      ? 'Included in the PDF API report'
      : 'Included in Raw Data API';

  const handleClick = () => {
    handlePlanTypeChange('pdf');
  };

  return (
    <Box mt={4}>
      <Card variant="outlined">
        <Text variant="h2" textAlign="center">
          {title}
        </Text>
        <Stack direction="row" flexWrap="wrap">
          {featureSetWithAsterisks.map((feature) => (
            <Box
              key={`feature-${feature}`}
              width={isTablet ? '50%' : '25%'}
              display="flex"
              justifyContent="center"
            >
              <StyledApiFeatureBox>
                <Check fontSize="small" sx={{ color: '#8736C1' }} />
                <Text ml={1}>{feature}</Text>
              </StyledApiFeatureBox>
            </Box>
          ))}
        </Stack>
        <Box px={8} mt={2} textAlign="center">
          {selectedPlanTypeIsJson ? (
            <Text variant="body2">
              *This data must be used for internal purposes only and may not be
              resold or displayed to consumers. For consumer purposes, please
              use our
              <Text
                variant="body2"
                p={0}
                pb={0.5}
                sx={{ textDecoration: 'underline' }}
                component={Button}
                onClick={handleClick}
              >
                PDF API
              </Text>
              .
            </Text>
          ) : (
            <Text variant="body2">
              Bumper PDF API is intended to be used to generate downloadable PDF
              reports that include all the history sections of our vehicle
              history report. You can see an example by running a VIN report and
              clicking the PDF button in the action bar.
            </Text>
          )}
        </Box>
      </Card>
    </Box>
  );
}
