import { useContext, useState } from 'react';
import { Box, Stack } from '@ltvco/refresh-lib/theme';
import { AppConfig, AppConstants } from '@ltvco/refresh-lib/ctx';
import { OnSuccessCallback, SearchData } from '@ltvco/refresh-lib/v1';
import { AccountCircle } from '@mui/icons-material';
import { HeaderMenu } from '@ltvco/refresh-lib/v2';
import { useHeaderContext } from 'context/HeaderContext';
import { useHandleSearch } from 'hooks/useHandleSearch';
import type { HeaderItem, DropdownHeaderItemName } from '../types';
import { ClassicHeaderItem } from '../ClassicHeaderItem/ClassicHeaderItem';
import { HeaderMenuDropdown } from './HeaderMenuDropdown/HeaderMenuDropdown';
import { HeaderSearchDropdown } from './HeaderSearchDropdown/HeaderSearchDropdown';
import { HeaderSimpleMenu } from './HeaderSimpleMenu/HeaderSimpleMenu';

export const DesktopHeaderNav = () => {
  const { currentDropdown, handleSetCurrentDropdown } = useHeaderContext();
  const [anchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { handleSearchSuccess } = useHandleSearch();

  const {
    links: { marketplaceBaseUrl },
  } = useContext(AppConstants);

  const handleClose = () => {
    handleSetCurrentDropdown(null);
  };

  const onSearchSuccess: OnSuccessCallback = (searchPayload: SearchData) => {
    handleSearchSuccess && handleSearchSuccess(searchPayload);
    handleSetCurrentDropdown(null);
  };

  const headerItems: HeaderItem[] = [
    {
      visibleText: 'History Reports',
      componentToDisplayWhenOpen: (
        <HeaderSearchDropdown
          name={'search'}
          onSearchSuccess={onSearchSuccess}
          id="search-dropdown"
          handleClose={handleClose}
          anchorEl={anchorEl}
          setMenuAnchorEl={setMenuAnchorEl}
        />
      ),
      eventName: 'openVehicleSearch',
      name: 'search',
      eventLabel: 'Search',
    },
    {
      visibleText: 'Buy',
      url: marketplaceBaseUrl,
      name: 'buy',
      eventLabel: 'Buy',
    },
    {
      visibleText: 'Sell',
      url: `${marketplaceBaseUrl}sell-your-car`,
      name: 'sell',
      eventLabel: 'Sell',
    },
    {
      visibleText: 'My Bumper',
      componentToDisplayWhenOpen: (
        <HeaderSimpleMenu
          name="myBumper"
          id="my-bumper-dropdown"
          anchorEl={anchorEl}
          handleClose={handleClose}
          listItems={[
            {
              url: '/dashboard/home',
              visibleText: 'My Bumper',
              openInNewTab: false,
              eventLabel: 'My Bumper',
            },
            {
              url: `${marketplaceBaseUrl}listed-vehicles`,
              visibleText: 'Listed Vehicles',
              openInNewTab: true,
              eventLabel: 'My Bumper',
            },
            {
              url: `${marketplaceBaseUrl}showroom`,
              visibleText: 'Saved Vehicles',
              openInNewTab: true,
              eventLabel: 'My Bumper',
            },
            {
              url: `${marketplaceBaseUrl}saved-searches`,
              visibleText: 'Saved Searches',
              openInNewTab: true,
              eventLabel: 'My Bumper',
            },
          ]}
        />
      ),
      eventName: 'openMyBumperDropdown',
      name: 'myBumper',
      eventLabel: 'My Bumper',
    },
    {
      visibleText: 'Tools',
      componentToDisplayWhenOpen: (
        <HeaderSimpleMenu
          name="tools"
          id="tools-dropdown"
          anchorEl={anchorEl}
          handleClose={handleClose}
          listItems={[
            {
              url: 'https://www.bumper.com/tools/compare-cars/',
              visibleText: 'Compare vehicles',
              openInNewTab: true,
              eventLabel: 'Tools',
            },
            {
              url: 'https://www.bumper.com/tools/market-value/',
              visibleText: 'Market value',
              openInNewTab: true,
              eventLabel: 'Tools',
            },
            {
              url: 'https://www.bumper.com/blog/',
              visibleText: 'Helpful articles',
              openInNewTab: true,
              eventLabel: 'Tools',
            },
          ]}
        />
      ),
      eventName: 'openToolsDropdown',
      name: 'tools',
      eventLabel: 'Tools',
    },
    {
      visibleText: 'Rewards',
      route: '/dashboard/rewards',
      name: 'rewards',
      eventLabel: 'Rewards',
    },
    {
      icon: <AccountCircle sx={{ color: '#212121' }} />,
      componentToDisplayWhenOpen: (
        <HeaderMenuDropdown
          name="menu"
          handleClose={handleClose}
          id="header-menu"
        >
          <HeaderMenu
            open={currentDropdown === 'menu'}
            handleClose={handleClose}
            showCustomSideMenu
            customStyle={{
              minWidth: '284px',
            }}
          />
        </HeaderMenuDropdown>
      ),
      eventName: 'openMenuDropdown',
      name: 'menu',
      eventLabel: 'Avatar',
    },
  ];

  return (
    <Stack direction={'row'}>
      {headerItems.map((headerItem, index) => {
        return (
          <NewComponent
            item={headerItem}
            setMenuAnchorEl={setMenuAnchorEl}
            key={index}
          />
        );
      })}
    </Stack>
  );
};

interface NewComponentProps {
  item: HeaderItem;
  setMenuAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

const NewComponent = ({ item, setMenuAnchorEl }: NewComponentProps) => {
  const { trackEventGA4 } = useContext(AppConfig);
  const { currentDropdown, handleSetCurrentDropdown } = useHeaderContext();

  const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    trackEventGA4({
      event_name: 'header_engagement',
      type: `nav - ${item.eventLabel}`.toLowerCase(),
    });
    if (item.eventName) {
      if (!currentDropdown || currentDropdown !== item.name) {
        setMenuAnchorEl(event.currentTarget);
        handleSetCurrentDropdown(item.name as DropdownHeaderItemName);
      } else {
        setMenuAnchorEl(null);
        handleSetCurrentDropdown(null);
      }
    }
  };

  return (
    <Box id={`header-nav-item-${item.eventLabel}`}>
      <ClassicHeaderItem item={item} onClickHandler={onClickHandler} />
      {item.componentToDisplayWhenOpen}
    </Box>
  );
};
