import { useEffect } from 'react';
import { Text, Box } from '@ltvco/refresh-lib/theme';
import { useCurrentUserApiAccess } from '@ltvco/refresh-lib/v1';
import { Code } from '@mui/icons-material';
import { ApiGatedPage } from '../../../layouts/ApiGatedPage/ApiGatedPage';
import { ApiNonGatedPage } from '../../../layouts/ApiNonGatedPage/ApiNonGatedPage';

/**
 * React component for the main API page.
 *
 * This component determines which API page to display based on the current user's
 * API access status. It renders either the ApiGatedPage or the ApiNonGatedPage
 * depending on whether the user has API access. Additionally, it sets the
 * document title to 'Bumper API - Bumper'.
 *
 * @returns {React.ReactElement} A React element representing the main API page.
 */
export function ApiPage() {
  const { currentUserHasApiAccess } = useCurrentUserApiAccess();

  useEffect(() => {
    document.title = 'Bumper API - Bumper';
  }, []);

  return (
    <Box>
      <Text variant="h1" display="flex" alignItems="center">
        <Code fontSize="large" sx={{ mr: 2, color: '#212121' }} />
        Bumper API
      </Text>
      {currentUserHasApiAccess() ? <ApiNonGatedPage /> : <ApiGatedPage />}
    </Box>
  );
}
