import { Button } from '@ltvco/refresh-lib/theme';
import { PlanTypeName } from '../PlanTypeSelector';

interface PlanTypeSelectorProps {
  planType: PlanTypeName;
  selectedPlanType: string;
  setSelectedPlanType: (planType: string) => void;
}

/**
 * `PlanSelectorButton` is a React component that renders a button for selecting a plan type.
 *
 * @component
 * @param {Object} props - The component's properties.
 * @param {PlanTypeName} props.planType - An object representing the plan type, containing both internal and public names.
 * @param {string} props.selectedPlanType - The currently selected plan type (internal name).
 * @param {function} props.setSelectedPlanType - A function to update the selected plan type.
 *
 * @example
 * <PlanSelectorButton
 *   planType={{ internal: 'basic', public: 'Basic Plan' }}
 *   selectedPlanType="basic"
 *   setSelectedPlanType={setSelectedPlanType}
 * />
 */

export default function PlanSelectorButton({
  planType,
  selectedPlanType,
  setSelectedPlanType,
}: PlanTypeSelectorProps) {
  const handleClick = () => {
    setSelectedPlanType(planType.internal);
  };

  return (
    <Button
      key={planType.internal}
      variant="contained"
      color={planType.internal === selectedPlanType ? 'success' : 'secondary'}
      onClick={handleClick}
    >
      {planType.public}
    </Button>
  );
}
