import { Button, Stack, styled } from '@ltvco/refresh-lib/theme';
import { ArrowBackIosNew } from '@mui/icons-material';
import { MiscellaneousCard } from 'utils/constants/selfInspectionQuestionnaire';
import { DataCard } from './DataCard/DataCard';
import { LastScreenDataCard } from './LastScreenDataCard/LastScreenDataCard';

interface MiscScreenProps {
  cardData: MiscellaneousCard;
  actionHandlers: {
    next: () => void;
    back: () => void;
    exit: () => void;
    complete: () => void;
    update: () => void;
    viewInspection: () => void;
  };
  isLastScreen: boolean;
}

const StyledMiscScreenContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
  alignItems: 'center',
}));

export function Miscellaneous({
  cardData,
  actionHandlers,
  isLastScreen,
}: MiscScreenProps) {
  const handlePrimaryAction = () => {
    actionHandlers[
      cardData.primaryAction.action as keyof typeof actionHandlers
    ]();
  };

  const handleSecondaryAction = () => {
    actionHandlers[
      cardData?.secondaryAction?.action as keyof typeof actionHandlers
    ]();
  };

  return (
    <StyledMiscScreenContainer>
      {cardData.hasBack && (
        <Button
          sx={{ alignSelf: 'flex-start', fontSize: '.875rem' }}
          onClick={actionHandlers.back}
        >
          <ArrowBackIosNew fontSize="inherit" /> Back
        </Button>
      )}
      {cardData.cardInfo.icon && (
        <img
          src={cardData.cardInfo.icon}
          alt="miscellaneous-icon"
          width="288px"
          height="auto"
        />
      )}
      {isLastScreen ? (
        <LastScreenDataCard cardData={cardData} />
      ) : (
        <DataCard cardData={cardData} />
      )}
      <Stack width="100%" mt="auto" spacing={2.5}>
        <Button
          color="success"
          variant="contained"
          fullWidth
          onClick={handlePrimaryAction}
        >
          {cardData.primaryAction.text}
        </Button>
        {Boolean(cardData.secondaryAction) && (
          <Button variant="text" fullWidth onClick={handleSecondaryAction}>
            {cardData?.secondaryAction?.text}
          </Button>
        )}
      </Stack>
    </StyledMiscScreenContainer>
  );
}
