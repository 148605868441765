export const DallasZips: string[] = [
  '75001',
  '75002',
  '75006',
  '75007',
  '75010',
  '75013',
  '75019',
  '75022',
  '75023',
  '75024',
  '75025',
  '75028',
  '75034',
  '75035',
  '75038',
  '75039',
  '75040',
  '75041',
  '75042',
  '75043',
  '75044',
  '75048',
  '75050',
  '75051',
  '75052',
  '75054',
  '75056',
  '75057',
  '75060',
  '75061',
  '75062',
  '75063',
  '75065',
  '75067',
  '75068',
  '75069',
  '75070',
  '75071',
  '75074',
  '75075',
  '75077',
  '75080',
  '75081',
  '75082',
  '75087',
  '75088',
  '75089',
  '75093',
  '75094',
  '75098',
  '75104',
  '75115',
  '75116',
  '75134',
  '75137',
  '75141',
  '75146',
  '75149',
  '75150',
  '75180',
  '75181',
  '75182',
  '75201',
  '75202',
  '75203',
  '75204',
  '75205',
  '75206',
  '75207',
  '75208',
  '75209',
  '75210',
  '75211',
  '75212',
  '75214',
  '75215',
  '75216',
  '75217',
  '75218',
  '75219',
  '75220',
  '75223',
  '75224',
  '75225',
  '75226',
  '75227',
  '75228',
  '75229',
  '75230',
  '75231',
  '75232',
  '75233',
  '75234',
  '75235',
  '75236',
  '75237',
  '75238',
  '75240',
  '75241',
  '75243',
  '75244',
  '75246',
  '75247',
  '75248',
  '75249',
  '75251',
  '75252',
  '75253',
  '75254',
  '75287',
  '75407',
  '75442',
  '76001',
  '76002',
  '76006',
  '76010',
  '76011',
  '76012',
  '76013',
  '76014',
  '76015',
  '76016',
  '76017',
  '76018',
  '76021',
  '76022',
  '76034',
  '76039',
  '76040',
  '76051',
  '76052',
  '76053',
  '76054',
  '76060',
  '76063',
  '76092',
  '76102',
  '76103',
  '76104',
  '76105',
  '76106',
  '76107',
  '76110',
  '76111',
  '76112',
  '76115',
  '76117',
  '76118',
  '76119',
  '76120',
  '76129',
  '76131',
  '76137',
  '76140',
  '76148',
  '76155',
  '76164',
  '76177',
  '76180',
  '76182',
  '76244',
  '76248',
  '76262',
  '75270',
];
