import { useState } from 'react';
import { Box, Stack } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { BPReward, CarwiserModal, useRewards } from '@ltvco/refresh-lib/v1';
import { PrimaryReward } from './PrimaryReward';
import { SecondaryReward } from './SecondaryReward';

export const PremiumUserRewardContainer = () => {
  const [carwiserModalIsOpen, setCarwiserModalIsOpen] = useState(false);
  const { data } = useRewards();
  const { isTablet } = useScreenSize();

  const primaryReward = data?.rewards?.[0];
  const secondaryRewardList = data?.rewards?.slice(1, 5);

  const handleOpenReward = (reward: BPReward) => {
    if (reward.name === 'carwiser') {
      setCarwiserModalIsOpen(true);
      return;
    }

    window.open(reward.link, '_blank');
  };

  return (
    <Box>
      <Stack
        flexDirection={isTablet ? 'column' : 'row'}
        justifyContent="space-between"
        columnGap={2}
      >
        {primaryReward && (
          <PrimaryReward
            reward={data.rewards[0] as BPReward}
            handleOpenReward={handleOpenReward}
          />
        )}
        <Stack
          flexDirection="row"
          flexWrap="wrap"
          rowGap={1.5}
          justifyContent="space-between"
          width={isTablet ? '100%' : 'calc(50% - 10px)'}
        >
          {secondaryRewardList?.map((reward) => (
            <SecondaryReward
              key={reward.name}
              reward={reward as BPReward}
              handleOpenReward={handleOpenReward}
            />
          ))}
        </Stack>
      </Stack>
      {carwiserModalIsOpen && (
        <CarwiserModal setIsOpen={setCarwiserModalIsOpen} />
      )}
    </Box>
  );
};
