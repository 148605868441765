import { commaFormat } from '@ltvco/refresh-lib/utils';
import { HardcodedPlan } from '@ltvco/refresh-lib/v1';

// Due to the inconsistencies on the HardcodedPlan interface, we need to format the data to be used by the lib
// This should be able to be removed once we no longer use the HardcodedPlan interface
export const formatForLib = (data: HardcodedPlan[]) => {
  const plans = data.map((plan) => {
    return {
      ...plan,
      key: plan.unique_key,
      amount: plan.amount / 100,
      renewalPeriod: plan.renewal_period,
      limit:
        plan.plan_type === 'json'
          ? commaFormat(plan.credits)
          : plan.monthly_report_limit,
      creditBasedPlan: plan.plan_type === 'json',
      savings: plan.discount ? parseInt(plan?.discount?.split(' ')[1]) : null,
      costPerReport: plan?.costPerCredit
        ? plan?.costPerCredit
        : plan?.price_per_report
        ? plan?.price_per_report / 100
        : 0,
    };
  });

  return plans;
};
