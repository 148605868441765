import {
  Box,
  Card,
  Text,
  PureTextButton,
  styled,
} from '@ltvco/refresh-lib/theme';
import { AppConstants } from '@ltvco/refresh-lib/ctx';
import { useContext } from 'react';

interface ArticlesContainerProps {
  children: JSX.Element | JSX.Element[];
}

export const UnderlineArticlesButton = styled(PureTextButton)(() => ({
  fontSize: '0.875rem',
  padding: '0',

  ':hover': {
    textDecoration: 'underline',
  },
}));

export const ArticlesContainer = ({ children }: ArticlesContainerProps) => {
  const {
    links: { blogLink },
  } = useContext(AppConstants);

  const openBlogLink = () => {
    window.open(blogLink, '_blank');
  };

  return (
    <Card>
      {children}
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        mt={1.5}
      >
        <Text textAlign="center">Want to learn even more?</Text>
        <UnderlineArticlesButton onClick={openBlogLink}>
          View Bumper Blog
        </UnderlineArticlesButton>
      </Box>
    </Card>
  );
};
