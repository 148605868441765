import { CustomIcons } from '@ltvco/refresh-lib/theme';
import {
  Code,
  DirectionsCar,
  Facebook,
  Instagram,
  LinkedIn,
  Sell,
  Pinterest,
  Redeem,
  Summarize,
  Restore,
  List,
  NotificationsNone,
  CompareArrows,
  AccountCircle,
  PersonAdd,
  ManageAccounts,
  Home,
} from '@mui/icons-material';
import { type ReportTypeOption } from '@ltvco/refresh-lib/v1';
import ComplianceLogo from './images/img-alert.svg';
import SearchLogo from './images/search-logo.svg';
import accidentsCoverageMapImage from './images/accident_coverage_map.png';
import american_express from './images/img_amex.svg';
import applePay from './images/img_apple_pay.svg';
import discover from './images/img_discover.svg';
import facebookLogo from './images/img_social_facebook.svg';
import facebookTwitter from './images/img_social_twitter.svg';
import firstRewardBanner from './images/sirius_xm_banner.png';
import genericCreditCard from './images/img_generic_credit_card.svg';
import googlePay from './images/img_google_pay.svg';
import img24hr from './images/img_24hr.svg';
import imgAddVehicle from './images/add-vehicle.svg';
import imgAddressGeneric from './images/img_address_generic_no_bg.svg';
import imgAmenities from './images/img_amenities.svg';
import imgAnimatedPersonMagnifying from './images/ani_person_magnifying.gif';
import imgAppStoreBadge from './images/img_apple_app_store_badge.svg';
import imgAreaPin from './images/img_areapin.svg';
import imgAuctioneersIcon from './images/img_auctioneers_icon.svg';
import imgBellCurve from 'images/bell_curve.png';
import imgBellMonitoring from './images/img_bell_monitoring.svg';
import imgCampaign from './images/campaign.svg';
import imgCheckIcon from './images/img-check-icon.svg';
import imgCheckList from './images/icn_img_checklist.svg';
import imgCircleUser from './images/img_circle_user.svg';
import imgClock from './images/clock.svg';
import imgCloseIcon from './images/img-close-icon.svg';
import imgComputerLimitReached from './images/img_computer_limit_reached.svg';
import imgContactHours from './images/img_contact_hours.svg';
import imgContactlessIcon from './images/img_contactless_icon.svg';
import imgCopyIcon from './images/img_copy_icon.svg';
import imgCustomerService from './images/img_customer_service.svg';
import imgDarkWebEmail from './images/img-dark-web-email.svg';
import imgDarkWebScan from './images/img_dark_web_scan.svg';
import imgDealersIcon from './images/img_dealers_icon.svg';
import imgDollar from './images/dollar.svg';
import imgEmission from './images/emission.svg';
import imgEtagsLogo from './images/img_etags_logo.svg';
import imgExampleAircraft from './images/img_example_aircraft.svg';
import imgExampleAutomobile from './images/img_example_automobile.svg';
import imgExampleWatercraft from './images/img_example_watercraft.svg';
import imgExpiredEmailLink from './images/img_expired_email_link.svg';
import imgFallbackSexOffender from './images/img_placeholder_sex_offender.svg';
import imgFleetOwnersIcon from './images/img_fleet_owners_icon.svg';
import imgGooglePlayBadge from './images/img_badge_google_play.svg';
import imgHeadset from './images/img_headset.svg';
import imgIdHub from './images/id-hub.svg';
import imgMagnifyingGlassNoResultsPage from './images/img_magnifying_glass_no_results.svg';
import imgMapBoxPin from './images/img_pin.svg';
import imgMarketTrends from './images/img_market_trends.svg';
import imgMobileApp from './images/img_mobile_app.svg';
import imgMobileExpIcon from './images/img_mobile_exp_icon.svg';
import imgMobileFreemium from './images/img_mobile_freemium.svg';
import imgMonitorBanner from './images/img_neighborhood_safety.svg';
import imgMonitoringBell from './images/img_bell_monitoring.svg';
import imgMyGarageBrake from './images/img_mygarage_brake.svg';
import imgMyGarageOilChange from './images/img_mygarage_oilchange.svg';
import imgMyGarageSellYourCar from './images/img_mygarage_sellyourcar.svg';
import imgMyGarageStar from './images/img_mygarage_starfilled.svg';
import imgMyGarageTireChange from './images/img_mygarage_tirechange.svg';
import imgMyGarageTitleCheck from './images/img_mygarage_titlecheck.svg';
import imgNHFacts from './images/img_neighborhood_facts.svg';
import imgNMVTISLogo from './images/img_nmvtis_logo.svg';
import imgNoProperty from './images/no-property-img-fallback.svg';
import imgNoVehicleImageProvided from './images/no-vehicle-image-provided.svg';
import imgOnlineMarketplaceIcon from './images/img_online_marketplace_icon.svg';
import img_pdf_glow from './images/img_pdf_glow.svg';
import imgPremiumBadge from './images/img_premium_badge.svg';
import imgPremiumFrame from './images/img-premium-frame.svg';
import imgPremiumSearchIcon from './images/img-premium-search-icon.svg';
import imgRadialCar from './images/img-radial-car.svg';
import imgReferralsIcon from './images/img_referrals_icon.svg';
import imgReportChecked from './images/img_report_checked_logo.svg';
import imgReportRating from './images/img_report_rating.svg';
import imgSafety from './images/safety.svg';
import imgSearch from './images/img_search.svg';
import imgServiceCenterIcon from './images/img_service_center_icon.svg';
import imgSeverityCritical from './images/severity-critical.svg';
import imgSeverityHigh from './images/severity-high.svg';
import imgSeverityLow from './images/severity-low.svg';
import imgSeverityMedium from './images/severity-medium.svg';
import imgSeverityNone from './images/severity-none.svg';
import imgSingleSignOnCta from './images/img_sso_cta.svg';
import imgSpecializedBackgroundCheck from './images/specialized-background-check.svg';
import imgSpecializedUnclaimedMoney from './images/specialized-unclaimed-money.svg';
import imgSuccessfulUpgrade from './images/img_successful_upgrade.svg';
import imgUpdatesIcon from './images/img_updates_icon.svg';
import imgVerticalArrow from 'images/vertical_arrow.png';
import imgMigrationCta from 'images/img_migration_cta.svg';
import imgWhatIsAVin from './images/img_what_is_a_vin.svg';
import imgWhatIsAVinV2 from './images/img_what_is_a_vin_v2.svg';
import imgWhereIsVin from './images/img_where_is_vin.svg';
import imgWhereIsVinV2 from './images/img_where_is_vin_v2.svg';
import instagramLogo from './images/img_social_instagram.svg';
import linkedinLogo from './images/img_social_linkedin.svg';
import logo from './images/logo.svg';
import logoSmall from './images/logo-small.svg';
import masterCard from './images/img_mastercard.svg';
import myGarageCtaImage from './images/my_garage_cta_image.svg';
import neighborSpoofing from './images/neighbor-spoofing.svg';
import paymentRequired from './images/img_payment_required.svg';
import paypal from './images/img_paypal.svg';
import pinterestLogo from './images/img_social_pinterest.svg';
import rewardsBannerDesktop from './images/rewards_banner_desktop.png';
import rewardsBannerMobile from './images/rewards_banner_mobile.png';
import rewardsCtaImage from './images/rewards_cta_image.svg';
import venmo from './images/img_venmo.svg';
import venmoButton from './images/img_venmo_button.svg';
import visa from './images/img_visa.svg';
import { BPLogo } from 'icons';

const baseUrl = `${window.location.protocol}//${window.location.host}`;
const reportTypeFilterOptions: { [key: string]: ReportTypeOption } = {
  vehicle_report: {
    id: 'vehicle_report',
    display: 'Vehicle',
    eventLabel: 'Vehicle',
  },
};

// TODO: should we extend the default config the same way we're doing with the theme? might be good for some props, other props maybe should be required
export const constants = {
  images: {
    brandLogo: logo,
    brandLogoMobile: logoSmall,
    master: masterCard,
    visa: visa,
    american_express: american_express,
    discover: discover,
    genericCreditCard: genericCreditCard,
    paypal: paypal,
    googlePay: googlePay,
    paymentRequired: paymentRequired,
    applePay: applePay,
    venmo: venmo,
    venmoButton: venmoButton,
    searchLogo: SearchLogo,
    complianceLogo: ComplianceLogo,
    imgDarkWebEmail: imgDarkWebEmail,
    imgNoProperty,
    imgExampleAircraft: imgExampleAircraft,
    imgExampleWatercraft: imgExampleWatercraft,
    imgExampleAutomobile: imgExampleAutomobile,
    monitoringBell: imgMonitoringBell,
    imgIdHub,
    imgAddressGeneric,
    imgDarkWebScan,
    imgSpecializedBackgroundCheck,
    imgSpecializedUnclaimedMoney,
    imgReportRating: imgReportRating,
    imgSuccessfulUpgrade: imgSuccessfulUpgrade,
    imgComputerLimitReached: imgComputerLimitReached,
    pdfLogo: img_pdf_glow,
    img24hr,
    neighborSpoofing,
    imgAnimatedPersonMagnifying,
    imgMagnifyingGlassNoResultsPage: imgMagnifyingGlassNoResultsPage,
    imgMonitorBanner: imgMonitorBanner,
    imgMobileExpIcon: imgMobileExpIcon,
    imgCopyIcon: imgCopyIcon,
    imgContactlessIcon: imgContactlessIcon,
    imgUpdatesIcon: imgUpdatesIcon,
    imgEtagsLogo: imgEtagsLogo,
    imgAddVehicle: imgAddVehicle,
    imgNHFacts: imgNHFacts,
    imgMarketTrends: imgMarketTrends,
    imgAmenities: imgAmenities,
    imgWhatIsAVin: imgWhatIsAVin,
    imgWhatIsAVinV2: imgWhatIsAVinV2,
    imgWhereIsVin: imgWhereIsVin,
    imgWhereIsVinV2: imgWhereIsVinV2,
    imgCheckList,
    imgAppStoreBadge,
    imgGooglePlayBadge,
    imgMobileFreemium,
    ownershipHistoryImages: {
      imgCircleUser,
    },
    recallImages: {
      imgSeverityCritical,
      imgSeverityHigh,
      imgSeverityLow,
      imgSeverityMedium,
      imgSeverityNone,
      imgClock,
      imgDollar,
      imgEmission,
      imgSafety,
      imgCampaign,
    },
    imgPremiumBadge,
    accidentsCoverageMapImage,
    rewardsBannerMobile,
    rewardsBannerDesktop,
    myGarageCtaImage,
    rewardsCtaImage,
    imgFallbackSexOffender,
    imgMapBoxPin,
    imgAreaPin,
    imgReferralsIcon,
    imgCustomerService,
    imgExpiredEmailLink,
    imgNoVehicleImageProvided,
    imgMyGarage: {
      imgMyGarageBrake: imgMyGarageBrake,
      imgMyGarageOilChange: imgMyGarageOilChange,
      imgMyGarageSellYourCar: imgMyGarageSellYourCar,
      imgMyGarageStar: imgMyGarageStar,
      imgMyGarageTireChange: imgMyGarageTireChange,
      imgMyGarageTitleCheck: imgMyGarageTitleCheck,
    },
    nmvtisLogo: imgNMVTISLogo,
    imgSearch,
    imgContactHours,
    imgHeadset,
    imgBellMonitoring,
    imgMobileApp,
    instructionalVideoMobile: 'https://via.placeholder.com/80x40',
    instructionalVideoDesktop: 'https://via.placeholder.com/80x40',
    imgReportChecked,
    firstRewardBanner,
    imgApiSolutions: {
      imgOnlineMarketplaceIcon,
      imgDealersIcon,
      imgServiceCenterIcon,
      imgFleetOwnersIcon,
      imgAuctioneersIcon,
    },
    imgRadialCar,
    imgPremiumData: {
      imgPremiumFrame,
      imgPremiumSearchIcon,
      imgCheckIcon,
      imgCloseIcon,
    },
    imgSingleSignOnCta,
    imgBellCurve,
    imgVerticalArrow,
    imgMigrationCta,
  },
  links: {
    baseUrl: `${baseUrl}${
      import.meta.env.VITE_PATH_PREFIX?.includes('/')
        ? import.meta.env.VITE_PATH_PREFIX
        : `/${import.meta.env.VITE_PATH_PREFIX}`
    }`,
    joinLink: 'https://www.bumper.com/lp/114010/4/subscribe',
    brandLink: import.meta.env.VITE_DEV_PROXY_SEARCH_PERSON_TEASER
      ? ''
      : `${baseUrl}`,
    aboutLink: 'https://www.bumper.com/about/',
    fcraLink: '',
    doDontsLink: '',
    tosLink: 'https://www.bumper.com/terms/',
    privacyLink: 'https://www.bumper.com/privacy-policy/',
    contactLink: 'https://www.bumper.com/contact/',
    FaqLink: '',
    dontSellInfoLink: 'https://www.bumper.com/app/optout/search',
    affiliateLink: 'https://www.bumper.com/affiliates',
    peopleSearchLink: '',
    reversePhoneLink: '',
    emailLookupLink: '',
    reverseAddressLink: '',
    contactForm: 'https://www.bumper.com/contact/',
    supportLink: 'https://support.bumper.com',
    twitterLink: 'https://x.com/bumperdotcom',
    facebookLink: 'https://www.facebook.com/Bumperdotcom',
    blogLink: 'https://www.bumper.com/blog/',
    brandIosUrl: 'https://bumperapp.onelink.me/Cl4S/e7cbad2',
    brandAndroidUrl: 'https://play.google.com/store/apps/details?id=com.bumper',
    rapidsLookupLink: `${baseUrl}/rpds/lookup`,
    etagsPartnerUrl: 'https://www.etags.com/app/us?whitelabel=bumper',
    mrRogersBaseUrl: '/ss/mrg',
    billingLink: 'https://support.bumper.com/hc/en-us',
    customPlanLink: 'https://forms.gle/MjUT6rfPWGh9NCTb7',
    mapboxStyleUrl: '',
    dataDeckRootURI: 'hk/dd/v2',
    marketplaceBaseUrl: 'https://www.bumper.com/marketplace/',
    accidentDisclaimerURL:
      'https://support.bumper.com/hc/en-us/articles/12017018227867',
    chatLink: 'https://www.bumper.com/chat/',
    vehicleReportInquiryURL:
      'https://www.bumper.com/contact/vehicle-history-report-inquiry/',
    nmvtisGlossaryURL: 'https://vehiclehistory.bja.ojp.gov/nmvtis_glossary',
    nmvtisStatesURL: 'https://vehiclehistory.bja.ojp.gov/nmvtis_states',
    nmvtisDataProvidersURL:
      'https://vehiclehistory.bja.ojp.gov/nmvtis_vehiclehistory',
    nmvtisVehicleHistoryURL: 'http://vehiclehistory.bja.ojp.gov/',
    topHapBaseUrl: 'https://widgets.tophap.com/api/widgets',
    fairCreditReportingActLink: '',
    partnerProgramLink: 'https://forms.gle/MsshKYQfoMsdmP459',
    reactivateLink: 'https://bumper.com/rf/reactivation',
    setupSsoLink: 'https://www.bumper.com/sso_connections',
  },
  featureFlags: {
    hasCriminalEnabled: false,
    hasVINScannerEnabled: true,
    hasSmartInsightsAutoLoadEnabled: true,
    propertyReportFlags: ['include_ca_data'],
    notificationsDisabled: true,
    hasReportPreferencesEnabled: true,
  },
  data: {
    brandName: 'Bumper',
    brandAbbreviation: 'bp' as 'bp',
    // we need to send it empty locally to enable the search person teaser proxy
    brandSupportEmail: 'support@bumper.com',
    legalName: 'Bumper, LLC',
    supportPhone: '1-332-225-9745',
    customerSupportPhone: '1-888-579-5910',
    supportEmail: 'support@bumper.com',
    complianceEmail: 'compliance@bumper.com',
    pdfPrice: '$4.95',
    rewardsDisclaimer:
      'Certain products and services, including vehicle purchase/sale opportunities, provided above include offers from third parties and/or external partners from whom we may receive limited compensation. Please be advised that these offers are provided as a convenience and are a sample, but do not necessarily include ALL offers, products or services available in the marketplace.',
    rewardsDescription:
      "As a Bumper member, you can take advantage of benefits and perks that can help you save time and money throughout your car buying, owning and selling journey. With Bumper, find awesome savings for all your vehicle needs: from used car pre-purchase inspections to auto insurance, even when you need service or maintenance. Check back often as we add more savings - we're just getting started!",
    searchTabsReplacementText: 'History Reports',
    carGeniusPhone: '1-877-313-2578',
    copyrightText:
      '© 2024 Bumper, LLC. All rights reserved. Bumper.com and the Bumper logo are registered trademarks of Bumper, LLC',
    defaultSearchFormEventName: 'openVehicleSearch',
  },
  config: {
    socialMedia: [
      {
        textContent: 'Twitter',
        alternateTextContent: '@bumperdotcom',
        href: 'https://x.com/bumperdotcom',
        imgLogoUrl: facebookTwitter,
        icon: <CustomIcons.TwitterIcon sx={{ color: '#212121' }} />,
        hideOnSide: false,
      },
      {
        textContent: 'Facebook',
        alternateTextContent: 'facebook.com/bumperdotcom',
        href: 'https://www.facebook.com/bumperdotcom',
        imgLogoUrl: facebookLogo,
        icon: <Facebook fontSize="small" sx={{ color: '#212121' }} />,
        hideOnSide: false,
      },
      {
        textContent: 'Instagram',
        alternateTextContent: 'instagram.com/bumperdotcom',
        href: 'https://www.instagram.com/bumperdotcom',
        imgLogoUrl: instagramLogo,
        icon: <Instagram fontSize="small" sx={{ color: '#212121' }} />,
        hideOnSide: false,
      },
      {
        textContent: 'Linkedin',
        href: 'https://www.linkedin.com/company/bumperdotcom',
        imgLogoUrl: linkedinLogo,
        icon: <LinkedIn fontSize="small" sx={{ color: '#212121' }} />,
        hideOnSide: true,
      },
      {
        textContent: 'Pinterest',
        href: 'https://www.pinterest.com/bumperdotcom',
        imgLogoUrl: pinterestLogo,
        icon: <Pinterest fontSize="small" sx={{ color: '#212121' }} />,
        hideOnSide: true,
      },
      {
        textContent: 'Bumper Blog',
        href: 'https://www.bumper.com/blog/',
        imgLogoUrl: logoSmall,
        icon: <BPLogo fontSize="medium" color="primary" />,
        hideOnSide: true,
      },
    ],
    searchTabs: [
      {
        displayName: 'Vehicle',
        id: 'vehicleTab',
        icon: <DirectionsCar fontSize="small" sx={{ color: '#212121' }} />,
        class: '',
      },
    ],
    sideMenuItems: [
      {
        route: 'https://www.bumper.com/marketplace/',
        icon: <Sell sx={{ color: '#212121' }} />,
        text: 'Marketplace',
        state: '',
        name: 'marketplace',
        openInNewTab: true,
        'data-cy': 'marketplace',
        ga4EventType: 'marketplace',
      },
      {
        route: '/dashboard/home',
        icon: <Home sx={{ color: '#212121', fontSize: '24px' }} />,
        text: 'My Bumper',
        name: 'myBumper',
        state: '',
        'data-cy': 'my_bumper',
      },
      {
        route: '/dashboard/rewards',
        icon: <Redeem sx={{ color: '#212121', fontSize: '24px' }} />,
        text: 'Rewards',
        state: '',
        name: 'rewards',
      },
      {
        route: '',
        icon: <Summarize sx={{ color: '#212121', fontSize: '24px' }} />,
        text: 'Reports',
        name: 'reports',
        state: '',
        'data-cy': 'reports',
        childItems: [
          {
            route: '/dashboard/reports',
            icon: <Restore sx={{ color: '#212121', fontSize: '24px' }} />,
            text: 'Recent Reports',
            name: 'recentReports',
            state: '',
            'data-cy': 'recent_reports',
          },
          {
            route: '/dashboard/lists',
            icon: <List sx={{ color: '#212121', fontSize: '24px' }} />,
            text: 'Saved Reports and Lists',
            state: '',
            name: 'lists',
            'data-cy': 'saved_reports_and_list',
          },
        ],
      },
      {
        route: '/dashboard/monitoring',
        icon: <NotificationsNone sx={{ color: '#212121', fontSize: '24px' }} />,
        text: 'Monitoring',
        state: '',
        name: 'monitoring',
      },
      {
        route: '/dashboard/api',
        icon: <Code sx={{ color: '#212121' }} />,
        text: 'Bumper API',
        state: '',
        name: 'api',
      },
      {
        route:
          'https://chrome.google.com/webstore/detail/save-cars-across-many-sit/ddcabilfaldblnbjjdakdhdkggmncplh?source=rf',
        icon: <CustomIcons.GoogleChromeIcon width="24px" height="24px" />,
        text: 'Chrome Extension',
        state: '',
        name: 'chromeExtension',
        'data-cy': 'chrome_extension',
        openInNewTab: true,
      },
      {
        route: 'https://www.bumper.com/tools/compare-cars/',
        icon: <CompareArrows sx={{ color: '#212121' }} />,
        text: 'Compare Vehicles',
        state: '',
        name: 'compare',
        openInNewTab: true,
      },
      {
        route: '',
        icon: <ManageAccounts sx={{ color: '#212121', fontSize: '24px' }} />,
        text: 'My Profile',
        state: '',
        name: 'my-profile',
        childItems: [
          {
            route: '/dashboard/billing',
            icon: <AccountCircle sx={{ color: '#212121', fontSize: '24px' }} />,
            text: 'My Account',
            state: '',
            name: 'myAccount',
            'data-cy': 'my_account',
          },
          {
            route: '/dashboard/referrals',
            icon: <PersonAdd sx={{ color: '#212121', fontSize: '24px' }} />,
            text: 'Send Invite',
            state: '',
            name: 'referrals',
          },
        ],
      },
    ],
    reportTypeFilterOptions,
    higherConfidenceThreshold: 50,
    hardcodedPlans: [],
  },
  keys: {
    rapidsAPIKey: '2skFtN2m6NrKMZnUG8Q4',
    mapboxKey:
      'pk.eyJ1IjoiYmVlbnZlcmlmaWVkIiwiYSI6ImNqeXN3ejY2MDAwbXczaHFzcnp6cmo5eGUifQ.hEfUf5T6xAzgW8wghHulEA',
    mrRogersAPIKey: 'kl6x16zhIp39hszFhGt5cxAfDV1MoXId',
    streetViewStaticApiKey: 'AIzaSyA6H5JZT4MeOKS_JUzBmtBC-wCsvDqIm40',
    topHapApiKey: 'si_MKritZu1BytMaH',
    carwiserToken: 'zLPenm4kT2xCFg9mNU',
  },
  migrationPlanNames: [
    '22_86_1_month_nofree_afill_limit100reports_freepdf_bumper_plan_migration',
  ],
};

export const searchTabsForNewDesign = [
  {
    displayName: 'Vehicle',
    isHidden: true,
    id: 'vehicleTab',
    icon: <DirectionsCar fontSize="small" sx={{ color: '#212121' }} />,
    internalTabs: [
      {
        label: 'VIN',
        mobileLabel: 'VIN',
        libFormComponentName: 'VINSearchForm',
      },
      {
        label: 'License Plate',
        mobileLabel: 'License Plate',
        libFormComponentName: 'LicensePlateSearchForm',
      },
      {
        label: 'Year, Make, Model',
        mobileLabel: 'Year, Make, Model',
        libFormComponentName: 'YMMSearchForm',
      },
    ],
  },
];

export const sideMenuItemsForNewDesign = [
  {
    route: '/dashboard/home',
    icon: <Home sx={{ color: '#212121', fontSize: '24px' }} />,
    text: 'My Bumper',
    name: 'myBumper',
    state: '',
    'data-cy': 'my_bumper',
  },
  {
    route: '/dashboard/rewards',
    icon: <Redeem sx={{ color: '#212121', fontSize: '24px' }} />,
    text: 'Rewards',
    state: '',
    name: 'rewards',
  },
  {
    route: '',
    icon: <Summarize sx={{ color: '#212121', fontSize: '24px' }} />,
    text: 'Reports',
    name: 'reports',
    state: '',
    'data-cy': 'reports',
    childItems: [
      {
        route: '/dashboard/reports',
        icon: <Restore sx={{ color: '#212121', fontSize: '24px' }} />,
        text: 'Recent Reports',
        name: 'recentReports',
        state: '',
        'data-cy': 'recent_reports',
      },
      {
        route: '/dashboard/lists',
        icon: <List sx={{ color: '#212121', fontSize: '24px' }} />,
        text: 'Saved Reports and Lists',
        state: '',
        name: 'lists',
        'data-cy': 'saved_reports_and_list',
      },
    ],
  },
  {
    route: '/dashboard/monitoring',
    icon: <NotificationsNone sx={{ color: '#212121', fontSize: '24px' }} />,
    text: 'Monitoring',
    state: '',
    name: 'monitoring',
  },
  {
    route: '/dashboard/api',
    icon: <Code sx={{ color: '#212121' }} />,
    text: 'Bumper API',
    state: '',
    name: 'api',
  },
  {
    route: '',
    icon: <ManageAccounts sx={{ color: '#212121', fontSize: '24px' }} />,
    text: 'My Profile',
    state: '',
    name: 'my-profile',
    childItems: [
      {
        route: '/dashboard/billing',
        icon: <AccountCircle sx={{ color: '#212121', fontSize: '24px' }} />,
        text: 'My Account',
        state: '',
        name: 'myAccount',
        'data-cy': 'my_account',
      },
      {
        route: '/dashboard/referrals',
        icon: <PersonAdd sx={{ color: '#212121', fontSize: '24px' }} />,
        text: 'Send Invite',
        state: '',
        name: 'referrals',
      },
    ],
  },
];
